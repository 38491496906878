import { Component, HostListener } from '@angular/core';
import { AlertController, IonicModule, LoadingController, PopoverController } from '@ionic/angular';
import { MenuPage } from "./menu/menu.page";
import { ListHeaderComponent } from './components/list-header/list-header.component';
import { ListBodyComponent } from './components/list-body/list-body.component';
import { SwUpdate } from '@angular/service-worker';
import { Performance } from '@angular/fire/performance';
import { NgClass } from '@angular/common';
import { Auth } from '@angular/fire/auth';
import { firstValueFrom, Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import Quill from 'quill';
import { ProductsService } from './services/products.service';
import { ClientsService } from './services/clients.service';
import { ExchangeRatesService } from './services/exchange-rates.service';
import { ClientBranchesService } from './services/client-branches.service';
import { CurrenciesService } from './services/currencies.service';
import { DeviceService } from './services/device.service';

const Link: any = Quill.import("formats/link");

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, MenuPage, ListHeaderComponent, ListBodyComponent, NgClass],
})
export class AppComponent {
  showsListPlaceholder: boolean = true;
  showsOffline: boolean = false;
  hidesOffline: boolean = false;
  newVersionSubscription: Subscription | undefined;
  updateLoading: HTMLIonLoadingElement | undefined;

  constructor(
    private swUpdate: SwUpdate,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private performace: Performance,
    private router: Router,
    private auth: Auth,
    private popoverController: PopoverController,
    private productsService: ProductsService,
    private devicesService: DeviceService,
    private clientsService: ClientsService,
    private branchesService: ClientBranchesService,
    private exchangeRatesService: ExchangeRatesService,
    private currenciesService: CurrenciesService
  ) {
    this.checkPWAUpdates();
    this.handleListPlaceholderVisibility();
    this.setRichTextEditorLinks();
    this.updateOfflineCache();

    document.getElementsByClassName('loading-raw').item(0)?.remove();
  }

  //#region Quill Rich Text Editor

  private setRichTextEditorLinks() {
    const router = this.router;
    const popoverController = this.popoverController;

    class MyLink extends Link {
      static create(value: any) {
        const node = Link.create(value);
        value = Link.sanitize(value);

        if (value.startsWith('/')) {
          //Elimina la acciono de navegar
          node.setAttribute('href', `#`);
          //Elimina el target _blank que se agrega por defecto
          node.removeAttribute('target');
          //Intercepta el evento click para navegar con Angular
          node.addEventListener('click', (event: Event) => {
            event.preventDefault();

            router.navigateByUrl(value);
            popoverController.dismiss();
          });
        }

        return node;
      }

      format(name: string, value: any) {
        super.format(name, value);
        this["domNode"].removeAttribute("target");
      }
    }

    Quill.register(MyLink, true);
  }

  //#endregion

  //#region List Placeholder

  handleListPlaceholderVisibility() {
    const url = window.location.href.toLowerCase();

    if (url.includes("/login") || url.includes("/register") || url.includes("/frogot-password")) {
      this.showsListPlaceholder = false;
    }

    this.auth.onAuthStateChanged(userIdentity => {

      if (userIdentity) {
        this.showsListPlaceholder = true;
      }
      else {
        this.showsListPlaceholder = false;
      }
    })
  }

  //#endregion

  //#region PWA Versión Update

  private async checkPWAUpdates() {
    if (this.newVersionSubscription) {
      this.newVersionSubscription.unsubscribe();
    }

    if (this.swUpdate.isEnabled) {
      this.newVersionSubscription = this.swUpdate.versionUpdates.subscribe(async event => {
        switch (event.type) {
          case 'VERSION_DETECTED':
            console.log(`SW: Downloading new app version: ${event.version.hash}`);
            if (!await this.isCurrentVersionCompatible()) {
              //Si no es compatible con la versión actual, fuerza la actualización
              this.showUpdateLoading();
            }
            //Si es compatible no hace nada hasta que se descargue por completo
            break;
          case 'VERSION_READY':
            console.log(`SW: App version '${event.latestVersion.hash}' is ready to be installed`);

            if (this.updateLoading) {
              //Si no es compatible y está esperando la descarga, recarga la página
              this.forceNewVersionAlert();
            } else {
              //Si es compatible muestra el mensaje de nueva versión, para que el usuario decida si actualizar o no
              this.showNewVersiónAlert();
            }
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`SW: Failed to install app version '${event.version.hash}': ${event.error}`);
            if (this.updateLoading) {
              //Si no es compativbble y falla la instalación, recarga la página
              this.forceNewVersionAlert();
            }
            break;
          case 'NO_NEW_VERSION_DETECTED':
            console.log('SW: No new app version detected');
            break;
        }
      });

      console.log("SW: Checking for updates...");
      this.swUpdate.checkForUpdate();
    }
    else {
      console.warn("Service Worker is Disabled");
    }
  }

  private async isCurrentVersionCompatible(): Promise<boolean> {
    try {
      const response = await fetch('api/app.json');

      if (!response.ok) {
        //Si no se puede obtener la versión, se asume que no es compatible
        return false;
      }

      const app = await response.json();
      const lastCompatibleVersion = app.lastCompatibleVersion.toString().trim();
      const currentVersion = environment.version.split('-')[0].trim(); // elimina los prefijos -dev, -beta, etc.

      console.log(`SW: Current version: ${currentVersion}, Last compatible version: ${lastCompatibleVersion}`);

      const lastCompatibleVersionArr = lastCompatibleVersion.split('.').map(Number);
      const currentVersionArr = currentVersion.split('.').map(Number);

      const maxLength = Math.max(lastCompatibleVersionArr.length, currentVersionArr.length);

      for (let i = 0; i < maxLength; i++) {
        const lastCompatibleComponent = lastCompatibleVersionArr[i] || 0;
        const currentComponent = currentVersionArr[i] || 0;

        if (currentComponent > lastCompatibleComponent) {
          return true;
        } else if (currentComponent < lastCompatibleComponent) {
          return false;
        }
      }

      // Si todas las partes de las versiones son iguales, son compatibles
      return true;
    } catch (error) {
      console.error("Error al obtener la versión de la aplicación", error);
      return false; // Si no se puede obtener la versión, se asume que no es compatible
    }
  }

  private async showUpdateLoading() {
    if (this.updateLoading) {
      this.updateLoading.dismiss();
    }

    this.updateLoading = await this.loadingController.create({
      message: 'Descargando nueva versión...',
      spinner: 'crescent',
    });

    this.updateLoading.present();

  }

  private async forceNewVersionAlert() {
    if (this.updateLoading) {
      this.updateLoading.dismiss();
    }

    const alert = await this.alertController.create({
      header: 'Nueva versión',
      subHeader: 'Mentesis ha sido actualizado.',
      message: 'La nueva versión se ha instalado correctamente.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Actualizar',
          handler: () => {
            this.swUpdate.activateUpdate().then(() => {
              console.log("SW: Activate and Reload");
              window.location.reload();
            });
          }
        }
      ]
    });

    await alert.present();
  }

  private async showNewVersiónAlert() {
    const alert = await this.alertController.create({
      header: 'Nueva versión',
      subHeader: 'Mentesis ha sido actualizado.',
      message: '¿Desea utilizar la versión actulizada inmediatamente o esperar a la próxima vez que ingrese?',
      buttons: [
        {
          text: 'Esperar',
          role: 'cancel',
          cssClass: 'alert-button-secondary',
        }, {
          text: 'Actualizar',
          handler: () => {
            this.swUpdate.activateUpdate().then(() => {
              console.log("SW: Activate and Reload");
              window.location.reload();
            });
          }
        }
      ]
    });

    await alert.present();
  }

  //#endregion

  //#region  Offline/Online

  @HostListener('window:offline')
  showOffline() {
    this.showsOffline = true;
    this.hidesOffline = false;
  }

  @HostListener('window:online')
  hideOffline() {
    this.hidesOffline = true;

    window.setTimeout(() => {
      this.showsOffline = false;
      this.hidesOffline = false;
    }, 600)
  }

  async updateOfflineCache() {
    console.log('updateOfflineCache');

    //Asegura que exista el registro del device
    await this.devicesService.current();

    await this.currenciesService.byActiveOnce(true).then(async currencies => {
      for (const from of currencies) {
        for (const to of currencies) {
          if (from.uid !== to.uid) {
            await this.exchangeRatesService.byCurrencies(from.uid, to.uid);
            await this.exchangeRatesService.byCurrenciesBCCR(from.uid, to.uid);
          }
        }
      }
    });

    await this.productsService.updateCache();

    await this.clientsService.updateCache();

    const clients = await firstValueFrom(await this.clientsService.byActive(true));

    for (const client of clients) {
      await this.branchesService.updateCache(client.uid);
    }
  }

  //#endregion
}