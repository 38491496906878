import { CodeDescriptionItem } from "./CodeDescriptionItem";


export enum CondicionVenta {
  Contado = "01",
  Credito = "02",
  Consignacion = "03",
  Apartado = "04",
  ArrendamientoOpcionCompra = "05",
  ArrendamientoFuncionFinanciera = "06",
  CobroATercero = "07",
  ServiciosPrestadosCredito = "08",
  PagoServiciosPrestados = "09",
  Otros = "99"
}

export const CondicionesVenta: CodeDescriptionItem[] = [
  { code: CondicionVenta.Contado, description: "Contado" },
  { code: CondicionVenta.Credito, description: "Crédito" },
  { code: CondicionVenta.Consignacion, description: "Consignación" },
  { code: CondicionVenta.Apartado, description: "Apartado" },
  { code: CondicionVenta.ArrendamientoOpcionCompra, description: "Arrendamiento con opción de compra" },
  { code: CondicionVenta.ArrendamientoFuncionFinanciera, description: "Arrendamiento en función financiera" },
  { code: CondicionVenta.CobroATercero, description: "Cobro a favor de un tercero" },
  { code: CondicionVenta.ServiciosPrestadosCredito, description: "Servicios prestados al Estado a crédito" },
  { code: CondicionVenta.PagoServiciosPrestados, description: "Pago del servicios prestado al Estado" },
  { code: CondicionVenta.Otros, description: "otros (se debe indicar la condición de la venta)" }
];