import { Profile } from "./Profile"

export type Permission = {
    uid: PermissionsCodes | number,
    name: string,
    added: PermissionsCodes[]
    required: PermissionsCodes[],
    isEnabled: ((profile: Profile) => boolean) | boolean;
}

export type PermissionGroup = {
    name: string,
    permissions: Permission[];
}

export enum PermissionsCodes {
    LoadCabys = 1,
    LoadTerritories = 2,
    LoadEconomicActivities = 3,
    LoadProducts = 4,
    LoadDigitalDocuments = 5,
    LoadPurchaseOrders = 6,

    ViewCompanies = 7,
    EditCompanies = 8,
    CreateCompanies = 9,
    DisableCompanies = 10,

    ViewBranches = 11,
    EditBranches = 12,
    CreateBranches = 13,
    DisableBranches = 14,

    ViewCashDesk = 15,
    EditCashDesk = 16,
    EditCashDeskConsecutives = 17,
    CreateCashDesk = 18,
    DisableCashDesk = 19,

    OpenCashDesk = 20,
    CloseCashDesk = 21,
    ViewCashDeskBalances = 22,
    AdjustCashDeskBalances = 23,

    ViewBanks = 24,
    EditBanks = 25,
    CreateBanks = 26,
    DisableBanks = 27,

    ViewUsers = 28,
    EditUsers = 29,
    CreateUsers = 30,
    DisableUsers = 31,

    ViewRoles = 32,
    EditRoles = 33,
    CreateRoles = 34,
    DisableRoles = 35,

    ViewSuppliers = 36,
    EditSuppliers = 37,
    CreateSuppliers = 38,
    DisableSuppliers = 39,

    ViewClients = 40,
    EditClients = 41,
    CreateClients = 42,
    DisableClients = 43,

    CreateInvoices = 44,
    CreateTickets = 45,
    CreateCreditNote = 46,
    CreateDebitNote = 47,
    ViewDigitalDocuments = 48,
    ChangeInvoicePrices = 112,
    ChangeInvoiceTaxes = 125,
    RemoveInvoiceProducts = 113,

    ViewCategories = 49,
    EditCategories = 50,
    CreateCategories = 51,
    DisableCategories = 52,

    ViewPriceLists = 53,
    EditPriceLists = 54,
    CreatePriceLists = 55,
    DisablePriceLists = 56,

    ViewProducts = 57,
    EditProducts = 58,
    CreateProducts = 59,
    DisableProducts = 60,

    ViewWarehouse = 61,
    EditWarehouse = 62,
    CreateWarehouse = 63,
    DisableWarehouse = 64,

    ViewBrands = 65,
    EditBrands = 66,
    CreateBrands = 67,
    DisableBrands = 68,

    ViewPurchases = 69,
    EditPurchases = 70,
    CreatePurchases = 71,
    ClosePurchases = 103,
    CancelPurchases = 72,

    ViewPurchaseReceptions = 73,
    CreatePurchaseReceptions = 74,

    ViewPurchaseDigitalDocuments = 122,
    CreatePurchaseInvoices = 123,
    AcceptRejectDigitalDocuments = 124,

    ViewStockChanges = 75,
    CreateStockChanges = 76,

    ViewStockTransfers = 77,
    RejectStockTransfers = 78,
    CreateStockTransfers = 79,
    CloseStockTransfers = 107,
    CancelStockTransfers = 108,

    ViewStockTransfersReceptions = 109,
    CreateStockTransfersReceptions = 110,
    EditStockTransfersReceptions = 111,

    ViewFixables = 80,
    EditFixables = 81,
    CreateFixables = 82,
    DisableFixables = 83,

    ViewWorkshopMaintenances = 84,
    CreateWorkshopMaintenances = 85,
    CompleteWorkshopMaintenances = 101,

    ViewOnSiteMaintenances = 104,
    CreateOnSiteMaintenances = 105,
    CompleteOnSiteMaintenances = 106,

    InProcessMaintenance = 86,
    CloseMaintenances = 102,
    CancelMaintenances = 87,

    ViewMaintenancesReceptions = 88,
    CreateMaintenancesReceptions = 89,
    EditMaintenancesReceptions = 90,

    ViewMaintenancesFixes = 91,
    CreateMaintenancesFixes = 92,
    EditMaintenancesFixes = 93,

    ViewMaintenancesDeliveries = 94,
    CreateMaintenancesDeliveries = 95,
    EditMaintenancesDeliveries = 96,

    ViewModels = 97,
    EditModels = 98,
    CreateModels = 99,
    DisableModels = 100,

    ViewDynamicPages = 114,
    EditDynamicPages = 115,
    CreateDynamicPages = 116,
    DisableDynamicPages = 117,

    ViewDynamicTypes = 118,
    EditDynamicTypes = 119,
    CreateDynamicTypes = 120,
    DisableDynamicTypes = 121,
}

const isDigitalInvoiceEnabled = (profile: Profile) => {
    return profile.company.configuration.digitalInvoice.isEnable;
}

const isPurchaseEnabled = (profile: Profile) => {
    return profile.company.configuration.purchase.isEnable;
}

const isMaintenanceEnabled = (profile: Profile) => {
    return profile.company.configuration.maintenance.isEnable;
}

const isCashDeskBalanceEnabled = (profile: Profile) => {
    return isDigitalInvoiceEnabled(profile) && profile.company.configuration.cashDeskBalance.isEnable;
}

const isStockEnabled = (profile: Profile) => {
    return profile.company.configuration.stock.isEnable;
}

const isSupportEnabled = (profile: Profile) => {
    return !!profile.claims.sp;
}

export const PermissionsList = (profile: Profile | undefined): PermissionGroup[] => [
    // {
    //     //cargas
    //     name: "Cargas",
    //     permissions: [
    //         {
    //             uid: PermissionsCodes.LoadCabys,
    //             name: "Cargar Cabys",
    //             added: [],
    //             required: [],
    //             isEnabled: true
    //         },
    //         {
    //             uid: PermissionsCodes.LoadTerritories,
    //             name: "Cargar Territorios",
    //             added: [],
    //             required: [],
    //             isEnabled: true
    //         },
    //         {
    //             uid: PermissionsCodes.LoadEconomicActivities,
    //             name: "Cargar Actividades Economicas",
    //             added: [],
    //             required: [],
    //             isEnabled: true
    //         },
    //         {
    //             uid: PermissionsCodes.LoadProducts,
    //             name: "Cargar Productos",
    //             added: [],
    //             required: [
    //                 PermissionsCodes.ViewBrands, PermissionsCodes.CreateBrands,
    //                 PermissionsCodes.ViewCategories, PermissionsCodes.CreateCategories, PermissionsCodes.EditCategories,
    //                 PermissionsCodes.ViewPriceLists,
    //                 PermissionsCodes.ViewProducts, PermissionsCodes.CreateProducts, PermissionsCodes.EditProducts
    //             ],
    //             isEnabled: true
    //         },
    //         {
    //             uid: PermissionsCodes.LoadDigitalDocuments,
    //             name: "Cargar Documentos Electrónicos",
    //             added: [],
    //             required: [],
    //             isEnabled: true
    //         },
    //         {
    //             uid: PermissionsCodes.LoadPurchaseOrders,
    //             name: "Cargar Ordenes de Compra",
    //             added: [],
    //             required: [],
    //             isEnabled: true
    //         }
    //     ]
    // },
    {
        name: "Compañias",
        permissions: [
            //Compañias
            {
                uid: PermissionsCodes.ViewCompanies,
                name: "Ver Compañias",
                added: [],
                required: [],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.EditCompanies,
                name: "Editar Compañias",
                added: [PermissionsCodes.ViewCompanies],
                required: [PermissionsCodes.ViewProducts, PermissionsCodes.ViewCashDesk, PermissionsCodes.ViewBranches],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.CreateCompanies,
                name: "Crear Compañias",
                added: [PermissionsCodes.ViewCompanies],
                required: [PermissionsCodes.ViewProducts, PermissionsCodes.ViewCashDesk, PermissionsCodes.ViewBranches],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.DisableCompanies,
                name: "Desactivar Compañias",
                added: [PermissionsCodes.ViewCompanies],
                required: [],
                isEnabled: true
            },
            //Sucursales
            {
                uid: PermissionsCodes.ViewBranches,
                name: "Ver Sucursales",
                added: [],
                required: [],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.EditBranches,
                name: "Editar Sucursales",
                added: [PermissionsCodes.ViewBranches],
                required: [],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.CreateBranches,
                name: "Crear Sucursales",
                added: [PermissionsCodes.ViewBranches],
                required: [],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.DisableBranches,
                name: "Desactivar Sucursales",
                added: [PermissionsCodes.ViewBranches],
                required: [],
                isEnabled: true
            },
            //Cajas
            {
                uid: PermissionsCodes.ViewCashDesk,
                name: "Ver Cajas",
                added: [],
                required: [PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewBranches],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.EditCashDesk,
                name: "Editar Cajas",
                added: [PermissionsCodes.ViewCashDesk],
                required: [PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewBranches],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.EditCashDeskConsecutives,
                name: "Editar Consecutivos de Caja",
                added: [PermissionsCodes.ViewCashDesk, PermissionsCodes.EditCashDesk],
                required: [PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewBranches],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.CreateCashDesk,
                name: "Crear Cajas",
                added: [PermissionsCodes.ViewCashDesk],
                required: [PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewBranches],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.DisableCashDesk,
                name: "Desactivar Cajas",
                added: [PermissionsCodes.ViewCashDesk],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            //Cierres de Caja
            {
                uid: PermissionsCodes.OpenCashDesk,
                name: "Abrir Caja",
                added: [],
                required: [PermissionsCodes.ViewCashDesk, PermissionsCodes.ViewCashDeskBalances],
                isEnabled: isCashDeskBalanceEnabled
            },
            {
                uid: PermissionsCodes.CloseCashDesk,
                name: "Cerrar Caja",
                added: [],
                required: [PermissionsCodes.ViewCashDesk, PermissionsCodes.ViewCashDeskBalances],
                isEnabled: isCashDeskBalanceEnabled
            },
            {
                uid: PermissionsCodes.ViewCashDeskBalances,
                name: "Ver Cierres Cajas",
                added: [PermissionsCodes.ViewCashDesk],
                required: [PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewBranches],
                isEnabled: isCashDeskBalanceEnabled
            },
            {
                uid: PermissionsCodes.AdjustCashDeskBalances,
                name: "Ajustar Cierres Cajas",
                added: [PermissionsCodes.ViewCashDeskBalances, PermissionsCodes.ViewCashDesk],
                required: [PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewBranches],
                isEnabled: isCashDeskBalanceEnabled
            },
            //Bancos
            {
                uid: PermissionsCodes.ViewBanks,
                name: "Ver Bancos",
                added: [],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.EditBanks,
                name: "Editar Bancos",
                added: [PermissionsCodes.ViewBanks],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.CreateBanks,
                name: "Crear Bancos",
                added: [PermissionsCodes.ViewBanks],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.DisableBanks,
                name: "Desactivar Bancos",
                added: [PermissionsCodes.ViewBanks],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },

            //Pantallas Dinámicas
            {
                uid: PermissionsCodes.ViewDynamicPages,
                name: "Ver Pantallas Dinámicas",
                added: [],
                required: [],
                isEnabled: isSupportEnabled
            },
            {
                uid: PermissionsCodes.EditDynamicPages,
                name: "Editar Pantallas Dinámicas",
                added: [PermissionsCodes.ViewDynamicPages],
                required: [],
                isEnabled: isSupportEnabled
            },
            {
                uid: PermissionsCodes.CreateDynamicPages,
                name: "Crear Pantallas Dinámicas",
                added: [PermissionsCodes.ViewDynamicPages],
                required: [],
                isEnabled: isSupportEnabled
            },
            {
                uid: PermissionsCodes.DisableDynamicPages,
                name: "Desactivar Pantallas Dinámicas",
                added: [PermissionsCodes.ViewDynamicPages],
                required: [],
                isEnabled: isSupportEnabled
            },
            
            // Tipos Dinámicos

            {
                uid: PermissionsCodes.ViewDynamicTypes,
                name: "Ver Tipos Dinámicos",
                added: [],
                required: [],
                isEnabled: isSupportEnabled
            },
            {
                uid: PermissionsCodes.EditDynamicTypes,
                name: "Editar Tipos Dinámicos",
                added: [PermissionsCodes.ViewDynamicTypes],
                required: [],
                isEnabled: isSupportEnabled
            },
            {
                uid: PermissionsCodes.CreateDynamicTypes,
                name: "Crear Tipos Dinámicos",
                added: [PermissionsCodes.ViewDynamicTypes],
                required: [],
                isEnabled: isSupportEnabled
            },
            {
                uid: PermissionsCodes.DisableDynamicTypes,
                name: "Desactivar Tipos Dinámicos",
                added: [PermissionsCodes.ViewDynamicTypes],
                required: [],
                isEnabled: isSupportEnabled
            }
        ]
    },
    {
        name: "Seguridad",
        permissions: [
            //Usuarios
            {
                uid: PermissionsCodes.ViewUsers,
                name: "Ver Usuarios",
                added: [],
                required: [PermissionsCodes.ViewRoles, PermissionsCodes.ViewCashDesk, PermissionsCodes.ViewWarehouse, PermissionsCodes.ViewBranches],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.EditUsers,
                name: "Editar Usuarios",
                added: [PermissionsCodes.ViewUsers],
                required: [PermissionsCodes.ViewRoles, PermissionsCodes.ViewCashDesk, PermissionsCodes.ViewWarehouse, PermissionsCodes.ViewBranches],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.CreateUsers,
                name: "Crear Usuarios",
                added: [PermissionsCodes.ViewUsers],
                required: [PermissionsCodes.ViewRoles, PermissionsCodes.ViewCashDesk, PermissionsCodes.ViewWarehouse, PermissionsCodes.ViewBranches],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.DisableUsers,
                name: "Desactivar Usuarios",
                added: [PermissionsCodes.ViewUsers],
                required: [PermissionsCodes.ViewRoles, PermissionsCodes.ViewCashDesk, PermissionsCodes.ViewWarehouse, PermissionsCodes.ViewBranches],
                isEnabled: true
            },
            //Roles
            {
                uid: PermissionsCodes.ViewRoles,
                name: "Ver Roles",
                added: [],
                required: [],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.EditRoles,
                name: "Editar Roles",
                added: [PermissionsCodes.ViewRoles],
                required: [],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.CreateRoles,
                name: "Crear Roles",
                added: [PermissionsCodes.ViewRoles],
                required: [],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.DisableRoles,
                name: "Desactivar Roles",
                added: [PermissionsCodes.ViewRoles],
                required: [],
                isEnabled: true
            }
        ]
    },
    {
        name: "Compras",
        permissions: [
            //Proveedores
            {
                uid: PermissionsCodes.ViewSuppliers,
                name: "Ver Proveedores",
                added: [],
                required: [],
                isEnabled: isPurchaseEnabled
            },
            {
                uid: PermissionsCodes.EditSuppliers,
                name: "Editar Proveedores",
                added: [PermissionsCodes.ViewSuppliers],
                required: [],
                isEnabled: isPurchaseEnabled
            },
            {
                uid: PermissionsCodes.CreateSuppliers,
                name: "Crear Proveedores",
                added: [PermissionsCodes.ViewSuppliers],
                required: [],
                isEnabled: isPurchaseEnabled
            },
            {
                uid: PermissionsCodes.DisableSuppliers,
                name: "Desactivar Proveedores",
                added: [PermissionsCodes.ViewSuppliers],
                required: [],
                isEnabled: isPurchaseEnabled
            },
            //Ordenes de compra
            {
                uid: PermissionsCodes.ViewPurchases,
                name: "Ver Ordenes de Compra",
                added: [],
                required: [],
                isEnabled: isPurchaseEnabled
            },
            {
                uid: PermissionsCodes.EditPurchases,
                name: "Editar Ordenes de Compra",
                added: [PermissionsCodes.ViewPurchases],
                required: [PermissionsCodes.ViewBranches, PermissionsCodes.ViewWarehouse, PermissionsCodes.ViewProducts, PermissionsCodes.ViewSuppliers],
                isEnabled: isPurchaseEnabled
            },
            {
                uid: PermissionsCodes.CreatePurchases,
                name: "Crear Ordenes de Compra",
                added: [PermissionsCodes.ViewPurchases],
                required: [PermissionsCodes.ViewBranches, PermissionsCodes.ViewWarehouse, PermissionsCodes.ViewProducts, PermissionsCodes.ViewSuppliers],
                isEnabled: isPurchaseEnabled
            },
            {
                uid: PermissionsCodes.ClosePurchases,
                name: "Cerrar Ordenes de Compra",
                added: [PermissionsCodes.ViewPurchases],
                required: [],
                isEnabled: isPurchaseEnabled
            },
            {
                uid: PermissionsCodes.CancelPurchases,
                name: "Cancelar Ordenes de Compra",
                added: [PermissionsCodes.ViewPurchases],
                required: [],
                isEnabled: isPurchaseEnabled
            },
            //recepción de pedidos
            {
                uid: PermissionsCodes.ViewPurchaseReceptions,
                name: "Ver Recepción de pedidos",
                added: [PermissionsCodes.ViewPurchases],
                required: [],
                isEnabled: isPurchaseEnabled
            },
            {
                uid: PermissionsCodes.CreatePurchaseReceptions,
                name: "Crear Recepción de pedidos",
                added: [PermissionsCodes.ViewPurchaseReceptions, PermissionsCodes.ViewPurchases],
                required: [PermissionsCodes.ViewBranches, PermissionsCodes.ViewWarehouse],
                isEnabled: isPurchaseEnabled
            },
            //Facturas de compra
            {
                uid: PermissionsCodes.ViewPurchaseDigitalDocuments,
                name: "Ver Documentos Electrónicos de Compra",
                added: [],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.CreatePurchaseInvoices,
                name: "Crear Facturas de Compra",
                added: [PermissionsCodes.ViewPurchaseDigitalDocuments],
                required: [PermissionsCodes.ViewBranches, PermissionsCodes.ViewWarehouse, PermissionsCodes.ViewSuppliers],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.AcceptRejectDigitalDocuments,
                name: "Aceptar/Rechazar Documentos Electrónicos",
                added: [PermissionsCodes.ViewPurchaseDigitalDocuments],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            }
        ]
    },
    {
        name: "Ventas",
        permissions: [
            //Clientes
            {
                uid: PermissionsCodes.ViewClients,
                name: "Ver Clientes",
                added: [],
                required: [PermissionsCodes.ViewPriceLists],
                isEnabled: (profile: Profile) => (isDigitalInvoiceEnabled(profile) || isMaintenanceEnabled(profile))
            },
            {
                uid: PermissionsCodes.EditClients,
                name: "Editar Clientes",
                added: [PermissionsCodes.ViewClients],
                required: [PermissionsCodes.ViewPriceLists],
                isEnabled: (profile: Profile) => (isDigitalInvoiceEnabled(profile) || isMaintenanceEnabled(profile))
            },
            {
                uid: PermissionsCodes.CreateClients,
                name: "Crear Clientes",
                added: [PermissionsCodes.ViewClients],
                required: [PermissionsCodes.ViewPriceLists],
                isEnabled: (profile: Profile) => (isDigitalInvoiceEnabled(profile) || isMaintenanceEnabled(profile))
            },
            {
                uid: PermissionsCodes.DisableClients,
                name: "Desactivar Clientes",
                added: [PermissionsCodes.ViewClients],
                required: [PermissionsCodes.ViewPriceLists],
                isEnabled: (profile: Profile) => (isDigitalInvoiceEnabled(profile) || isMaintenanceEnabled(profile))
            },
            //Documentos Electronicos
            {
                uid: PermissionsCodes.CreateInvoices,
                name: "Crear Facturas Electrónicas",
                added: [PermissionsCodes.ViewDigitalDocuments, PermissionsCodes.ViewProducts],
                required: [PermissionsCodes.ViewBranches, PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewCashDesk,
                PermissionsCodes.ViewCashDeskBalances, PermissionsCodes.ViewClients,
                PermissionsCodes.ViewBanks],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.ChangeInvoicePrices,
                name: "Modificar precios en Facturas Electrónicas",
                added: [],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.ChangeInvoiceTaxes,
                name: "Modificar IVA en Facturas Electrónicas",
                added: [],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.RemoveInvoiceProducts,
                name: "Eliminar productos en Facturas Electrónicas",
                added: [],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.CreateTickets,
                name: "Crear Tiquetes Electrónicas",
                added: [PermissionsCodes.ViewDigitalDocuments, PermissionsCodes.ViewProducts],
                required: [PermissionsCodes.ViewBranches, PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewCashDesk,
                PermissionsCodes.ViewCashDeskBalances, PermissionsCodes.ViewClients,
                PermissionsCodes.ViewBanks],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.CreateCreditNote,
                name: "Crear Notas de Crédito",
                added: [PermissionsCodes.ViewDigitalDocuments, PermissionsCodes.ViewProducts],
                required: [PermissionsCodes.ViewBranches, PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewCashDesk,
                PermissionsCodes.ViewCashDeskBalances, PermissionsCodes.ViewClients,
                PermissionsCodes.ViewBanks],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.CreateDebitNote,
                name: "Crear Notas de Debito",
                added: [PermissionsCodes.ViewDigitalDocuments, PermissionsCodes.ViewProducts],
                required: [PermissionsCodes.ViewBranches, PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewCashDesk,
                PermissionsCodes.ViewCashDeskBalances, PermissionsCodes.ViewClients,
                PermissionsCodes.ViewBanks],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.ViewDigitalDocuments,
                name: "Ver Documentos Electrónicos",
                added: [],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            //Ordenes de mantenimiento
            //*******En Taller******
            {
                uid: PermissionsCodes.ViewWorkshopMaintenances,
                name: "Ver Ordenes de Mantenimiento en Taller",
                added: [],
                required: [],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.CreateWorkshopMaintenances,
                name: "Crear Ordenes de Mantenimiento en Taller",
                added: [PermissionsCodes.ViewWorkshopMaintenances],
                required: [PermissionsCodes.ViewClients, PermissionsCodes.ViewFixables],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.CompleteWorkshopMaintenances,
                name: "Completar Ordenes de Mantenimiento en Taller",
                added: [PermissionsCodes.ViewWorkshopMaintenances],
                required: [],
                isEnabled: isMaintenanceEnabled
            },
            //*******En Sitio******
            {
                uid: PermissionsCodes.ViewOnSiteMaintenances,
                name: "Ver Ordenes de Mantenimiento en Sitio",
                added: [],
                required: [],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.CreateOnSiteMaintenances,
                name: "Crear Ordenes de Mantenimiento en Sitio",
                added: [PermissionsCodes.ViewOnSiteMaintenances],
                required: [PermissionsCodes.ViewClients, PermissionsCodes.ViewFixables],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.CompleteOnSiteMaintenances,
                name: "Completar Ordenes de Mantenimiento en Sitio",
                added: [PermissionsCodes.ViewOnSiteMaintenances],
                required: [],
                isEnabled: isMaintenanceEnabled
            },
            //*******Ambas******
            {
                uid: PermissionsCodes.InProcessMaintenance,
                name: "Iniciar Mantenimiento Manualmente",
                added: [],
                required: [],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.CloseMaintenances,
                name: "Cerrar Ordenes de Mantenimiento",
                added: [],
                required: [],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.CancelMaintenances,
                name: "Anular Ordenes de Mantenimiento",
                added: [],
                required: [],
                isEnabled: isMaintenanceEnabled
            },
            //recepción de mantenimientos
            {
                uid: PermissionsCodes.ViewMaintenancesReceptions,
                name: "Ver Recepción de Ordenes de Mantenimientos",
                added: [PermissionsCodes.ViewWorkshopMaintenances],
                required: [],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.CreateMaintenancesReceptions,
                name: "Crear Recepción de Ordenes de Mantenimientos",
                added: [PermissionsCodes.ViewWorkshopMaintenances, PermissionsCodes.ViewMaintenancesReceptions],
                required: [PermissionsCodes.ViewBranches],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.EditMaintenancesReceptions,
                name: "Editar Recepción de Ordenes de Mantenimientos",
                added: [PermissionsCodes.ViewWorkshopMaintenances, PermissionsCodes.ViewMaintenancesReceptions],
                required: [PermissionsCodes.ViewBranches],
                isEnabled: isMaintenanceEnabled
            },
            //registro de trabajos
            {
                uid: PermissionsCodes.ViewMaintenancesFixes,
                name: "Ver Trabajos de Mantenimiento",
                added: [PermissionsCodes.ViewWorkshopMaintenances],
                required: [PermissionsCodes.ViewProducts, PermissionsCodes.ViewWarehouse],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.CreateMaintenancesFixes,
                name: "Crear Trabajos de Mantenimiento",
                added: [PermissionsCodes.ViewWorkshopMaintenances, PermissionsCodes.ViewMaintenancesFixes],
                required: [],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.EditMaintenancesFixes,
                name: "Editar Trabajos de Mantenimiento",
                added: [PermissionsCodes.ViewWorkshopMaintenances, PermissionsCodes.ViewMaintenancesFixes],
                required: [],
                isEnabled: isMaintenanceEnabled
            },
            //entrega de mantenimientos
            {
                uid: PermissionsCodes.ViewMaintenancesDeliveries,
                name: "Ver Entrega de Ordenes de Mantenimientos",
                added: [PermissionsCodes.ViewWorkshopMaintenances],
                required: [],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.CreateMaintenancesDeliveries,
                name: "Crear Entrega de Ordenes de Mantenimientos",
                added: [PermissionsCodes.ViewWorkshopMaintenances, PermissionsCodes.ViewMaintenancesDeliveries],
                required: [PermissionsCodes.ViewClients],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.EditMaintenancesDeliveries,
                name: "Editar Entrega de Ordenes de Mantenimientos",
                added: [PermissionsCodes.ViewWorkshopMaintenances, PermissionsCodes.ViewMaintenancesDeliveries],
                required: [PermissionsCodes.ViewClients],
                isEnabled: isMaintenanceEnabled
            },
        ]
    },
    {
        name: "Almacen",
        permissions: [
            //Categorías
            {
                uid: PermissionsCodes.ViewCategories,
                name: "Ver Categorías",
                added: [],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.EditCategories,
                name: "Editar Categorías",
                added: [PermissionsCodes.ViewCategories],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.CreateCategories,
                name: "Crear Categorías",
                added: [PermissionsCodes.ViewCategories],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.DisableCategories,
                name: "Desactivar Categorías",
                added: [PermissionsCodes.ViewCategories],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            //Listas de Precio
            {
                uid: PermissionsCodes.ViewPriceLists,
                name: "Ver Listas de Precio",
                added: [],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.EditPriceLists,
                name: "Editar Listas de Precio",
                added: [PermissionsCodes.ViewPriceLists],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.CreatePriceLists,
                name: "Crear Listas de Precio",
                added: [PermissionsCodes.ViewPriceLists],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            {
                uid: PermissionsCodes.DisablePriceLists,
                name: "Desactivar Listas de Precio",
                added: [],
                required: [],
                isEnabled: isDigitalInvoiceEnabled
            },
            //Marcas
            {
                uid: PermissionsCodes.ViewBrands,
                name: "Ver Marcas",
                added: [],
                required: [],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.EditBrands,
                name: "Editar Marcas",
                added: [PermissionsCodes.ViewBrands],
                required: [],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.CreateBrands,
                name: "Crear Marcas",
                added: [PermissionsCodes.ViewBrands],
                required: [],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.DisableBrands,
                name: "Desactivar Marcas",
                added: [PermissionsCodes.ViewBrands],
                required: [],
                isEnabled: true
            },
            //Modelos
            {
                uid: PermissionsCodes.ViewModels,
                name: "Ver Modelos",
                added: [],
                required: [PermissionsCodes.ViewBrands],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.EditModels,
                name: "Editar Modelos",
                added: [PermissionsCodes.ViewModels],
                required: [PermissionsCodes.ViewBrands],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.CreateModels,
                name: "Crear Modelos",
                added: [PermissionsCodes.ViewModels],
                required: [PermissionsCodes.ViewBrands],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.DisableModels,
                name: "Desactivar Modelos",
                added: [PermissionsCodes.ViewModels],
                required: [PermissionsCodes.ViewBrands],
                isEnabled: true
            },
            //Productos
            {
                uid: PermissionsCodes.ViewProducts,
                name: "Ver Productos",
                added: [],
                required: [PermissionsCodes.ViewCategories, PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewBrands, PermissionsCodes.ViewModels],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.EditProducts,
                name: "Editar Productos",
                added: [PermissionsCodes.ViewProducts],
                required: [PermissionsCodes.ViewCategories, PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewBrands, PermissionsCodes.ViewModels],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.CreateProducts,
                name: "Crear Productos",
                added: [PermissionsCodes.ViewProducts],
                required: [PermissionsCodes.ViewCategories, PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewBrands, PermissionsCodes.ViewModels],
                isEnabled: true
            },
            {
                uid: PermissionsCodes.DisableProducts,
                name: "Desactivar Productos",
                added: [PermissionsCodes.ViewProducts],
                required: [PermissionsCodes.ViewCategories, PermissionsCodes.ViewPriceLists, PermissionsCodes.ViewBrands, PermissionsCodes.ViewModels],
                isEnabled: true
            },
            //Equipos
            {
                uid: PermissionsCodes.ViewFixables,
                name: "Ver " + profile?.company.configuration.maintenance.labels.plural ?? "<Equipos>",
                added: [],
                required: [PermissionsCodes.ViewBrands, PermissionsCodes.ViewModels],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.EditFixables,
                name: "Editar " + profile?.company.configuration.maintenance.labels.plural ?? "<Equipos>",
                added: [PermissionsCodes.ViewFixables],
                required: [PermissionsCodes.ViewClients, PermissionsCodes.ViewBrands, PermissionsCodes.ViewModels],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.CreateFixables,
                name: "Crear " + profile?.company.configuration.maintenance.labels.plural ?? "<Equipos>",
                added: [PermissionsCodes.ViewFixables],
                required: [PermissionsCodes.ViewClients, PermissionsCodes.ViewBrands, PermissionsCodes.ViewModels],
                isEnabled: isMaintenanceEnabled
            },
            {
                uid: PermissionsCodes.DisableFixables,
                name: "Desactivar " + profile?.company.configuration.maintenance.labels.plural ?? "<Equipos>",
                added: [PermissionsCodes.ViewFixables],
                required: [PermissionsCodes.ViewClients, PermissionsCodes.ViewBrands, PermissionsCodes.ViewModels],
                isEnabled: isMaintenanceEnabled
            },
            //Bodegas
            {
                uid: PermissionsCodes.ViewWarehouse,
                name: "Ver Bodegas",
                added: [],
                required: [],
                isEnabled: isStockEnabled
            },
            {
                uid: PermissionsCodes.EditWarehouse,
                name: "Editar Bodegas",
                added: [PermissionsCodes.ViewWarehouse],
                required: [PermissionsCodes.ViewBranches],
                isEnabled: isStockEnabled
            },
            {
                uid: PermissionsCodes.CreateWarehouse,
                name: "Crear Bodegas",
                added: [PermissionsCodes.ViewWarehouse],
                required: [PermissionsCodes.ViewBranches],
                isEnabled: isStockEnabled
            },
            {
                uid: PermissionsCodes.DisableWarehouse,
                name: "Desactivar Bodegas",
                added: [PermissionsCodes.ViewWarehouse],
                required: [PermissionsCodes.ViewBranches],
                isEnabled: isStockEnabled
            },
            //Traslados de Inventario
            {
                uid: PermissionsCodes.ViewStockTransfers,
                name: "Ver Traslados de Inventario",
                added: [],
                required: [],
                isEnabled: isStockEnabled
            },
            {
                uid: PermissionsCodes.CreateStockTransfers,
                name: "Crear Traslados de Inventario",
                added: [PermissionsCodes.ViewStockTransfers],
                required: [PermissionsCodes.ViewBranches, PermissionsCodes.ViewWarehouse],
                isEnabled: isStockEnabled
            },
            {
                uid: PermissionsCodes.RejectStockTransfers,
                name: "Rechazar Traslados de Inventario",
                added: [PermissionsCodes.ViewStockTransfers],
                required: [],
                isEnabled: isStockEnabled
            },
            {
                uid: PermissionsCodes.CloseStockTransfers,
                name: "Cerrar Traslados de Inventario",
                added: [PermissionsCodes.ViewStockTransfers],
                required: [],
                isEnabled: isStockEnabled
            },
            {
                uid: PermissionsCodes.CancelStockTransfers,
                name: "Anular Traslados de Inventario",
                added: [PermissionsCodes.ViewStockTransfers],
                required: [],
                isEnabled: isStockEnabled
            },
            //Recepcion de Traslados de Inventario
            {
                uid: PermissionsCodes.ViewStockTransfersReceptions,
                name: "Ver Recepción de Traslados de Inventario",
                added: [PermissionsCodes.ViewStockTransfers],
                required: [],
                isEnabled: isStockEnabled
            },
            {
                uid: PermissionsCodes.CreateStockTransfersReceptions,
                name: "Crear Recepción de Traslados de Inventario",
                added: [PermissionsCodes.ViewStockTransfers, PermissionsCodes.ViewStockTransfersReceptions],
                required: [PermissionsCodes.ViewBranches, PermissionsCodes.ViewWarehouse],
                isEnabled: isStockEnabled
            },
            {
                uid: PermissionsCodes.EditStockTransfersReceptions,
                name: "Editar Recepción de Traslados de Inventario",
                added: [PermissionsCodes.ViewStockTransfers, PermissionsCodes.ViewStockTransfersReceptions],
                required: [PermissionsCodes.ViewBranches, PermissionsCodes.ViewWarehouse],
                isEnabled: isStockEnabled
            },
            //Movimientos de Inventario
            {
                uid: PermissionsCodes.ViewStockChanges,
                name: "Ver Movimientos de Inventario",
                added: [],
                required: [PermissionsCodes.ViewBranches, PermissionsCodes.ViewWarehouse],
                isEnabled: isStockEnabled
            },
            {
                uid: PermissionsCodes.CreateStockChanges,
                name: "Crear Movimientos de Inventario",
                added: [PermissionsCodes.ViewStockChanges],
                required: [PermissionsCodes.ViewBranches, PermissionsCodes.ViewWarehouse],
                isEnabled: isStockEnabled
            },
        ]
    }
];

export const DynamicPermissionsList : PermissionGroup[] = [];