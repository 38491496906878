import { ListItem } from "../../models/ListItem";
// import { CodeDescriptionItem } from "./CodeDescriptionItem";

export enum TipoIdentificacion {
  CedulaFisica = "01",
  CedulaJuridica = "02",
  DIMEX = "03",
  NITE = "04"
}

export const TiposIdentificacion: ListItem[] = [
  { uid: TipoIdentificacion.CedulaFisica, name: "Cédula Física" },
  { uid: TipoIdentificacion.CedulaJuridica, name: "Cédula Jurídica" },
  { uid: TipoIdentificacion.DIMEX, name: "DIMEX" },
  { uid: TipoIdentificacion.NITE, name: "NITE" },
]

export const identificationTypeText = (uid: TipoIdentificacion) => {
  return TiposIdentificacion.find(t => t.uid == uid)?.description;
}