import { CondicionVenta } from "./facturaelectronica/CondicionVenta"

export type PaymentTerm = {
    type: PaymentTermType,
    days: number
}

export enum PaymentTermType{
    Cash = "CS",
    Credit = "CR",
    Layaway = "LA",
    Consignment = "CO",
    PurchaseOptionLease = "PO",
    FinancialLease = "FL",
    ThirdPartyCharge = "TP",
    GovernmentServicesCredit = "SC",
    GovernmentServices = "SR",
    Others = "OT"
}

export const PaymentTermDetails = [
    {
        uid: PaymentTermType.Cash,
        name: "Contado",
        condicionVenta: CondicionVenta.Contado,
    },
    {
        uid: PaymentTermType.Credit,
        name: "Crédito",
        condicionVenta: CondicionVenta.Credito,
    },
    {
        uid: PaymentTermType.Layaway,
        name: "Apartado",
        condicionVenta: CondicionVenta.Apartado,
    },
    {
        uid: PaymentTermType.Consignment,
        name: "Consignación",
        condicionVenta: CondicionVenta.Consignacion,
    },
    {
        uid: PaymentTermType.PurchaseOptionLease,
        name: "Arrendamiento con opción de compra",
        condicionVenta: CondicionVenta.ArrendamientoOpcionCompra,
    },
    {
        uid: PaymentTermType.FinancialLease,
        name: "Arrendamiento en función financiera",
        condicionVenta: CondicionVenta.ArrendamientoFuncionFinanciera,
    },
    {
        uid: PaymentTermType.ThirdPartyCharge,
        name: "Cobro a favor de un tercero",
        condicionVenta: CondicionVenta.CobroATercero,
    },
    {
        uid: PaymentTermType.GovernmentServicesCredit,
        name: "Servicios prestados al Estado a crédito",
        condicionVenta: CondicionVenta.ServiciosPrestadosCredito,
    },
    {
        uid: PaymentTermType.GovernmentServices,
        name: "Pago del servicios prestado al Estado",
        condicionVenta: CondicionVenta.PagoServiciosPrestados,
    },
    {
        uid: PaymentTermType.Others,
        name: "Otros",
        condicionVenta: CondicionVenta.Otros,
    }

]