import { Timestamp } from "@angular/fire/firestore";
import { UnidadMedida } from "./facturaelectronica/UnidadMedida";
import { ListDocument, MultiImagedDocument, MultiFieldTypedDocument, UniqueDocument, ImagedDocument } from "./BaseDocument";
import { Stock } from "./Stock";
import { MultiField, multifieldDescription } from "./MultiField";

export type Product = ListDocument & UniqueDocument & MultiImagedDocument & MultiFieldTypedDocument & {  
  code: string | null;
  costHistory?: CostHistory[];
  stock: Stock;
  sold: number;
  tags: string[];
  cost: number;
  maxDiscount: number;
  allowResellBelowCost: boolean;
  taxPercentage: number | null;
  fixedPrices: FixedPrice[];
  unit: UnidadMedida;
  cabysCode: string | null;
}

export type ProductRef = UniqueDocument & ImagedDocument & {
  code: string | null;
  description: string;  
  multifieldType: string;
}

export type CostHistory = {
  lastDate: Timestamp;
  cost: number;
}

export type FixedPrice = {
  priceListId: string;
  amount: number;
}

export const getReference = (product: Product, fields: MultiField[]) : ProductRef => {
  const reference: ProductRef =  {
    uid: product.uid,
    code: product.code,
    description: multifieldDescription(product, fields),
    image: product.image.length > 0 ? product.image[0] : null, 
    multifieldType: product.multifieldType  
  }

  return reference;
}

export const multifieldPageId = "products";
export const multifieldCodeSection = "codes"
export const multifieldDescriptionSection= "descriptions"

