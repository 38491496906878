import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IonSearchbar, IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule]
})
export class ListHeaderComponent {
  @Input() title: string = ""
  @Input() isModal: boolean = false;
  @Input() showsSearchButton: boolean = true;
  @Input() showsOptionsButton: boolean = true;
  @Input() showSearchBar: boolean = true;
  @Input() isLoading: boolean = true;

  // @Output() showButtonsChange = new EventEmitter<boolean>();
  @Output() showSearchBarChange = new EventEmitter<boolean>();
  @Output() ionInput = new EventEmitter();
  @Output() ionChange = new EventEmitter();
  @Output() onOptionsDismiss = new EventEmitter();

  @ViewChild('searchbar') searchbar!: IonSearchbar;
  @ViewChild('options') options!: HTMLIonPopoverElement;

  isSearchBarExpanded: boolean = false;

  isOptionsOpen: boolean = false;

  constructor() { }

  toogleSearchBar() {
    this.isSearchBarExpanded = !this.isSearchBarExpanded;
  }

  async setValue(value: string) {
    const input = await this.searchbar.getInputElement();

    input.value = value;

    //Fuerza el evento de input, como si se hubiera escrito por el usuario
    input.dispatchEvent(new Event('input'));
    input.dispatchEvent(new Event('change'));
  }

  showOptions(event: any) {
    if (event) {
      event.stopPropagation();
    }

    this.options.event = event;

    this.options.onDidDismiss().then(result => this.onOptionsDismiss.emit(result))

    this.isOptionsOpen = true;
  }
}
