import { Injectable } from '@angular/core';
import { Storage, getDownloadURL, ref, uploadString, deleteObject } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class ImageStorageService {

  constructor(
    private storage: Storage
    ) { }

  async save(folder: string, name: string, image: string): Promise<string>{
    const path = `${folder}/${name}`;

    const storageRef = ref(this.storage, path);

    await uploadString(storageRef, image, 'base64');

    return await getDownloadURL(storageRef);
  }

  async delete(path: string): Promise<void>{
    const storageRef = ref(this.storage, path);

    await deleteObject(storageRef);
  }
}
