import { Pipe, PipeTransform } from '@angular/core';
import { FieldValue } from '@angular/fire/firestore';
import { LogBase } from 'models/LogBase';

@Pipe({
  name: 'currentStatus',
  standalone: true
})
export class CurrentStatusPipe implements PipeTransform {

  transform<T extends LogBase[] | null | undefined>(logs: T, priorities: { status: string, overrides: string[] }[] = []): string | undefined {
    if (!logs) {
      return undefined;
    }

    if (logs.length === 0) {
      //El Array esta vacio
      return undefined;
    }

    const orderedLogs = logs.sort((a, b) => b.date.toMillis() - a.date.toMillis());

    const lastLog = orderedLogs[0];

    if (priorities.length === 0) {
      return lastLog.status;
    }

    const overrides = priorities.filter(priority => priority.overrides.some(status => status === lastLog.status));

    if (!overrides || overrides.length === 0) {
      return lastLog.status;
    }

    //Recorre el lista de log buscando el primero override
    for (let index = 0; index < orderedLogs.length; index++) {
      const log = orderedLogs[index];
      const override = overrides.find(o => o.status === log.status);

      if (override){
        return log.status;
      }
    }

    return lastLog.status;
  }

}
