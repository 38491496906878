
import { Timestamp } from "@angular/fire/firestore";
import { IdentifiedDocument, MultiImagedDocument, UniqueDocument, TransactionalDocument } from "./BaseDocument";
import { UserRef } from "./User";
import { LogBase } from "./LogBase";
import { BranchRef as BaseBranchRef, getReference as getBaseBranchReference } from "./Branch";
import { ClientRef as BaseClientRef, Client } from "./Client";
import { ListItem } from "./ListItem";
import { FixableRef } from "./Fixable";
import { Address } from "./Address";
import { Branch } from "./Branch";
import { EmailUsage } from "./Email";

export type Maintenance = TransactionalDocument &
{
    type: MaintenanceTypes,
    user: UserRef;
    client: ClientRef;
    lines: Line[];
    isDraft: boolean;
    isClosed: boolean;
    notes: string;
    signature: string | null;
    pdf: string | Blob,
    pdfFixes: string | Blob,
    xls: string | Blob,
    statusLog: Log[];

    agregationData: {
        fixables: {
            [key: string]: {
                received: boolean
                delivered: boolean
            };
        }
    }
}

export enum MaintenanceTypes {
    OnSite = "OS",
    Workshop = "WS",    
}

export type MaintenanceRef = UniqueDocument & {
    number: number;
    date: Timestamp;
}

export type Line = MultiImagedDocument & {
    amount: number,
    fixable: FixableRef,
}

export type Log = LogBase & {
    status: Status;
}

export enum Status {
    Draft = "DF",
    New = "N",
    Sending = "SP",
    ReSending = "RS",
    Send = "S",
    PartialReceived = "PR",
    Received = "R",
    InProcess = "IP",
    Completed = "C",
    PartialDelivered = "PD",
    Delivered = "D",
    Closed = "X",
    Cancelled = "V",
    Error = "E",
    OnHold = "H",
}

export const statusDetails: ListItem[] = [
    {
        uid: Status.Draft, name: "Borrador", color: "medium"
    },
    {
        uid: Status.New, name: "Nueva", color: "medium"
    },
    {
        uid: Status.Sending, name: "Enviando", color: "primary"
    },
    {
        uid: Status.ReSending, name: "Reenviando", color: "primary"
    },
    {
        uid: Status.Send, name: "Enviada", color: "success"
    },
    {
        uid: Status.Received, name: "Recibida", color: "success"
    },
    {
        uid: Status.PartialReceived, name: "Recibida Parcial", color: "warning"
    },
    {
        uid: Status.InProcess, name: "En Proceso", color: "primary"
    },
    {
        uid: Status.Completed, name: "Completada", color: "success"
    },
    {
        uid: Status.PartialDelivered, name: "Entregada Parcial", color: "warning"
    },
    {
        uid: Status.Delivered, name: "Entregada", color: "success"
    },
    {
        uid: Status.Closed, name: "Cerrada", color: "dark"
    },
    {
        uid: Status.Cancelled, name: "Anulada", color: "danger"
    },
    {
        uid: Status.Error, name: "Error", color: "danger"
    },
    {
        uid: Status.OnHold, name: "En Espera", color: "warning"
    },
]

export const statusPriorities = [
    {
        status: Status.InProcess,
        overrides: [Status.PartialReceived, Status.Received]
    },
    {
        status: Status.New,
        overrides: [Status.Sending, Status.Send]
    },
    {
        status: Status.Completed,
        overrides: [Status.Sending, Status.Send]
    },
    {
        status: Status.Cancelled,
        overrides: [Status.Sending, Status.Send]
    },
]

export const getReference = (maintenance: Maintenance): MaintenanceRef => {
    return {
        uid: maintenance.uid,
        number: maintenance.number as number,
        date: maintenance.date,
    }
}

// Referencias -----------------------------------------------

export type BranchRef = BaseBranchRef & {
    emails: string[],
    address: Address | null
}

export type ClientRef = BaseClientRef & IdentifiedDocument & {
    manualEmails: string[],
    branch: BranchRef
}

export const getBranchReference = (branch: Branch): BranchRef => {
    return {
        ...getBaseBranchReference(branch),
        emails: branch.emails.filter(e => e.usages.includes(EmailUsage.SendMaintenances)).map(e => e.address),
        address: branch.address,
    }
}

export const getClientReference = (client: Client): ClientRef => {
    return {
        uid: client.uid,
        image: client.image,
        identificationType: client.identificationType,
        identification: client.identification,
        name: client.name,
        tradeName: client.tradeName,        
        branch: null as any,
        manualEmails: []
    }
}