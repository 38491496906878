import { Timestamp } from "@angular/fire/firestore";
import { CodigoReferencia } from "./facturaelectronica/CodigoReferencia";
import { TipoDocumentoReferencia } from "./facturaelectronica/TipoDocumentoReferencia";
import { IdentifiedDocument, UniqueDocument, TransactionalDocument } from "./BaseDocument";
import { ClientRef as BaseClientRef, Client, getReference as getBaseClientReference } from "./Client";
import { CompanyRef as BaseCompanyRef, Company, getReference as getBaseCompanyReference } from "./Company";
import { FixedPrice, Product, ProductRef as BaseProductRef, getReference as getBaseProductReference } from "./Product";
import { CashDeskRef as BaseCashDeskRef, CashDesk, getReference as getBaseCashDeskReference } from "./CashDesk";
import { LogBase } from "./LogBase";
import { Stock } from "./Stock";
import { Warehouse, WarehouseRef, getReference as getWarehouseReference } from "./Warehouse";
import { CurrencyRef } from "./Currency";
import { Address } from "./Address";
import { Telephone } from "./Telephone";
import { CompanyBranch, CompanyBranchRef as BaseCompanyBranchRef, getReference as getBaseCompanyBranchReference } from "./CompanyBranch";
import { ClientBranch } from "./ClientBranch";
import { UnidadMedida } from "./facturaelectronica/UnidadMedida";
import { MultiField } from "./MultiField";
import { ListItem } from "./ListItem";
import { BranchRef, getReference as getBaseBranchReference } from "./Branch";
import { Payment } from "./Payment";
import { Summary as BaseSummary } from "./Summary";
import { EmailUsage } from "./Email";
import { SupplierRef as BaseSupplierRef, getReference as getBaseSupplierRef, Supplier } from "./Supplier";
import { SupplierBranch } from "./SupplierBranch";
import { PaymentTerm } from "./PaymentTerm";
import { Tax, TaxType } from "./Tax";
import { DigitalDocumentResponse } from "./DigitalDocumentResponse";
import { Other } from "./Others";

export type DigitalDocument = TransactionalDocument &
{
  isDraft: boolean;

  type: DocumentType,
  key: string;
  number: string;

  company: CompanyRef;
  economicActivity: string;

  client: ClientRef | string | null | undefined,
  supplier: SupplierRef | null | undefined,
  currency: CurrencyRef & { exchangeRateCRC: number },
  paymentTerm: PaymentTerm,

  payments: Payment[];

  lines: Line[];

  references: DocumentReference[]; //Referencias agregadas por el usuario y que se envian a Hacienda
  relatedDocuments: DigitalDocumentRef[]; //Documentos relacionados controlados internamente, deben estar las referencias tambien

  others: Other[];

  notes: string;

  statusLog: Log[];

  pdf: string | Blob;
  xls: string | Blob,
  xml: string | Blob | null;
  xmlResponse: string | Blob | null;

  response: DigitalDocumentResponse | null;
}

export type DigitalDocumentRef = UniqueDocument & {
  number: string;
  key: string;
  date: Timestamp;
}

export enum DocumentType {
  Ticket = "TE",
  Invoice = "FE",
  PurchaseInvoice = "FEC",
  PurchaseInvoiceVoid = "FEC-V",
  PurchaseInvoiceReplace = "FEC-R",
  CreditNote = "NC",
  CreditNoteVoid = "NC-V",
  CreditNoteDiscount = "NC-D",
  CreditNoteRefound = "NC-R",
  DebitNote = "ND",
  DebitNoteVoid = "ND-V",
}

export const documentTypeDetails: ListItem[] = [
  {
    uid: DocumentType.Ticket, name: "Tiquete Electrónico", code: "TE", color: "primary"
  },
  {
    uid: DocumentType.Invoice, name: "Factura Electrónica", code: "FE", color: "primary"
  },
  {
    uid: DocumentType.PurchaseInvoice, name: "Factura Electrónica de Compra", code: "FC", color: "primary"
  },
  {
    uid: DocumentType.PurchaseInvoiceVoid, name: "Factura Electrónica de Compra", code: "FC", color: "danger"
  },
  {
    uid: DocumentType.PurchaseInvoiceReplace, name: "Factura Electrónica de Compra", code: "FC", color: "primary"
  },
  {
    uid: DocumentType.CreditNote, name: "Nota de Crédito", code: "NC", color: "danger"
  },
  {
    uid: DocumentType.CreditNoteVoid, name: "Nota de Crédito", code: "NC", color: "danger"
  },
  {
    uid: DocumentType.CreditNoteDiscount, name: "Nota de Crédito", code: "NC", color: "danger"
  },
  {
    uid: DocumentType.CreditNoteRefound, name: "Nota de Crédito", code: "NC", color: "danger"
  },
  {
    uid: DocumentType.DebitNote, name: "Nota de Débito", code: "ND", color: "danger"
  },
  {
    uid: DocumentType.DebitNoteVoid, name: "Nota de Débito", code: "ND", color: "danger"
  },
]

export type Line = {
  amount: number,
  product: ProductRef,
  price: number,
  discountPercentage: number,
  additionalDiscountPercentage: number | null, //Solo se usa en las Notas de Credito por Descuento  
  modifiers: { //Solo se usa en las facturas electronicas con notas de credito o notas de credito anuladas
    refoundAmount: number | null, //Sumatoria de todos las devoluciones realizado a la linea
    additionalDiscountPercentage: number | null, //Sumatoria de todos los descuentos adicionales aplicados a la linea
  } | null
}

export type LineTotals = {
  unitDiscount: number,
  unitTax: number,
  unitTotal: number,
  subTotal: number,
  discount: number,
  tax: number,
  total: number,
  additionalDiscount: {
    unitDiscount: number,
    unitTax: number,
    unitTotal: number,
    discount: number,
    tax: number,
    total: number,
  } | null,
  modified: {
    unitDiscount: number,
    unitTax: number,
    unitTotal: number,
    subTotal: number,
    discount: number,
    tax: number,
    total: number,
  } | null
}

export type DocumentReference = {
  number: string;
  date: Timestamp;
  type: TipoDocumentoReferencia;
  code: CodigoReferencia | null;
  reason: string;
}

export type ExchangeRate = {
  currency: CurrencyRef,
  rate: number
}

export type Log = LogBase & {
  status: Status;
}

export enum Status {
  Draft = "DF",
  New = "N",
  Sending = "SP",
  ReSending = "RS",
  Send = "S",
  AceptedByHacienda = "AH",
  RejectedByHacienda = "RH",
  AcceptedByClient = "AC",
  PartialAcceptedByClient = "PAC",
  RejectedByClient = "RC",
  AceptedByHaciendaResponse = "AHR",
  RejectedByHaciendaResponse = "RHR",
  Error = "E",
}

export const statusDetails: ListItem[] = [
  {
    uid: Status.Draft, name: "Borrador", color: "medium"
  },
  {
    uid: Status.New, name: "Nueva", color: "medium"
  },
  {
    uid: Status.Sending, name: "Enviando", color: "primary"
  },
  {
    uid: Status.ReSending, name: "Reenviando", color: "primary"
  },
  {
    uid: Status.Send, name: "Enviada", color: "success"
  },
  {
    uid: Status.AceptedByHacienda, name: "Aceptada por MH", color: "success"
  },
  {
    uid: Status.RejectedByHacienda, name: "Rechazada por MH", color: "danger"
  },
  {
    uid: Status.AcceptedByClient, name: "Aceptada", color: "success"
  },
  {
    uid: Status.PartialAcceptedByClient, name: "Aceptada Parcial", color: "warning"
  },
  {
    uid: Status.RejectedByClient, name: "Rechazada", color: "danger"
  },
  {
    uid: Status.AceptedByHaciendaResponse, name: "Respuesta Aceptada", color: "success"
  },
  {
    uid: Status.RejectedByHaciendaResponse, name: "Respuesta Rechazada", color: "danger"
  },
  {
    uid: Status.Error, name: "Error", color: "danger"
  },
]

export const statusPriorities = [
  {
    status: Status.New,
    overrides: [Status.Sending, Status.Send]
  },
  {
    status: Status.AceptedByHacienda,
    overrides: [Status.Sending, Status.Send]
  },
  {
    status: Status.RejectedByHacienda,
    overrides: [Status.Sending, Status.Send]
  },
  {
    status: Status.AcceptedByClient,
    overrides: [Status.Sending, Status.Send, Status.AceptedByHaciendaResponse]
  },
  {
    status: Status.PartialAcceptedByClient,
    overrides: [Status.Sending, Status.Send, Status.AceptedByHaciendaResponse]
  },
  {
    status: Status.RejectedByClient,
    overrides: [Status.Sending, Status.Send, Status.AceptedByHaciendaResponse]
  },
  {
    status: Status.RejectedByHaciendaResponse,
    overrides: [Status.Sending, Status.Send]
  },
]

export type Summary = BaseSummary & {
  modified: {
    subTotal: number,
    discount: number,
    tax: number,
    total: number,
  } | null
}

export const getReference = (document: DigitalDocument): DigitalDocumentRef => {
  return {
    uid: document.uid,
    number: document.number,
    key: document.key,
    date: document.date
  }
}

export const calculateLineTotals = (line: Line): LineTotals => {
  const totals: LineTotals = {
    unitDiscount: 0,
    unitTax: 0,
    unitTotal: 0,
    subTotal: 0,
    discount: 0,
    tax: 0,
    total: 0,
    additionalDiscount: null,
    modified: null,
  };

  const totalTaxPercentage = line.product.taxes.reduce((acc, tax) => acc + (tax.percentage - (tax.exempt ? tax.exempt.percentage : 0)), 0);

  //No toma encuenta las notas de credito y debito
  totals.unitDiscount = line.price * (line.discountPercentage ?? 0);
  totals.unitTax = (line.price - totals.unitDiscount) * totalTaxPercentage;
  totals.unitTotal = line.price - totals.unitDiscount + totals.unitTax;

  totals.subTotal = line.price * line.amount;
  totals.discount = totals.unitDiscount * line.amount;
  totals.tax = totals.unitTax * line.amount;
  totals.total = totals.unitTotal * line.amount;

  //Si hay un descuento adicional calcula los valores
  if (line.additionalDiscountPercentage) {
    totals.additionalDiscount = {
      unitDiscount: 0,
      unitTax: 0,
      unitTotal: 0,
      discount: 0,
      tax: 0,
      total: 0,
    }

    totals.additionalDiscount.unitDiscount = line.price * (line.additionalDiscountPercentage ?? 0);
    totals.additionalDiscount.unitTax = (totals.additionalDiscount.unitDiscount) * totalTaxPercentage;
    totals.additionalDiscount.unitTotal = totals.additionalDiscount.unitDiscount + totals.additionalDiscount.unitTax;

    totals.additionalDiscount.discount = totals.additionalDiscount.unitDiscount * line.amount;
    totals.additionalDiscount.tax = totals.additionalDiscount.unitTax * line.amount;
    totals.additionalDiscount.total = totals.additionalDiscount.unitTotal * line.amount;
  }

  //SI toma en cuenta las notas de credito y debito
  //Si tiene algun modificador significa que se han aplicado notas de credito o debito
  if (line.modifiers) {
    totals.modified = {
      unitDiscount: 0,
      unitTax: 0,
      unitTotal: 0,
      subTotal: 0,
      discount: 0,
      tax: 0,
      total: 0,
    };

    const adjustedAmount = (line.amount - (line.modifiers.refoundAmount ?? 0));

    totals.modified.unitDiscount = line.price * ((line.discountPercentage ?? 0) + (line.modifiers.additionalDiscountPercentage ?? 0));
    totals.modified.unitTax = (line.price - totals.modified.unitDiscount) * totalTaxPercentage;
    totals.modified.unitTotal = line.price - totals.modified.unitDiscount + totals.modified.unitTax;

    totals.modified.subTotal = line.price * adjustedAmount;
    totals.modified.discount = totals.modified.unitDiscount * adjustedAmount;
    totals.modified.tax = totals.modified.unitTax * adjustedAmount;
    totals.modified.total = totals.modified.unitTotal * adjustedAmount;
  }

  return totals;
}

export const calculateSummary = (lines: LineTotals[], roundFactor: number = 100): Summary => {
  const summary: Summary = {
    subTotal: 0,
    discount: 0,
    tax: 0,
    total: 0,
    modified: null
  };

  const hasAdditionalDiscount = lines.some(l => !!l.additionalDiscount);

  const hasModifiers = lines.some(l => !!l.modified)

  if (hasModifiers) {
    summary.modified = {
      subTotal: 0,
      discount: 0,
      tax: 0,
      total: 0,
    }
  }

  for (const line of lines) {
    if (!hasAdditionalDiscount) {
      summary.subTotal += line.subTotal;
      summary.discount += line.discount;
      summary.tax += line.tax;
      summary.total += line.total;
    }
    else {
      summary.subTotal += line.additionalDiscount?.discount ?? 0;
      summary.discount += 0;
      summary.tax += line.additionalDiscount?.tax ?? 0;
      summary.total += line.additionalDiscount?.total ?? 0;
    }

    if (hasModifiers) {
      summary.modified!.subTotal += line.modified?.subTotal ?? line.subTotal;
      summary.modified!.discount += line.modified?.discount ?? line.discount;
      summary.modified!.tax += line.modified?.tax ?? line.tax;
      summary.modified!.total += line.modified?.total ?? line.total;
    }
  }

  summary.subTotal = Math.round(summary.subTotal * roundFactor) / roundFactor;
  summary.discount = Math.round(summary.discount * roundFactor) / roundFactor;
  summary.tax = Math.round(summary.tax * roundFactor) / roundFactor;
  summary.total = Math.round(summary.total * roundFactor) / roundFactor;

  return summary;
}

//Claves de los documentos electronicos
export const getDocumentKey = async (date: Date, documentNumber: string, company: BaseCompanyRef, crypto: Crypto) => {
  const countryCode = '506';
  const day = date.getDate().toString().padStart(2, '0');
  //date.getMonth() devuelve del 0 al 11
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear().toString().slice(2);
  const identifiaction = company.identification.toString().padStart(12, '0');
  const type = "1" //1. Situación Normal, 2. Contingencia, 3. Sin Internet. TODO: deferenciar entre cada tipo.

  const baseKey = `${countryCode}${day}${month}${year}${identifiaction}${documentNumber}${type}`

  const code = await securityCode(baseKey, crypto);

  return `${baseKey}${code}`
}

const securityCode = async (input: string, crypto: Crypto): Promise<string> => {
  const buffer = new TextEncoder().encode(input);
  const hashArray = await crypto.subtle.digest('SHA-256', buffer);
  const hashHex = Array.prototype.map.call(new Uint8Array(hashArray), x => ('00' + x.toString(10)).slice(-2)).join('');
  return hashHex.substring(0, 8); // Tomar los primeros 8 caracteres del hash hexadecimal
}

//#endregion

//#region Company References

export type CompanyRef = BaseCompanyRef & {
  branch: CompanyBranchRef;

  configuration: {
    digitalInvoice: {
      isEnable: boolean;
      email: string;
    },
    stock: {
      isEnable: boolean;
      allowNegative: boolean;
    }
  }
}

export type CompanyBranchRef = BaseCompanyBranchRef & {
  warehouse: WarehouseRef | null;
  cashDesk: CashDeskRef;

  address: Address;
  telephones: Telephone[];
}

export type CashDeskRef = BaseCashDeskRef & {
  balanceId: string | null;
}

export const getCompanyReference = (company: Company, branch: CompanyBranch, warehouse: Warehouse | null, cashDesk: CashDesk, balanceId: string | null): CompanyRef => {
  return {
    ...getBaseCompanyReference(company),
    branch: getCompanyBranchReference(branch, warehouse, cashDesk, balanceId),
    configuration: {
      digitalInvoice: {
        isEnable: company.configuration.digitalInvoice.isEnable,
        email: company.configuration.digitalInvoice.email,
      },
      stock: {
        isEnable: company.configuration.stock.isEnable,
        allowNegative: company.configuration.stock.allowNegative,
      },
    }
  }
}

export const getCompanyBranchReference = (branch: CompanyBranch, warehouse: Warehouse | null, cashDesk: CashDesk, balanceId: string | null): CompanyBranchRef => {
  return {
    ...getBaseCompanyBranchReference(branch),
    warehouse: warehouse ? getWarehouseReference(warehouse) : null,
    cashDesk: getCashDeskReference(cashDesk, balanceId),

    address: branch.address,
    telephones: branch.telephones,
  }
}

export const getCashDeskReference = (cashDesk: CashDesk, balanceId: string | null): CashDeskRef => {
  return {
    ...getBaseCashDeskReference(cashDesk),
    balanceId: balanceId,
  }

}

//#endregion

//#region Client References

export type ClientRef = BaseClientRef & IdentifiedDocument & {
  branch: ClientBranchRef

  manualEmails: string[];
  tradeName: string | null;
  priceListId: string | null;
}

export type ClientBranchRef = BranchRef & {
  emails: string[],
  address: Address | null;
  telephones: Telephone[];
}

export const getClientReference = (client: Client): ClientRef => {
  return {
    ...getBaseClientReference(client),
    identificationType: client.identificationType,
    identification: client.identification,
    priceListId: client.priceListId,
    branch: null as any,
    manualEmails: [],
  }
}

export const getClientBranchReference = (branch: ClientBranch): ClientBranchRef => {
  return {
    ...getBaseBranchReference(branch),
    emails: branch.emails.filter(e => e.usages.includes(EmailUsage.SendDigitalDocuments)).map(e => e.address),
    address: branch.address,
    telephones: branch.telephones,
  }
}

//#endregion

//#region Product References

export type ProductRef = BaseProductRef & {
  code: string;
  cost: number;
  maxDiscount: number;
  allowResellBelowCost: boolean;
  taxes: Tax[],
  fixedPrices: FixedPrice[];
  unit: UnidadMedida;
  cabysCode: string;
  stock: Stock,
}

export const getProductReference = (product: Product, fields: MultiField[]): ProductRef => {
  return {
    ...getBaseProductReference(product, fields),
    code: product.code!,
    cost: product.cost,
    maxDiscount: product.maxDiscount,
    allowResellBelowCost: product.allowResellBelowCost,
    taxes: product.taxPercentage !== null ? [{ type: TaxType.IVA, percentage: product.taxPercentage, exempt: null }] : [],
    fixedPrices: product.fixedPrices,
    unit: product.unit,
    cabysCode: product.cabysCode!,
    stock: product.stock,
  }
}

//#endregion

//#region Supplier References

export type SupplierRef = BaseSupplierRef & IdentifiedDocument & {
  manualEmails: string[],
  branch: SupplierBranchRef
}

export type SupplierBranchRef = BranchRef & {
  emails: string[],
  address: Address | null;
  telephones: Telephone[];
}

export const getSupplierReference = (supplier: Supplier): SupplierRef => {
  return {
    ...getBaseSupplierRef(supplier),
    identificationType: supplier.identificationType,
    identification: supplier.identification,
    branch: null as any,
    manualEmails: []
  }
}

export const getSupplierBranchReference = (branch: SupplierBranch): SupplierBranchRef => {
  return {
    ...getBaseBranchReference(branch),
    emails: branch.emails.filter(e => e.usages.includes(EmailUsage.SendDigitalDocuments)).map(e => e.address),
    address: branch.address,
    telephones: branch.telephones,
  }
}

//#endregion