import { ListDocument, UniqueDocument } from "./BaseDocument";
import { PrintableSize } from "./PrintableSize";

export type CashDesk = ListDocument & CashDeskRef & {
  branchId: string;
  priceListId: string;

  mustPrintDocuments: boolean;
  printableSize: PrintableSize;
  includeLogo: boolean;
}

export type CashDeskRef = UniqueDocument & {
  number: number;
}

export type Consecutive = {
  uid: string;
  number: number;
}

export enum Consecutives {
  Invoice = "invoice",
  Ticket = "ticket",
  CreditNote = "creditNote",
  DebitNote = "debitNote",
  PurchaseInvoice = "purchaseInvoice",
  Accept = "accept",
  PartialAccept = "partialAccept",
  Reject = "reject",
}


export const getReference = (cashDesk: CashDesk): CashDeskRef => {
  return {
    uid: cashDesk.uid,
    number: cashDesk.number
  }
}

export const ExternalCashDeskId = "--external--"