<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button *ngIf="!isModal"></ion-menu-button>
    </ion-buttons>
    <ion-title>{{title}} <ng-content select="[title]"></ng-content></ion-title>
    <ion-buttons slot="end">
      <ng-content select="[buttons]"></ng-content> 
      <ion-button (click)="toogleSearchBar()" class="ion-hide-sm-up" [class.hiddeOut]="!showsSearchButton || isModal"
      [class.showIn]="showsSearchButton && !isModal" *ngIf="!isLoading">
      <ion-icon slot="icon-only" name="search"></ion-icon>
    </ion-button>
    <ion-button (click)="showOptions($event)" fill="clear" color="dark" [class.hiddeOut]="!showsOptionsButton || isModal"
    [class.showIn]="showsOptionsButton && !isModal" *ngIf="!isLoading">
    <ion-icon slot="icon-only" name="options"></ion-icon>
  </ion-button>
</ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="search-toolbar" [class.collapse]="(!isSearchBarExpanded || !showSearchBar) && !isModal">
    <ion-searchbar #searchbar show-clear-button="always" animated="true" placeholder="Buscar" enterkeyhint="search"
      (ionInput)="ionInput.emit($event)" (ionChange)="ionChange.emit($event)" [debounce]="300">
      <ng-content select="[searchbar]"></ng-content>
    </ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-popover #options side="bottom" alignment="end" [isOpen]="isOptionsOpen" (didDismiss)="isOptionsOpen = false">
  <ng-template>
    <ng-content select="[options]"></ng-content>
  </ng-template>
</ion-popover>