import { environment } from "src/environments/environment";
import { AdjustmentType, PriceList, ProfitType } from "../../../models/PriceList";
import { FieldValue, Timestamp, serverTimestamp } from "@angular/fire/firestore";
import { DynamicPermissionsList, PermissionGroup, PermissionsCodes } from "models/Permission";
import { Analytics, logEvent } from "@angular/fire/analytics";
import { ListItem } from "models/ListItem";
import { DynamicsService } from "../services/dynamics.service";
import { Router } from "@angular/router";
import { canActivate } from "@angular/fire/auth-guard";
import { DirtyFormGuard, redirectUnauthorizedToLogin } from "./guards";
import { routes } from "../app.routes";
import { AuthService } from "../services/auth.service";
import { Parse, SignedXml } from "xmldsigjs";
import { extensionFromUrl, formatXMLAsHaciendaResponse } from "shared/utilities";

export const toIsoString = (date: Date): string => {
    const tzo = -date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = (num: number) => {
        return (num < 10 ? '0' : '') + num;
    };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
}

export const trimProperties = (obj: any): any => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if ((typeof value) === 'string') {
                obj[key] = obj[key].trim();
            } else if (typeof value === 'object') {
                trimProperties(value);
            }
        }
    }
}

export const isBase64Image = (url: string): { isBase64: boolean, format: string, data: string } => {
    // Lista de extensiones de archivos que consideramos como imágenes
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

    // Verificar si el URL comienza con 'data:image/'
    if (url.startsWith('data:image/')) {
        const [prefix, data] = url.split(';base64,');
        // Extraer el tipo de imagen desde el URL
        const format = prefix.split(':')[1].split('/')[1];
        // Verificar si el tipo de imagen es válido
        if (imageExtensions.includes(format)) {
            return { isBase64: true, format: format, data: data };
        }
    }
    else {
        // Verificar si el URL contiene una extensión de archivo
        const fileExtension = url.split('.').pop()?.toLowerCase();

        if (fileExtension && imageExtensions.includes(fileExtension)) {
            return { isBase64: true, format: fileExtension, data: "" }
        } else {
            return { isBase64: false, format: fileExtension ?? "", data: "" }
        }
    }

    return { isBase64: false, format: "", data: "" };
}

export const getBase64FromUrl = async (url: string): Promise<{ data: string, format: string | null, extension: string | null, aspectRatio: number }> => {
    const response = await fetch(url);
    const contentType = response.headers.get('content-type');
    const extension = extensionFromUrl(url);

    const blob = await response.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result as string;

            const image = new Image();
            image.onload = () => {
                const aspectRatio = image.width / image.height;
                resolve({ data: base64data, extension: extension, format: contentType, aspectRatio: aspectRatio });
            };
            image.src = base64data;
        };
    });
}

export const restoreTimestampObjects = (obj: any): any => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value instanceof Timestamp) {
                continue;
            } else if (value && value.seconds != undefined && value.nanoseconds != undefined) {
                obj[key] = new Timestamp(value.seconds, value.nanoseconds);
                continue;
            } else if (typeof value === 'object') {
                restoreTimestampObjects(value);
            }
        }
    }
}

export const splitArray = <T>(array: T[], size: number): T[][] => {
    if (!array || array.length === 0) {
        return [[]];
    }

    const groups = [];

    for (let i = 0; i < array.length; i += size) {
        groups.push(array.slice(i, i + size));
    }
    return groups;
}

export const getImageFormat = (url: string): string | null => {
    try {
        const urlObj = new URL(url);
        const pathname = urlObj.pathname;
        const filename = pathname.split('/').pop(); // Obtiene el nombre del archivo
        if (filename) {
            const extension = filename.split('.').pop(); // Obtiene la extensión del archivo
            if (extension) {
                const formato = extension.toLowerCase();
                if (formato === 'jpg' || formato === 'jpeg') {
                    return 'JPEG';
                } else if (formato === 'png') {
                    return 'PNG';
                } else {
                    return null; // Formato de imagen desconocido
                }
            }
        }
        return null; // No se pudo determinar el formato
    } catch (error) {
        console.error('Error al obtener el formato de la imagen:', error);
        return null;
    }
}

export const setPWAThemeColor = () => {
    const darkModeColor = '#34315d';
    const lightModeColor = '#edecf5';

    const themeColor = document.querySelector('meta[name="theme-color"]');

    if (!themeColor) {
        return;
    }

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        themeColor.setAttribute('content', darkModeColor);
    }
    else {
        themeColor.setAttribute('content', lightModeColor);
    };

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        if (event.matches) {
            themeColor.setAttribute('content', darkModeColor);
        }
        else {
            themeColor.setAttribute('content', lightModeColor);
        };
    });
}

export const checkPermissionConsistency = () => {
    //Si esá local revisa que los Ids de los permisos no tengan errores
    if (environment.name === 'Local') {
        let isValid = true;
        const values = Object.values(PermissionsCodes).filter((v) => !isNaN(Number(v))).map(v => Number(v));

        const min = Math.min(...values);
        const max = Math.max(...values);

        for (let num = min; num <= max; num++) {
            const permission = values.filter(v => v === num);

            if (!permission || permission.length === 0) {
                console.warn(`El Permiso número ${num} no existe!!!`);
                // isValid = false;
                continue;
            }

            if (permission.length > 1) {
                console.error(`El Permiso número ${num} esta duplicado!!!`);
                isValid = false;
            }
        }

        if (!isValid) {
            alert("Los permisos no están bien configurados, revisa la consola para más información");
        }
    }
}

export const logAnalyticsError = (analytics: Analytics, error: any, reference: string) => {
    let parameters: { description: string, fatal: boolean, stack: any, reference: string } = {
        description: "",
        fatal: false,
        stack: undefined,
        reference: reference
    };

    if (typeof (error) === 'string') {
        parameters.description = error;
    }

    if (typeof (error) === 'object') {
        if ('message' in error) {
            parameters.description = error.message;
        }
        else if ('toString' in error) {
            parameters.description = error.toString();

            if (parameters.description.startsWith("[object")) {
                parameters.description = JSON.stringify(error);
            }

            if (parameters.description === "{}") {
                parameters.description = error;
            }
        } else {
            parameters.description = error;
        }

        if ('stack' in error) {
            parameters.stack = error.stack;
        }
    }

    logEvent(analytics, 'exception', parameters)
}

export const discountList = (max: number) => {
    const list: ListItem[] = [];

    list.push({
        uid: null,
        name: "0"
    })

    for (let i = 1; i <= 5; i++) {
        if (i <= max) {
            list.push({
                uid: i / 100,
                name: `${i}%`
            });
        }
    }

    for (let i = 10; i <= 20; i += 5) {
        if (i <= max) {
            list.push({
                uid: i / 100,
                name: `${i}%`
            });
        }
    }

    return list;
}

export const isEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) {
        return true;
    }

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (!keys2.includes(key)) {
            return false;
        }

        if (!isEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

export const updateDynamicRoutes = async (auth: AuthService, dynamicsService: DynamicsService, router: Router) => {
    console.log('Updating dynamic routes...');

    let lastCompanyId: string | undefined = undefined;

    return new Promise<void>((resolve, reject) => {

        auth.profile.subscribe(async profile => {
            if (!profile) {
                return;
            }

            if (lastCompanyId === profile.company.uid) {
                return;
            }

            lastCompanyId = profile.company.uid;

            const pages = (await dynamicsService.allOnce()).sort((a, b) => a.position - b.position || a.createdDate.toMillis() - b.createdDate.toMillis());

            // Definir las rutas dinámicas
            const dynamicRoutes = pages
                .filter(d => d.isActive)
                .map(page => {
                    return {
                        path: page.uid.toLowerCase(),
                        loadComponent: () => import('../../app/pages/dynamic-list/dynamic-list.page').then(m => m.DynamicListPage),
                        ...canActivate(redirectUnauthorizedToLogin),
                        canDeactivate: [DirtyFormGuard]
                    }
                });

            // Combinar las rutas actuales con las nuevas dinámicas
            router.resetConfig([...dynamicRoutes, ...routes]);


            //Permisos de las paginas dinamicas

            DynamicPermissionsList.splice(0, DynamicPermissionsList.length - 1);

            const groupControl: PermissionGroup = {
                name: "Almacen",
                permissions: []
            };

            for (const page of pages.filter(d => d.isActive)) {
                const pageNumber = Number(page.uid.split("_")[0]);

                const View = dynamicPermissionNumber(pageNumber, "View");
                const Edit = dynamicPermissionNumber(pageNumber, "Edit");
                const Create = dynamicPermissionNumber(pageNumber, "Create");
                const Disable = dynamicPermissionNumber(pageNumber, "Disable");

                const required: number[] = [];

                if (page.parent.field) {
                    const parent = pages.find(d => d.uid === page.parent.pageId);

                    if (parent) {
                        const parentNumber = Number(parent.uid.split("_")[1]);

                        const parentView = dynamicPermissionNumber(parentNumber, "View");

                        required.push(parentView);
                    }
                }

                groupControl.permissions.push({
                    uid: View,
                    name: "Ver " + page.labels.plural,
                    added: [],
                    required: required,
                    isEnabled: true
                });

                groupControl.permissions.push({
                    uid: Edit,
                    name: "Editar " + page.labels.plural,
                    added: [View],
                    required: required,
                    isEnabled: true
                });

                groupControl.permissions.push({
                    uid: Create,
                    name: "Crear " + page.labels.plural,
                    added: [View],
                    required: required,
                    isEnabled: true
                });

                groupControl.permissions.push({
                    uid: Disable,
                    name: "Desactivar " + page.labels.plural,
                    added: [View],
                    required: required,
                    isEnabled: true
                });
            }

            DynamicPermissionsList.push(groupControl);

            resolve();
        });
    });
}

export const dynamicPermissionNumber = (pageNumber: number, action: "View" | "Edit" | "Create" | "Disable") => {
    return (((pageNumber - 1) * 4) + (["View", "Edit", "Create", "Disable"].indexOf(action) + 1)) * -1;
}

export const justNumbers = (event: KeyboardEvent) => {
    // Permitir: backspace, delete, tab, escape, enter y .
    if (['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', '.'].indexOf(event.key) !== -1 ||
        // Permitir: Ctrl/cmd+A
        (event.key === 'a' && (event.ctrlKey === true || event.metaKey === true)) ||
        // Permitir: Ctrl/cmd+C
        (event.key === 'c' && (event.ctrlKey === true || event.metaKey === true)) ||
        // PermitirL Ctrl/cmd+V
        (event.key === 'v' && (event.ctrlKey === true || event.metaKey === true)) ||
        // PermitirL Ctrl/cmd+Z
        (event.key === 'z' && (event.ctrlKey === true || event.metaKey === true)) ||
        // Permitir: Ctrl/cmd+X
        (event.key === 'x' && (event.ctrlKey === true || event.metaKey === true)) ||
        // Permitir: home, end, left, right
        (event.key === 'Home' || event.key === 'End' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
        // No hacer nada, dejar que las teclas normales funcionen
        return;
    }
    // Asegurarse de que sea un número y detener la acción predeterminada si no lo es
    if ((event.shiftKey || (event.key < '0' || event.key > '9')) && (event.key < '0' || event.key > '9')) {
        event.preventDefault();
    }
}

export const isInteger = (text: string) => {
    return /^\s*\d+\s*$/.test(text);
}

export class ClassWatcher {
    observer: MutationObserver | null;
    lastClassState: boolean;


    constructor(
        private targetNode: Element,
        private classToWatch: string,
        private classAddedCallback: (() => void) | undefined = undefined,
        private classRemovedCallback: (() => void) | undefined = undefined
    ) {

        this.targetNode = targetNode
        this.classToWatch = classToWatch
        this.classAddedCallback = classAddedCallback
        this.classRemovedCallback = classRemovedCallback
        this.observer = null
        this.lastClassState = targetNode.classList.contains(this.classToWatch)

        this.init()
    }

    init() {
        this.observer = new MutationObserver(this.mutationCallback)
        this.observe()
    }

    observe() {
        this.observer?.observe(this.targetNode, { attributes: true })
    }

    disconnect() {
        this.observer?.disconnect()
    }

    mutationCallback = (mutationsList: MutationRecord[]) => {
        for (let mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                let currentClassState = (mutation.target as Element).classList.contains(this.classToWatch)
                if (this.lastClassState !== currentClassState) {
                    this.lastClassState = currentClassState
                    if (currentClassState) {
                        if (this.classAddedCallback) {
                            this.classAddedCallback()
                        }
                    }
                    else {
                        if (this.classRemovedCallback) {
                            this.classRemovedCallback()
                        }
                    }
                }
            }
        }
    }
}

export const sort = (a: any, b: any, direction: 'asc' | 'desc'): number => {
    const directionMultiplier = direction === 'asc' ? 1 : -1;

    if (a === null || a === undefined) {
        return -1 * directionMultiplier;
    }

    if (b === null || b === undefined) {
        return 1 * directionMultiplier;
    }

    const typeA = typeof (a)
    const typeB = typeof (b);

    if (typeA !== typeB) {
        throw Error(`Both items must have the same type '${typeA}' '${typeB}'`);
    }

    if (typeA === 'string' && typeB === 'string') {
        return a.localeCompare(b) * directionMultiplier;
    }

    if (typeA === 'number' || typeA === 'bigint') {
        return (a - b) * directionMultiplier;
    }

    if ('toMillis' in a) {
        return (a.toMillis() - b.toMillis()) * directionMultiplier
    }

    throw new Error(`Unhandled sorting type '${typeA}'`);

}

export const verifySignature = async (xmlString: string, retry: boolean = false): Promise<boolean> => {
    try {
        // Parse the XML string
        const xmlDoc = Parse(xmlString);

        // Find the Signature element
        const signatureElement = xmlDoc.getElementsByTagNameNS('http://www.w3.org/2000/09/xmldsig#', 'Signature')[0];
        if (!signatureElement) {
            return false;
        }

        // Load the XMLSignature object
        const signedXml = new SignedXml(xmlDoc);

        // Load the signature from the element
        signedXml.LoadXml(signatureElement);

        // Verify the signature using the public key
        const isValid = await signedXml.Verify();

        return isValid;
    } catch (error) {
        if (retry) {
            console.log('Invalid Signature. Retrying with formatted XML');
            const formattedXML = formatXMLAsHaciendaResponse(xmlString);
            return verifySignature(formattedXML, false);
        }

        console.error('Error verifying signature:', error);
        return false;
    }
}

export const quillModules = {
    toolbar: [

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        // ['blockquote', 'code-block'],

        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        // [{ 'direction': 'rtl' }],                         // text direction

    ]
};