import { Injectable, NgZone } from '@angular/core';
import { Log, LogLevels } from 'models/Log'
import { Firestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { firstValueFrom } from 'rxjs';
import { Analytics } from '@angular/fire/analytics';
import { logAnalyticsError } from '../utilities/utililties';
import { environment } from '../../environments/environment';
import { serverTimestamp, setDoc, doc, } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  static lastError: { message: string, stack: string, date: Date, count: number, reference: string, timeout: number | undefined } | undefined;

  constructor(
    public auth: AuthService,
    protected store: Firestore,
    protected analytics: Analytics,
    private zone: NgZone,
  ) {
  }

  error(error: any): string {
    let message = "";
    let stack = "";

    if (typeof (error) === 'string') {
      message = error;
    }

    if (typeof (error) === 'object') {
      if ('message' in error) {
        message = error.message;
      }
      else if ('toString' in error) {
        message = error.toString();

        if (message.startsWith("[object")) {
          message = JSON.stringify(error);
        }

        if (message === "{}") {
          message = error;
        }
      } else {
        message = error;
      }

      if ('stack' in error) {
        stack = error.stack;
      }
    }

    if (LoggerService.lastError && LoggerService.lastError?.message === message && LoggerService.lastError?.stack === stack) {
      LoggerService.lastError.count++;

      if (!LoggerService.lastError.timeout) {
        if (LoggerService.lastError && !LoggerService.lastError.timeout) {
          //Corre fuera de angular ya que si el error esta en el template, ejecuta la deteccion de errores y genera un ciclo infinido
          this.zone.runOutsideAngular(() => {
            if (LoggerService.lastError && !LoggerService.lastError.timeout) {
              LoggerService.lastError.timeout = window.setTimeout(() => {
                console.warn('LOG TiMEOUT')
                this.Log('error', `[Parent: ${LoggerService.lastError?.reference}] [${LoggerService.lastError?.count} times] [1.5s] ${message}`, stack);
                LoggerService.lastError = undefined;
              }, 1500);
            }
          });
        }
      }

      console.log(`Skipping Log: ${LoggerService.lastError.count}`);
      return LoggerService.lastError.reference;
    }

    if (LoggerService.lastError && LoggerService.lastError.count > 0) {
      this.Log('error', `[Parent: ${LoggerService.lastError?.reference}] [${LoggerService.lastError?.count} times] [otr] ${message}`, stack);

      const timeout = LoggerService.lastError.timeout;

      console.warn('Clearing Timeout', timeout);
      window.clearTimeout(timeout);
    }

    LoggerService.lastError = undefined;

    let reference = "";

    try {
      reference = this.Log('error', message, stack);

      LoggerService.lastError = {
        message: message,
        stack: stack,
        date: new Date(),
        count: 0,
        reference: reference,
        timeout: undefined
      };
    }
    catch (e) {
      console.error(error);
      console.error(e);
    }

    logAnalyticsError(this.analytics, error, reference);

    return reference;
  }

  warn(message: string, stack: string): string {
    console.warn(message);

    return this.Log('warn', message, stack);
  }

  private Log(level: LogLevels, message: string, stack: string): string {
    const uid = crypto.randomUUID().replace(/-/g, "");
    const path = `logs/${uid}`;
    const docRef = doc(this.store, path);

    firstValueFrom(this.auth.profile).then(profile => {
      const log: Log = {
        level: level,
        date: serverTimestamp() as any, //HACK: se usa 'firebase/firestore' en vez de '@angular/fire/firestore' para evitar la deteccion de errores de angular
        version: environment.version,
        message: message,
        stack: stack,
        page: window.location.href,
        userId: profile?.user.uid ?? null,
        companyId: profile?.company.uid ?? null,
      }
      setDoc(docRef, log) //HACK: se usa 'firebase/firestore' en vez de '@angular/fire/firestore' para evitar la deteccion de errores de angular
        .catch(error => {
          console.error("Error al guardar el log:", error);
        });
    });

    return uid;
  }
}
