<ion-app>
  <ion-split-pane contentId="main" when="sm">
    <ion-menu contentId="main" [class.hiddeOut]="!showsListPlaceholder" [class.showIn]="showsListPlaceholder">
      <ion-content>
        <app-menu></app-menu>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main">
      <ion-chip color="danger" class="offline" [ngClass]="{'show': showsOffline, 'hide': hidesOffline}">
        <ion-icon name="warning"></ion-icon>
        <ion-label>
          Sin Conexión
        </ion-label>
      </ion-chip>
      <app-list-header [showsOptionsButton]="false" [showsSearchButton]="false" [showSearchBar]="false"
        [class.hiddeOut]="!showsListPlaceholder" [class.showIn]="showsListPlaceholder">
      </app-list-header>

      <app-list-body [showsCreateButton]="false" [isLoading]="false" [class.hiddeOut]="!showsListPlaceholder"
        [class.showIn]="showsListPlaceholder">
        <ion-list list>
        </ion-list>
      </app-list-body>

    </ion-router-outlet>
  </ion-split-pane>
</ion-app>