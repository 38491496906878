import { BarCodeTypes } from "./BarcodeTypes";
import { ListDocument, IdentifiedDocument, ImagedDocument, NamedDocument, UniqueDocument } from "./BaseDocument";
import { CashDeskRef } from "./CashDesk";
import { Currencies } from "./Currency";
import { Status as DigitalDocumentStatus } from "./DigitalDocument";
import { Status as MaintenanceStatus } from "./Maintenance";

export type Company = ListDocument & CompanyRef & {
  owner: string,
  configuration: Configuration;
}

export type Configuration = {
  general: {
    color: string;
    currency: string;
  },
  barcodeScanner: {
    codeTypes: BarCodeTypes[];
    accuracy: number;
  },
  purchase: {
    isEnable: boolean,
    sendNew: mailSupplierConfiguration,
    sendCancelled: mailSupplierConfiguration,
  }
  maintenance: {
    isEnable: boolean;
    sendNew: mailClientConfiguration,
    sendCancelled: mailClientConfiguration,
    sendCompleted: mailClientConfiguration,
    createInvoice:{
      isEnable: boolean,
      onStatus: MaintenanceStatus.Completed | MaintenanceStatus.Closed,
      withStatus: DigitalDocumentStatus.Draft | DigitalDocumentStatus.New,
      cashDesk: CashDeskRef,
      economicActivity: string,
    },
    labels: {
      singular: string,
      plural: string,
      identification: string,
      icon: string
    }
  },
  stock: {
    isEnable: boolean;
    allowNegative: boolean;
    multifieldTypes: string[];
  }
  digitalInvoice: {
    isEnable: boolean;
    economicActivities: string[];
    email: string;
    sendNew: mailClientConfiguration,
  },
  cashDeskBalance: {
    isEnable: boolean;
    initialCash: number;
    tolerance: number;
  }
}

export type mailConfiguration = {
  isEnable: boolean,
  sendToUser: boolean,
  emails: string
}

export type mailClientConfiguration = mailConfiguration & {
  sendToClient: boolean,
}

export type mailSupplierConfiguration = mailConfiguration & {
  sendToSupplier: boolean,
}

export type CompanyRef = UniqueDocument & ImagedDocument & NamedDocument & IdentifiedDocument & {
  tradeName: string | null;

}

export const getReference = (company: Company): CompanyRef => {
  return {
    uid: company.uid,
    image: company.image,
    identificationType: company.identificationType,
    identification: company.identification,
    name: company.name,
    tradeName: company.tradeName,
  }
}


//#region Configuration

export const defaultEmailClientConfiguration: mailClientConfiguration = {
  isEnable: false,
  sendToClient: false,
  sendToUser: false,
  emails: ""
}

export const defaultEmailSupplierConfiguration: mailSupplierConfiguration = {
  isEnable: false,
  sendToSupplier: false,
  sendToUser: false,
  emails: ""
}

export const defaultConfiguration: Configuration = {
  general: {
    color: '#5c57a6',
    currency: Currencies.CRC,
  },
  barcodeScanner: {
    codeTypes: [BarCodeTypes.EAN, BarCodeTypes.UPC],
    accuracy: 5,
  },
  purchase: {
    isEnable: false,
    sendNew: defaultEmailSupplierConfiguration,
    sendCancelled: defaultEmailSupplierConfiguration,
  },
  maintenance: {
    isEnable: false,
    sendNew: defaultEmailClientConfiguration,
    sendCancelled: defaultEmailClientConfiguration,
    sendCompleted: defaultEmailClientConfiguration,
    createInvoice: {
      isEnable: false,
      onStatus: MaintenanceStatus.Completed,
      withStatus: 'DF' as any, //HACK: por alguna razón no se puede usar DigitalDocumentStatus.Draft, ya que dice que esta undefined al cargar los modulos del webpack (posiblemenete una referencia ciclica)
      cashDesk: null as any, //Se asigna al crear la compañia con el Id de la caja creada por defecto
      economicActivity: null as any, //Se asigna al activar factura electrónica
    },
    labels: {
      singular: "Equipo",
      plural: "Equipos",
      identification: "Identificación",
      icon: "disc-outline",
    }
  },
  digitalInvoice: {
    isEnable: false,
    economicActivities: [],
    email: '',
    sendNew: defaultEmailClientConfiguration,
  },
  cashDeskBalance: {
    isEnable: false,
    initialCash: 0,
    tolerance: 0,
  },
  stock: {
    isEnable: false,
    allowNegative: false,
    multifieldTypes: ["product"]
  }
}

//#endregion
