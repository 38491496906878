import { Injectable } from '@angular/core';
import { Auth, User as UserIdentity, ParsedToken, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, reauthenticateWithCredential, EmailAuthProvider, updatePassword, sendPasswordResetEmail } from '@angular/fire/auth';
import { Firestore, Timestamp, collection, collectionData, doc, docData, documentId, query, serverTimestamp, setDoc, where } from '@angular/fire/firestore';
import { BehaviorSubject, Subscription, combineLatest, filter, first, firstValueFrom, from, map, of, switchMap } from 'rxjs';
import { ImageStorageService } from './image-storage.service';
import { Profile } from '../../../models/Profile';
import { Credentials } from '../../../models/Credentials';
import { User, UserTypes } from '../../../models/User';
import { Company } from '../../../models/Company';
import { isBase64Image } from '../utilities/utililties';
import { Warehouse } from 'models/Warehouse';
import { Currency } from 'models/Currency';
import { TipoIdentificacion } from 'models/facturaelectronica/TipoIdentificacion';
import { Session } from 'models/Session';
import { AlertController, NavController } from '@ionic/angular';
import { UAParser } from 'ua-parser-js';
import { removeEmpty } from 'shared/utilities';
import { Subscription as SubscriptionModel } from 'models/Subscription';
import { Account } from 'models/Account';
import { DocumentsBatch } from 'models/DocumentsBatch';
import { serverTime } from '../utilities/serverTime';
import { CompanyBranch } from 'models/CompanyBranch';
import { DefaultBranchId } from 'models/Branch';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private LastCompanyKey = "DICE__LAST__COMPANY__UUID";

  private _profile: BehaviorSubject<Profile | undefined> = new BehaviorSubject<Profile | undefined>(undefined);

  private currentClaims?: ParsedToken;
  private currentUser?: User & { branchIds: string[] };
  private currentCompany?: Company & { branch: CompanyBranch, currency: Currency, subcription: (SubscriptionModel & { documentsBatches: DocumentsBatch[] }) };
  private currentSessionToken?: string;

  private userSubscription?: Subscription;
  private companySubscription?: Subscription;
  private sesionSubscription?: Subscription;

  private sessionAlert: HTMLIonAlertElement | undefined;

  constructor(
    private auth: Auth,
    private store: Firestore,
    private imageStorage: ImageStorageService,
    private alertController: AlertController,
    private navCtrl: NavController
  ) {

    this.auth.onAuthStateChanged(userIdentity => {
      console.log('onAuthStateChanged');

      this.onAuthStateChanged(userIdentity);
    })
  }

  private async onAuthStateChanged(userIdentity: UserIdentity | null) {
    if (userIdentity) {
      //Inicia una nueva sessión.
      this.initSession(userIdentity.uid);

      this.currentClaims = (await userIdentity.getIdTokenResult()).claims;
      console.log(this.currentClaims);
      console.log('size Claims', `${JSON.stringify({ companies: this.currentClaims['companies'], sp: this.currentClaims['sp'] })}`.length);

      let companyId: string = "";

      if (this.currentClaims && this.currentClaims['companies']) {
        const companies = Object.keys(this.currentClaims['companies']).filter(company => (this.currentClaims!['companies'] as any)[company] !== false);
        const lastCompanyId = localStorage.getItem(`${this.LastCompanyKey}__${userIdentity.uid}`);

        if (lastCompanyId && companies.includes(lastCompanyId)) {
          companyId = lastCompanyId;
        }
        else if (companies.length > 0) {
          companyId = companies[0];
        }
      }

      if (companyId) {
        if (this.currentUser?.uid !== userIdentity.uid || this.currentCompany?.uid !== companyId) {
          console.log('User Changed');
          this.watchUser(userIdentity.uid, companyId)
        }

        if (this.currentCompany?.uid !== companyId) {
          console.log('Company Changed');
          this.watchCompany(companyId);
        }
      }
      else {
        console.log("No Company");

        this.currentUser = {
          uid: "SinCompañia",
          image: null,
          email: userIdentity.email ?? "[Sin Correo]",
          name: userIdentity.displayName ?? "[Sin nombre]",
          jobTitle: "",
          telephones: [],
          type: UserTypes.Normal,
          roles: {},
          permissions: {},
          warehousesIds: [],
          cashdesksIds: [],
          branchIds: [],
          lastClaimsUpdate: 'none',
          createdBy: "SinCompañia",
          createdDate: Timestamp.now(),
          modifiedBy: "SinCompañia",
          modifiedDate: Timestamp.now(),
          isActive: false
        }

        this.currentCompany = {
          uid: "SinCompañia",
          accountId: 'none',
          identificationType: TipoIdentificacion.CedulaFisica,
          identification: '',
          image: null,
          name: "SinCompañia",
          tradeName: "",
          createdBy: "SinCompañia",
          createdDate: Timestamp.now(),
          modifiedBy: "SinCompañia",
          modifiedDate: Timestamp.now(),
          isActive: false,
          branch: {} as any,
          currency: {} as any,
          subcription: {
            uid: 'none',
            name: 'none',
            order: 0,
            public: false,
            quote: false,
            digitalInvoice: false,
            cashDeskBalance: false,
            purchase: false,
            stock: false,
            maintenance: false,
            automaticPriceList: false,
            maxUsers: 0,
            maxCompanies: 0,
            freeAccountantSeats: 0,
            maxDigitalDocuments: 0,
            documentsBatches: [],
            isActive: true,
            createdBy: "SinCompañia",
            createdDate: Timestamp.now(),
            modifiedBy: "SinCompañia",
            modifiedDate: Timestamp.now(),
          },
          configuration: {
            maintenance: {
              isEnabled: false
            },
            stock: {
              isEnabled: false
            },
            quote:{
              isEnabled: false
            },
            digitalInvoice: {
              isEnabled: false
            },
            cashDeskBalance: {
              isEnabled: false,
            },
            purchase: {
              isEnabled: false
            }
          } as any
        }

        this.emit();
      }
    }
    else {
      console.log('Clear Auth');

      this.userSubscription?.unsubscribe();
      this.companySubscription?.unsubscribe();

      this.currentClaims = undefined;
      this.currentUser = undefined;
      this.currentCompany = undefined;

      this.navCtrl.navigateRoot("/login");
    }
  }

  public async refreshCurrentAuthState() {
    console.log("refreshCurrentAuthState");

    await this.onAuthStateChanged(this.auth.currentUser);
  }

  async initSession(uid: string | undefined = undefined) {
    if (!uid) {
      uid = this.currentUser?.uid;
    }

    if (!uid) {
      throw new Error('User not found');
    }

    const parser = new UAParser();
    const result = parser.getResult();

    removeEmpty(result);

    const lastToken = localStorage.getItem('MENTESIS_LAST_SESSION_TOKEN');

    const session = {
      date: serverTimestamp() as any,
      token: lastToken ?? crypto.randomUUID().replace(/-/g, ''),
      agent: result,
    };

    localStorage.setItem('MENTESIS_LAST_SESSION_TOKEN', session.token);

    if (this.sesionSubscription) {
      this.sesionSubscription.unsubscribe();
    }

    const documentRef = doc(this.store, `sessions/${uid}`);

    await setDoc(documentRef, session);

    this.currentSessionToken = session.token;

    this.watchSession(uid);
  }

  private watchSession(uid: string) {
    if (this.sesionSubscription) {
      this.sesionSubscription.unsubscribe();
    }

    const documentRef = doc(this.store, `sessions/${uid}`);

    this.sesionSubscription = docData(documentRef, { idField: 'uid' }).subscribe(async (data: any) => {
      const session = data as Session;

      if (session.token !== this.currentSessionToken) {
        if (this.sessionAlert) {
          return;
        }

        let deviceName: string | undefined;

        if (session.agent.device.vendor && session.agent.device.model) {
          if (session.agent.device.vendor === "Apple") {
            deviceName = ` (${session.agent.device.model})`;
          } else if (session.agent.device.type === "mobile") {
            deviceName = ` (Teléfono ${session.agent.device.vendor} ${session.agent.device.model})`;
          } else if (session.agent.device.type === "tablet") {
            deviceName = ` (Tablet ${session.agent.device.vendor} ${session.agent.device.model})`;
          } else {
            deviceName = ` (${session.agent.device.vendor} ${session.agent.device.model})`;
          }
        }
        else if (session.agent.os.name && session.agent.os.version) {
          deviceName = ` (${session.agent.os.name})`;
        }
        else {
          deviceName = undefined;
        }

        this.sessionAlert = await this.alertController.create({
          header: 'Sesión',
          message: `El usuario '${this.currentUser!.name}' ha iniciado sesión en otro dispositivo${deviceName}.\nPor integridad de datos y transacciones Mentesis no permite varias sesiones simultaneas. \n¿Donde desea continuar trabajando?`,
          backdropDismiss: false,
          buttons: [
            {
              text: (deviceName ?? 'El Otro Dispositivo').replace('(', '').replace(')', ''),
              handler: () => {
                this.navCtrl.navigateRoot('/standby');
              }
            },
            {
              text: 'Este Dispositivo',
              handler: () => {
                this.initSession();
              }
            }
          ]
        });

        this.sessionAlert.onDidDismiss().then(() => {
          this.sessionAlert = undefined;
        });

        this.sessionAlert.present();
      } else if (this.sessionAlert) {
        this.sessionAlert.dismiss();
        this.sessionAlert = undefined;
      }
    });
  }

  private watchUser(uid: string, companyId: string) {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    let userRef;
    const support: { c: string, o: string, w: boolean, r: boolean } | undefined = this.currentClaims?.['sp'] as any;

    if (support?.o) {
      //TODO: cambiar por el user service
      //Si es soporte el usuario se toma de la compañia original, no de la que se le da soporte
      userRef = doc(this.store, `companies/${support.o}/users/${uid}`);
    }
    else {
      //TODO: cambiar por el user service
      userRef = doc(this.store, `companies/${companyId}/users/${uid}`);
    }

    this.userSubscription = docData(userRef, { idField: 'uid' })
      .pipe(
        switchMap(data => {
          const user = data as User;

          if (!user.warehousesIds || user.warehousesIds.length == 0) {
            return of({ user, branchIds: [] })
          }

          const warehousesRef = collection(this.store, `companies/${companyId}/warehouses`);

          const filtered = query(warehousesRef, where(documentId(), "in", user.warehousesIds))

          return collectionData(filtered, { idField: 'uid' })
            .pipe(
              first(),
              map(data => {
                const warehouses = data as Warehouse[];

                const uniqueBranchIds = Array.from(new Set(warehouses.map(w => w.branchId)));

                return { user, branchIds: uniqueBranchIds }
              })
            )
        })
      )
      .subscribe(data => {
        this.currentUser = { ...data.user, branchIds: data.branchIds };

        console.log('User Updated', this.currentUser.name);

        this.emit();
      });
  }

  private async checkPermissionSync(): Promise<void> {
    console.log('checkPermissionSync');

    return new Promise((resolve, reject) => {

      if (this.currentUser && this.currentClaims && this.currentCompany) {
        if (!this.currentUser.isActive) {
          //Si el usuario esta inactivo el company debería borrarse de los claims
          if ((this.currentClaims['companies'] as any)[this.currentCompany.uid] !== false && this.currentCompany.uid !== "SinCompañia") {
            console.warn(`Permission sync issue User INACTIVE`,);

            setTimeout(() => {
              this.refreshClaims().then(() => {
                this.checkPermissionSync().then(resolve);
              })
            }, 1000);
          }

          resolve();
          return;
        }

        const support: { c: string, r: boolean, w: boolean } = this.currentClaims['sp'] as any;

        if (support) {
          //Si es soporte no valida el token
          resolve();
          return;
        }

        //LEGACY: una vez actulizados todos los permisos al formato comprimido, se debe eliminar la logica del Timestamp
        const lastUserClaimsUpdate: string | undefined = JSON.stringify(this.currentUser.lastClaimsUpdate);
        let lastTokenClaimsUpdate: string | undefined = JSON.stringify((this.currentClaims['companies'] as any)[this.currentCompany.uid]["at"]).replace("_seconds", "seconds").replace("_nanoseconds", "nanoseconds");

        // console.log(lastUserClaimsUpdate, lastTokenClaimsUpdate);

        if (!(lastUserClaimsUpdate === undefined && lastTokenClaimsUpdate === undefined)
          && (lastUserClaimsUpdate === undefined
            || lastTokenClaimsUpdate === undefined
            || lastUserClaimsUpdate !== lastTokenClaimsUpdate
          )) {

          console.warn(`Permission sync issue User: ${lastUserClaimsUpdate} Claims:${lastTokenClaimsUpdate}`);

          setTimeout(() => {
            this.refreshClaims().then(() => {
              this.checkPermissionSync().then(resolve);
            })
          }, 1000);
        }
        else {
          resolve();
        }
      }
      else {
        console.log('reject');
        reject("Incomplete data");
      }
    });
  }

  private watchCompany(uid: string) {
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }

    const companyRef = doc(this.store, `companies/${uid}`)

    this.companySubscription = combineLatest(
      [
        docData(companyRef, { idField: 'uid' }).pipe(map(data => data as Company)),
        from(serverTime())
      ])
      .pipe(
        switchMap(([company, serverTime]: [Company, Date]) => {


          return combineLatest([
            of(company as Company),
            docData(doc(this.store, `companies/${company.uid}/branches/${DefaultBranchId}`), { idField: 'uid' }).pipe(map(data => data as CompanyBranch)),
            docData(doc(this.store, `currencies/${company.configuration.general.currency}`), { idField: 'uid' }).pipe(map(data => data as Currency)),
            docData(doc(this.store, `accounts/${company.accountId}`), { idField: 'uid' }).pipe(map(data => data as Account)).pipe(
              switchMap(account => {

                return combineLatest([
                  of(account),
                  docData(doc(this.store, `subscriptions/${account.subscriptionId}`), { idField: 'uid' }).pipe(
                    map(data => data as SubscriptionModel),
                    switchMap(subscription => {
                      const validDocumentsBatches = query(
                        collection(this.store, `accounts/${company.accountId}/documents-batches`),
                        where('expirationDate', '>=', serverTime),
                        where('remainingDocuments', '>', 0)
                      );

                      return combineLatest([
                        of(subscription as SubscriptionModel),
                        subscription.maxDigitalDocuments !== null ? collectionData(validDocumentsBatches, { idField: 'uid' }).pipe(map(data => data as DocumentsBatch[])) : of([] as DocumentsBatch[])
                      ]);
                    }),
                  )
                ])
              }))
          ])
        }),
        map(([company, defaultBranch, currency, accountAndSubscription]) => {
          return [company, defaultBranch, currency, accountAndSubscription[0], accountAndSubscription[1][0], accountAndSubscription[1][1]] as [Company, CompanyBranch, Currency, Account, SubscriptionModel, DocumentsBatch[]];
        })
      )
      .subscribe(async ([data, defaultBranch, currency, account, subcription, documentBatches]) => {
        console.log('Company Updated', data, currency, documentBatches, account, subcription);

        this.currentCompany = {
          ...data,
          branch: defaultBranch,
          currency: currency,
          subcription: { ...subcription, documentsBatches: documentBatches },
        } as any

        if (!subcription.quote) {
          this.currentCompany!.configuration.quote.isEnable = false;
        }

        if (!subcription.digitalInvoice) {
          this.currentCompany!.configuration.digitalInvoice.isEnable = false;
        }

        if (!subcription.cashDeskBalance) {
          this.currentCompany!.configuration.cashDeskBalance.isEnable = false;
        }

        if (!subcription.purchase) {
          this.currentCompany!.configuration.purchase.isEnable = false;
        }

        if (!subcription.stock) {
          this.currentCompany!.configuration.stock.isEnable = false;
        }

        if (!subcription.maintenance) {
          this.currentCompany!.configuration.maintenance.isEnable = false;
        }

        this.emit();
      });
  }

  async refreshClaims() {
    console.log('refresh token');

    // await this.auth.currentUser?.getIdToken(true);

    const result = await this.auth.currentUser?.getIdTokenResult(true);

    console.log("Refreshed companies", JSON.stringify(result?.claims['companies']));
    console.log("Refreshed Support", JSON.stringify(result?.claims['sp']));

    const emit = JSON.stringify((this.currentClaims as any).companies) !== JSON.stringify(result?.claims['companies'])

    this.currentClaims = result?.claims;

    if (emit) {
      console.log("emit new Claims", this.currentClaims);
      await this.emit();
    }

    console.log('end refresh token');
  }

  //Revisa si el token tiene los permisos de la compañia recien creada
  //Si no refresca el token hasta que los tenga
  //los permisos se agregan mediante un trigger en Cloud Funtions
  public checkNewCompany(companyId: string) {
    const interval = 500; //500 ms
    const timeout = 5000; //5 segundos
    let count = 0;

    return new Promise<void>((resolve, reject) => {
      const timer = window.setInterval(() => {
        console.log("INTERVAL FIRED", Date.now());
        if (this.currentClaims && this.currentClaims['companies'] && Object.keys(this.currentClaims['companies']).includes(companyId)) {
          window.clearInterval(timer);
          console.log("New Company Found");
          return this.refreshClaims().then(() => { return resolve(); }); //Se refrescan las credenciales para asegurar que los permisos esten actualizados
        }
        else {
          if (count > (timeout / interval)) {
            window.clearInterval(timer);
            return reject("Check New Company TimeOut");
          }

          count++;
          this.refreshClaims();
        }
      }, interval);
    })
  }

  private async emit() {
    if (this.currentUser && this.currentCompany && this.currentClaims) {
      localStorage.setItem(`${this.LastCompanyKey}__${this.currentUser.uid}`, this.currentCompany.uid);

      await this.checkPermissionSync();

      const profile = {
        user: { ...this.currentUser },
        company: { ...this.currentCompany },
        claims: { ...this.currentClaims }
      }

      this._profile.next(profile);
    }
  }

  async updateImage(imageUrl: string) {
    //se usa el profile, pra asegurar que tanto usuaer como company tienen datos
    const profile = await this.profileOnce;

    //Si es base 64 significa que es nueva, las cargadas de la BD se almacenan en Firebase Store
    const base64 = isBase64Image(imageUrl);

    console.log(base64, imageUrl);

    if (!base64.isBase64) {
      throw new Error('Image Url must be innn base64 format');
    }
    const image = await this.imageStorage.save(`companies/${profile.company.uid}/users`, `${profile.user.uid}.${base64.format}`, base64.data);

    //TODO: cambiar por el user service
    const userRef = doc(this.store, `companies/${profile.company.uid}/users/${profile.user.uid}`);

    await setDoc(userRef, { image: image }, { merge: true });
  }

  async setCurrentCompany(companyId: string) {
    this.watchCompany(companyId);

    if (this.currentUser) {
      this.watchUser(this.currentUser.uid, companyId);
    }
  }

  async register({ email, password }: Credentials) {
    const user = await createUserWithEmailAndPassword(this.auth, email, password);

    return user;
  }

  async login({ email, password }: Credentials) {
    const user = await signInWithEmailAndPassword(this.auth, email, password);

    return user;
  }

  async updatePassword(password: string, newPassword: string) {
    let user = this.auth.currentUser;

    if (!user) {
      throw new Error("User is not loged");
    }

    if (!user.email) {
      throw new Error("User has not an email address");
    }

    let credential = EmailAuthProvider.credential(
      user.email,
      password
    );

    //Se dispara un error si no authentica correctamente
    await reauthenticateWithCredential(user!, credential);

    await updatePassword(user!, newPassword);
  }

  sendPasswordResetEmail(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  async logout() {
    signOut(this.auth);
  }

  get profileOnce(): Promise<Profile> {
    const promise = new Promise<Profile>(resolver => {
      this._profile.pipe(filter(x => x !== undefined), first()).subscribe(profile => {
        //Debido al filter nunca deberia ser undefined
        resolver(profile!);
      })
    })

    return promise;
  }

  get profile(): BehaviorSubject<Profile | undefined> {
    return this._profile;
  }

  async hasAccess(permissions: number[], isDynamic: boolean = false): Promise<boolean> {
    const profile = await this.profileOnce;

    if (!profile.user.isActive) {
      return false;
    }

    if (profile.claims?.sp?.c === profile.company.uid) {
      //Si es soporte
      return true;
    }

    if (profile && profile.company && profile.claims) {
      for (const permission of permissions) {
        //LEGACY: una vez actulizados todos los permisos al formato comprimido, se debe eliminar la logica del Timestamp
        if (typeof profile.claims.companies[profile.company.uid].at === 'string') {
          //Array de permisos Ej: [255, , 156]. cada permiso es un bit, por ejemplo el permiso 12 es el bit 12 o sea el 2^12 = 4096;
          let encodedList;

          if (isDynamic) {
            encodedList = profile.claims.companies[profile.company.uid].dymc || [];
          }
          else {
            encodedList = profile.claims.companies[profile.company.uid].list || [];
          }

          if (this.hasPermission(encodedList || [], permission)) {
            return true;
          }
        }
        else {
          //LEGACY
          if (profile.claims.companies[profile.company.uid].list.includes(permission)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  //#region Funciones Firestore Security Rules
  //son una copia de las funciones del Security Rules, con las mimas restricciones, para que el comportamiento sea el mismo

  private calculateBit(a: number, b: number, bitPosition: number) {
    // Extrae el bit en una posición dada usando potencias de 2
    return (Math.floor(a / Math.pow(2, bitPosition)) % 2) * (Math.floor(b / Math.pow(2, bitPosition)) % 2) * Math.pow(2, bitPosition);
  }

  private bitwiseAnd32(a: number, b: number) {
    return this.calculateBit(a, b, 0) + this.calculateBit(a, b, 1) + this.calculateBit(a, b, 2) + this.calculateBit(a, b, 3) + this.calculateBit(a, b, 4) + this.calculateBit(a, b, 5) + this.calculateBit(a, b, 6) + this.calculateBit(a, b, 7) +
      this.calculateBit(a, b, 8) + this.calculateBit(a, b, 9) + this.calculateBit(a, b, 10) + this.calculateBit(a, b, 11) + this.calculateBit(a, b, 12) + this.calculateBit(a, b, 13) + this.calculateBit(a, b, 14) + this.calculateBit(a, b, 15) +
      this.calculateBit(a, b, 16) + this.calculateBit(a, b, 17) + this.calculateBit(a, b, 18) + this.calculateBit(a, b, 19) + this.calculateBit(a, b, 20) + this.calculateBit(a, b, 21) + this.calculateBit(a, b, 22) + this.calculateBit(a, b, 23) +
      this.calculateBit(a, b, 24) + this.calculateBit(a, b, 25) + this.calculateBit(a, b, 26) + this.calculateBit(a, b, 27) + this.calculateBit(a, b, 28) + this.calculateBit(a, b, 29) + this.calculateBit(a, b, 30) + this.calculateBit(a, b, 31);
  }

  private hasPermission(encodedList: number[], permission: number) {
    //Como hay 32 bits por numero, se divide el permiso entre 32 para saber en que byte está
    let byteIndex = Math.floor((permission - 1) / 32);
    //Si hace así ya que las reglas dan error 500 al acceder el indice con la variable: encodedList[byteIndex]
    let encodedByte = byteIndex == 0 ? encodedList[0] : (byteIndex == 1 ? encodedList[1] : (byteIndex == 2 ? encodedList[2] : (byteIndex == 3 ? encodedList[3] : (byteIndex == 4 ? encodedList[4] : (byteIndex == 5 ? encodedList[5] : (byteIndex == 6 ? encodedList[6] : (byteIndex == 7 ? encodedList[7] : (byteIndex == 8 ? encodedList[8] : 0))))))));

    let bitIndex = (permission - 1) % 32;
    let bitMask = Math.pow(2, bitIndex);
    // console.log('encoded',  (encodedByte >>> 0).toString(2).padStart(32, '0'));
    // console.log('bitMask',  (bitMask >>> 0).toString(2).padStart(32, '0'));
    return this.bitwiseAnd32(encodedByte || 0, bitMask) != 0;
  }

  //#endregion
}