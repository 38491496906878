import { ListDocument, NamedDocument, UniqueDocument } from "./BaseDocument";

export type Warehouse = ListDocument & WarehouseRef & {
  branchId: string;
};

export type WarehouseRef = UniqueDocument & NamedDocument;

export const getReference = (warehouse: Warehouse) : WarehouseRef => {
  return {
    uid: warehouse.uid,
    name: warehouse.name
  }
}
export const DefaultWarehouseId = "--default--";
export const InTransitWarehouseId = "--in-transit--";