import { TransactionalDocument } from "./BaseDocument";
import { DigitalDocumentRef, DocumentType } from "./DigitalDocument";
import { WarehouseRef } from "./Warehouse";
import { BranchRef as BaseBranchRef } from "./Branch";
import { PurchaseReceptionRef, numberPrefix as purchaseReceptionPrefix } from "./PruchaseReception";
import { UserRef } from "./User";
import { StockTransferRef } from "./StockTransfer";
import { ListItem } from "./ListItem";
import { ProductRef } from "./Product";
import { StockTransferReceptionRef, numberPrefix as stockTransferReceptionPrefix } from "./StockTransferReception";

export type StockChange = TransactionalDocument & {
  type: ChangeTypes,
  detail: string,
  user: UserRef,

  from: BranchRef | null,
  to: BranchRef | null,

  lines: Line[],

  reference: DocumentReference,
  notes: string
}

export type BranchRef = BaseBranchRef & {
  warehouse: WarehouseRef
}

export type Line = {
  amount: number,
  product: ProductRef,
}

export enum ChangeTypes {
  OutGoing = "S",
  Incoming = "E",
  Transfer = "T"
}

export type DocumentReference = (
  ({ type: DocumentType } & DigitalDocumentRef) |
  ({ type: ReferenceTypes.PurchaseReception } & PurchaseReceptionRef) |
  ({ type: ReferenceTypes.StockTransfer } & StockTransferRef) |
  ({ type: ReferenceTypes.StockTransferReception } & StockTransferReceptionRef)
)

export type MergedReferenceTypes = DocumentType | ReferenceTypes;

export enum ReferenceTypes {
  PurchaseReception = "PR",
  StockTransfer = "ST",
  StockTransferReception = "STR"
}

export const changeDetails: ListItem[] = [
  {
    uid: ChangeTypes.Incoming, name: "Entrada", code: "EN"
  },
  {
    uid: ChangeTypes.OutGoing, name: "Salida", code: "SA"
  },
  {
    uid: ChangeTypes.Transfer, name: "Traslado", code: "TR"
  },
]

export const referenceNumber = (reference: DocumentReference) => {
  if (reference.type === ReferenceTypes.PurchaseReception) {
    return `${reference.purchase.number.toString().padStart(5, "0")}-${purchaseReceptionPrefix}${reference.number.toString().padStart(2, "0")}`
  }
  else if (reference.type === ReferenceTypes.StockTransferReception) {
    return `${reference.stockTransfer.number.toString().padStart(5, "0")}-${stockTransferReceptionPrefix}${reference.number.toString().padStart(2, "0")}`
  }
  else {
    return reference.number.toString().padStart(5, "0");
  }
}