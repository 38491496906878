import { CodeDescriptionItem } from "./CodeDescriptionItem";

export enum MedioPago {
  Efectivo = "01",
  Tarjeta = "02",
  Cheque = "03",
  TransferenciaDeposito = "04",
  RecaudadoTerceros = "05",
  Otros = "99"
}

export const MediosPago: CodeDescriptionItem[] = [
  { code: MedioPago.Efectivo, description: "Efectivo" },
  { code: MedioPago.Tarjeta, description: "Tarjeta" },
  { code: MedioPago.Cheque, description: "Cheque" },
  { code: MedioPago.TransferenciaDeposito, description: "Transferencia" },
  { code: MedioPago.RecaudadoTerceros, description: "Recaudado por terceros" },
  { code: MedioPago.Otros, description: "Crédito" }
];

export const medioPagoText = (method: MedioPago) => {
  return MediosPago.find(m => m.code === method)?.description ?? "Error"
}

export const medioPagoIcon = (method: MedioPago) => {
  switch (method) {
    case MedioPago.Efectivo:
      return "cash";
    case MedioPago.Tarjeta:
      return "card";
    case MedioPago.TransferenciaDeposito:
      return "shield-checkmark";
    case MedioPago.Otros:
      return "calendar";
    default:
      return "alert-circle";
  }
}