import { Pipe, PipeTransform } from '@angular/core';
import { DocumentReference, referenceNumber } from 'models/StockChange';

@Pipe({
  name: 'referenceNumber',
  standalone: true
})
export class ReferenceNumberPipe implements PipeTransform {

  transform(reference: DocumentReference | undefined | null): string {
    if (!reference){
      return ''
    }
    
    return referenceNumber(reference)
  }

}
