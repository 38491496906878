import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { Analytics, logEvent } from "@angular/fire/analytics";
import { LoggerService } from "../services/logger.service";
import { logAnalyticsError } from "./utililties";

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  analytics: Analytics | undefined;
  logger: LoggerService | undefined;

  constructor(
    private injector: Injector
  ) {
    super();
  }

  override handleError(error: any): void {
    if (!this.logger) {
      this.logger = this.injector.get(LoggerService)
    }

    //Si el logger da error por algun motivo, lo intenta diractamente en analitycs
    try{
      this.logger.error(error);
    }
    catch(e){
      if (!this.analytics) {
        this.analytics = this.injector.get(Analytics)
      }

      logAnalyticsError(this.analytics, error, "");
      logAnalyticsError(this.analytics, e, "")
    }

    super.handleError(error);
  }


}