const cacheToken = "cache_"

export const Cache = (expiration: number) => {
    return function (
        target: Object,
        propertyKey: string | symbol,
        descriptor: PropertyDescriptor
    ) {

        const originalMethod = descriptor.value;

        descriptor.value = async function (...args: any[]) {
            const cacheKey = `${cacheToken}${target.constructor.name}_${propertyKey.toString()}:${JSON.stringify(args)}`;

            try {
                const persistentCahe = sessionStorage.getItem(cacheKey);


                if (persistentCahe) {
                    const cache: Cache = JSON.parse(persistentCahe);

                    const now = new Date();

                    if (now.getTime() - cache.date < expiration) {
                        console.log(`FROM CACHE ${cacheKey}`);

                        // if (cache.isArray){
                        //     return Array(...cache.result);
                        // }

                        return cache.result;
                    }
                }
            }
            catch (ex) {
                console.warn("Cache Getting Failed", ex);
            }
            console.log(`NORMAL ${cacheKey}`);
            const result = await originalMethod.apply(this, args);

            try {
                const cache: Cache = {
                    date: (new Date()).getTime(),
                    // isArray: Array.isArray(result),
                    result
                };

                sessionStorage.setItem(cacheKey, JSON.stringify(cache))
            }
            catch (ex) {
                console.warn("Cache Setting Failed", ex);
            }

            return result;

        };
    };
}

export const  getAllCacheKeys = (): string[] => {
    const keys = [];
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (key && key.startsWith(cacheToken)) {
            keys.push(key);
        }
    }
    return keys;
}

export const clearCache = () => {
    const keys = getAllCacheKeys();
    keys.forEach(key => sessionStorage.removeItem(key));
}

type Cache = {
    date: number
    result: any,
}

export const expireOn1Second = 1000;
export const expireOn10Seconds = expireOn1Second * 10;
export const expireOn30Seconds = expireOn1Second * 30;

export const expireOn1Minute = expireOn1Second * 60;
export const expireOn10Minutes = expireOn1Minute * 10;
export const expireOn30Minutes = expireOn1Minute * 30;

export const expireOn1Hour = expireOn1Minute * 60;
export const expireOn4Hours = expireOn1Hour * 4;
export const expireOn8Hours = expireOn1Hour * 8;
export const expireOn12Hours = expireOn1Hour * 12;