import { Timestamp } from "@angular/fire/firestore";
import { ListDocument } from "./BaseDocument";
import { ListItem } from "./ListItem";

export type Notification = ListDocument & {
    userIds: string[];
    type: NotificationType;
    date: Timestamp;
    subject: string;
    message: string;
    status: NotificationStatus;
}

export enum NotificationType {
    Info = 'i',
    Warning = 'w',
    Error = 'e',
}

export enum NotificationStatus {
    New = 'n',
    Read = 'r',
}

export const notificationTypeDetails : ListItem[] = [
    { uid: NotificationType.Info, name: 'Info', icon: 'notifications', color: 'medium' },
    { uid: NotificationType.Warning, name: 'Warning', icon: 'warning', color: 'warning' },
    { uid: NotificationType.Error, name: 'Error', icon: 'alert-circle', color: 'danger' },
]