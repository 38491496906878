import { KeyValue } from "@angular/common";
import { TipoIdentificacion } from "./facturaelectronica/TipoIdentificacion";
import { DocumentType } from "./DigitalDocument";

export type Other = OtherText | OtherContentContainer  | OtherContentText;

export type OtherText = OtherBase & {
    type: OtherType.Text,
    label: string,
    value: string,
}

export type OtherContentContainer = OtherBase & {
    type: OtherType.ContentContainer,
    attributes: KeyValue<string, string>[],
    label: string | false,
    value:  (OtherContentContainer | OtherContentText)[],
    required: true,
    canEditOnDocument: false,
}

export type OtherContentText = OtherBase & {
    type: OtherType.ContentText,  
    label: string,  
    value: string,
}

type OtherBase = {
    type: OtherType,
    name: string,    
    attributes: KeyValue<string, string>[]
    required: boolean,
    canEditOnDocument: boolean,
    documentTypes: DocumentType[],
}

export enum OtherType {
    Text = 'text',
    ContentContainer = 'content-container',
    ContentText = 'content-text',
}

export const WaltmartTemplate : OtherText[] = [
    {
        type: OtherType.Text,
        label: 'Número de vendedor',
        name: 'WMNumeroVendedor',
        attributes: [],
        value: '',
        required: true,
        canEditOnDocument: false,
        documentTypes: [DocumentType.Invoice, DocumentType.CreditNote, DocumentType.CreditNoteVoid, DocumentType.CreditNoteRefound, DocumentType.CreditNoteDiscount],
    },
    {
        type: OtherType.Text,
        label: 'GLN Lugar de entrega',
        name: 'WMEnviarGLN',
        attributes: [],
        value: '',
        required: true,
        canEditOnDocument: true,
        documentTypes: [DocumentType.Invoice, DocumentType.CreditNote, DocumentType.CreditNoteVoid, DocumentType.CreditNoteRefound, DocumentType.CreditNoteDiscount],
    },
    {
        type: OtherType.Text,
        label: 'Número de orden',
        name: 'WMNumeroOrden',
        attributes: [],
        value: '',
        required: true,
        canEditOnDocument: true,
        documentTypes: [DocumentType.Invoice, DocumentType.CreditNote, DocumentType.CreditNoteVoid, DocumentType.CreditNoteRefound, DocumentType.CreditNoteDiscount],
    },
    {
        type: OtherType.Text,
        label: 'Fecha de reclamo',
        name: 'WMFechaReclamo',
        attributes: [],
        value: '',
        required: true,
        canEditOnDocument: true,
        documentTypes: [DocumentType.CreditNote, DocumentType.CreditNoteVoid, DocumentType.CreditNoteRefound, DocumentType.CreditNoteDiscount],
    },
];

export const PricesmartTemplate : OtherContentContainer[] = [
    {
        type: OtherType.ContentContainer,
        label: false,
        name: 'retail:Complemento',
        required: true,
        attributes:[
            { key: 'xmlns:xsi', value: 'http://www.w3.org/2001/XMLSchema-instance' },
            { key: 'xmlns:retail', value: 'https://invoicer.ekomercio.com/esquemas' },
            { key: 'xsi:schemaLocation', value: 'https://invoicer.ekomercio.com/esquemas https://invoicer.ekomercio.com/esquemas/ComplementoPricesmartCR_V1_0.xsd' },
        ],
        value: [
            {
                type: OtherType.ContentText,
                label: 'Número de vendedor',
                name: 'retail:NumeroVendedor',
                attributes:[],
                value: '',
                required: true,
                canEditOnDocument: false,
                documentTypes: [DocumentType.Invoice, DocumentType.CreditNote, DocumentType.CreditNoteVoid, DocumentType.CreditNoteRefound, DocumentType.CreditNoteDiscount],
            },
            {
                type: OtherType.ContentContainer,
                label: false,
                name: 'retail:LugarDeEntrega',
                attributes:[],
                required: true,
                value: [
                    {
                        type: OtherType.ContentText,
                        label: 'GLN Lugar de entrega',
                        name: 'retail:GLNLugarDeEntrega',
                        attributes:[],
                        value: '',
                        required: true,
                        canEditOnDocument: true,
                        documentTypes: [DocumentType.Invoice, DocumentType.CreditNote, DocumentType.CreditNoteVoid, DocumentType.CreditNoteRefound, DocumentType.CreditNoteDiscount]
                    },
                ],
                canEditOnDocument: false,
                documentTypes: [DocumentType.Invoice, DocumentType.CreditNote, DocumentType.CreditNoteVoid, DocumentType.CreditNoteRefound, DocumentType.CreditNoteDiscount]
            },
            {
                type: OtherType.ContentContainer,
                label: false,
                name: 'retail:OrdenDeCompra',
                attributes:[],
                required: true,
                value: [
                    {
                        type: OtherType.ContentText,
                        label: 'Número de orden',
                        name: 'retail:NumeroOrden',
                        attributes:[],
                        value: '',
                        required: true,
                        canEditOnDocument: true,
                        documentTypes: [DocumentType.CreditNote, DocumentType.CreditNoteVoid, DocumentType.CreditNoteRefound, DocumentType.CreditNoteDiscount]
                    },
                    {
                        type: OtherType.ContentText,
                        label: 'Fecha de orden',
                        name: 'retail:FechaOrden',
                        attributes:[],
                        value: '',
                        required: true,
                        canEditOnDocument: true,
                        documentTypes: [ DocumentType.CreditNote, DocumentType.CreditNoteVoid, DocumentType.CreditNoteRefound, DocumentType.CreditNoteDiscount]
                    },
                ],
                canEditOnDocument: false,
                documentTypes: [DocumentType.CreditNote, DocumentType.CreditNoteVoid, DocumentType.CreditNoteRefound, DocumentType.CreditNoteDiscount]
            },
        ],
        canEditOnDocument: false,
        documentTypes: [DocumentType.Invoice, DocumentType.CreditNote, DocumentType.CreditNoteVoid, DocumentType.CreditNoteRefound, DocumentType.CreditNoteDiscount]
    },
];

export const OthersTemplates : Template[] = [
    {
        identificationType: TipoIdentificacion.CedulaJuridica,
        identification: '3102007223',
        others: WaltmartTemplate,
    },
    {
        identificationType: TipoIdentificacion.CedulaJuridica,
        identification: '3101231707',
        others: PricesmartTemplate,
    },
]

export type Template = {
    identificationType: TipoIdentificacion,
    identification: string,
    others: Other[],
}