import { ListDocument, NamedDocument, UniqueDocument } from "./BaseDocument";

export type Currency = ListDocument & NamedDocument & {
  symbol: string,
};

export type CurrencyRef = UniqueDocument & {
  exchangeRate: number
};

export const getReference = (currency: Currency, exchangeRate: number): CurrencyRef => {
  return {
    uid: currency.uid,
    exchangeRate: exchangeRate,
  }
}

export enum Currencies {
  CRC = "CRC",
  USD = "USD",
  EUR = "EUR"
}