import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AlertController, IonicModule } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { Profile } from '../../../models/Profile';
import { Permission, PermissionsCodes } from '../../../models/Permission';
import { DynamicsService } from '../services/dynamics.service';
import { addIcons } from 'ionicons';
import { dynamicPermissionNumber } from '../utilities/utililties';
import { NotificationsService } from '../services/notifications.service';
import { menuPages, Page, Section } from '../../../models/Page';
import { normalizeText } from 'models/BaseDocument';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, RouterModule]
})
export class MenuPage implements OnInit {

  @ViewChild('notification', { static: true }) notification!: ElementRef;

  profile?: Profile;
  environmentName: string = "";
  version: string = "";
  cssVariables: string = "";

  lastNotificationDate: Date | undefined;
  private newNotificationSound: HTMLAudioElement;

  sections: Section[] = [];

  currentSection: string = "";

  constructor(
    public authService: AuthService,
    private alertController: AlertController,
    private router: Router,
    private dynamicsService: DynamicsService,
    private notifications: NotificationsService
  ) {
    this.version = environment.version;
    if (!environment.production) {
      this.environmentName = environment.name;
    }

    this.newNotificationSound = new Audio();
    this.newNotificationSound.src = 'assets/audio/notification.wav';
    this.newNotificationSound.load();
  }

  lastCompanyId: string | undefined = undefined;

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.expandAccordion(event.url);
      }
    });

    this.authService.profile.subscribe(profile => {
      this.profile = profile;

      if (profile && profile.company.uid !== this.lastCompanyId) {
        this.lastCompanyId = profile.company.uid;

        this.updateDynamicPages(profile);
      }

      if (profile?.user.isActive === false) {
        this.navigate("/home");
      }
    });

    this.notifications.lastNewNotification().then(result => {
      result.subscribe(notification => {
        console.log(notification);

        if (notification && notification.createdDate.toDate() > (this.lastNotificationDate ?? new Date(0))) {
          //Se pone undefined para que se dispare la animacion
          this.lastNotificationDate = undefined;
          this.playSound();
        }

        setTimeout(() => {
          this.lastNotificationDate = notification?.createdDate.toDate();
        }, 0);
      });
    });
  }

  private playSound() {
    if (!this.newNotificationSound.paused) {
      this.newNotificationSound.pause();
      this.newNotificationSound.currentTime = 0;
    }
    this.newNotificationSound.play();
  }

  updateMenu(profile: Profile, dynamicPages: Page[]) {
    const otherSection = {
      name: "Otros",
      icon: "apps-outline",
      pages: [...menuPages(profile), ...dynamicPages],
      hidded: false
    }

    this.sections = [
      otherSection
    ];

    if (profile.company.configuration.maintenance.isEnable) {
      //Actualiza el nombre de la pantalla de equipos
      const fixablesPage = otherSection.pages.find(p => p.url === '/fixables');

      if (fixablesPage) {
        fixablesPage.title = profile.company.configuration.maintenance.labels.plural;
        fixablesPage.icon = profile.company.configuration.maintenance.labels.icon;
      }
    }

    const sections = profile.company.configuration.menu.sections;

    for (let i = sections.length - 1; i >= 0; i--) {
      const group = sections[i];

      const iconName = `section-${normalizeText(group.uid)}`;

      const section = {
        name: group.uid,
        icon: group.icon ? iconName : "apps-outline",
        pages: [] as Page[],
        hidded: false
      }

      if (group.icon && group.uid !== otherSection.name) {
        addIcons({ [iconName]: group.icon });
      }

      for (const pageUrl of group.pagesUrls) {
        const pageIndex = otherSection.pages.findIndex(p => p.url === pageUrl);

        if (pageIndex !== -1) {
          section.pages.push(otherSection.pages.splice(pageIndex, 1)[0]);
        }
      }

      this.sections.unshift(section);
    }

    this.checkPermissions();
    this.expandAccordion(this.router.url)
  }

  updateDynamicPages(profile: Profile) {
    this.dynamicsService.byActiveOnce(true).then(dynamics => {
      const dynamicPages = [] as Page[];

      for (const dynamic of dynamics
        .filter(d => d.parent?.field === null || d.parent.showsOnPrimaryMenu)
        .sort((a, b) => b.position - a.position || b.createdDate.toMillis() - a.createdDate.toMillis())) {
        const pageSections = dynamic.uid.toLowerCase().split('_');
        const pageNumber = Number(pageSections[0]);
        const pageCode = pageSections[1];

        addIcons({ [pageCode]: dynamic.icon });

        dynamicPages.push(
          {
            title: dynamic.labels.plural,
            url: `/${dynamic.uid.toLowerCase()}`,
            icon: pageCode,
            permissions: [
              dynamicPermissionNumber(pageNumber, "View") * -1,
              dynamicPermissionNumber(pageNumber, "Edit") * -1,
              dynamicPermissionNumber(pageNumber, "Create") * -1,
              dynamicPermissionNumber(pageNumber, "Disable") * -1
            ],
            hidded: true,
            isDynamic: true
          }
        );
      }

      this.updateMenu(profile, dynamicPages);
    });
  }

  expandAccordion(pageUrl: string) {
    for (const section of this.sections) {
      for (const page of section.pages) {
        if (pageUrl.toLowerCase().startsWith(page.url.toLowerCase())) {
          setTimeout(() => {
            this.currentSection = section.name;
          }, 10);
        }
      }
    }
  }

  async checkPermissions() {
    for (const section of this.sections) {
      let hidded = 0;
      for (const page of section.pages) {
        let permissions: PermissionsCodes[] | boolean;

        if (typeof (page.permissions) == "function") {
          permissions = page.permissions();
        }
        else {
          permissions = page.permissions;
        }

        page.hidded = false;

        if (typeof (permissions) === "boolean") {
          if (!permissions) {
            page.hidded = true;
            hidded++;
          }

          continue;
        }

        if (page.permissions && !await this.authService.hasAccess(permissions, !!page.isDynamic)) {
          page.hidded = true;
          hidded++;
        }
      }

      if (hidded === section.pages.length) {
        section.hidded = true;
      }
      else {
        section.hidded = false;
      }
    }

    this.updateCssVariables();
  }

  @HostListener('window:resize')
  private updateCssVariables() {
    this.cssVariables =
      `--viewport-height:${window.innerHeight}px;
       --group-count:${this.sections.filter(s => !s.hidded).length + 1};`; //+1 porque Inicio siempre esta activo
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }

  async logout() {
    const alert = await this.alertController.create({
      header: "Cerrar Sesión",
      message: "¿Esta seguro que desea salir del sistema?",
      buttons: [{
        text: 'Cancelar',
        role: 'cancel'
      },
      {
        text: 'Salir',
        role: 'confirm',
        handler: data => {
          this.authService.logout().then(() => {
            this.router.navigateByUrl('/login', { replaceUrl: true })
          });
        },
      }]
    });

    alert.present();
  }

  trackBySection(index: number, section: Section): string {
    return section.name;
  }

  trackByPage(index: number, page: Page): string {
    return page.url;
  }
}