import { BankRef } from "./Bank"
import { CurrencyRef } from "./Currency"
import { MedioPago } from "./facturaelectronica/MedioPago"

export type Payment = {
    type: PaymentType,
    currency: CurrencyRef
    amount: number,
    bank?: BankRef,
    reference?: string
  }
  
export enum PaymentType{
    Cash = "CS",
    Card = "CR",
    Transfer = "TF",
    Credit = "CD",
    Check = "CH",
    ThirdParty = "TP",
    Others = "OT"
}

export const PaymentDetails = [
    {
        uid: PaymentType.Cash,
        label: "Efectivo",
        icon: "cash",
        medioPago: MedioPago.Efectivo
    },
    {
        uid: PaymentType.Card,
        label: "Tarjeta",
        icon: "card",
        medioPago: MedioPago.Tarjeta
    },
    {
        uid: PaymentType.Transfer,
        label: "Transferencia",
        icon: "shield-checkmark",
        medioPago: MedioPago.TransferenciaDeposito
    },
    {
        uid: PaymentType.Credit,
        label: "A Crédito",
        icon: "calendar",
        medioPago: MedioPago.Efectivo
    },
    {
        uid: PaymentType.Check,
        label: "Cheque",
        icon: "check-payment",
        medioPago: MedioPago.Cheque
    },
    {
        uid: PaymentType.ThirdParty,
        label: "Cobro a Terceros",
        icon: "people-circle",
        medioPago: MedioPago.RecaudadoTerceros
    },
    {
        uid: PaymentType.Others,
        label: "Otros",
        icon: "disc",
        medioPago: MedioPago.Otros
    }

]