// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'Desarrollo',
  version: require('../../package.json').version + '-dev',
  firebase: {
    apiKey: "AIzaSyAjXfJlLDCPAqibX5bXQAYS28wPh8E2vn0",
    authDomain: "mentesis-dev.firebaseapp.com",
    projectId: "mentesis-dev",
    storageBucket: "mentesis-dev.appspot.com",
    messagingSenderId: "378530056819",
    appId: "1:378530056819:web:0b49c362d26bd385923806",
    measurementId: "G-01TVWQVYD8"
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
