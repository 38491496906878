import { Injectable } from '@angular/core';
import { Log, LogLevels } from 'models/Log'
import { Firestore, doc, serverTimestamp, setDoc } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { firstValueFrom } from 'rxjs';
import { Analytics } from '@angular/fire/analytics';
import { logAnalyticsError } from '../utilities/utililties';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(
    public auth: AuthService,
    protected store: Firestore,
    protected analytics: Analytics
  ) {
  }

  async error(error: any): Promise<string> {
    console.error(error);

    let message = "";
    let stack = "";

    if (typeof (error) === 'string') {
      message = error;
    }

    if (typeof (error) === 'object') {
      if ('message' in error) {
        message = error.message;
      }
      else if ('toString' in error) {
        message = error.toString();

        if (message.startsWith("[object")) {
          message = JSON.stringify(error);
        }

        if (message === "{}") {
          message = error;
        }
      } else {
        message = error;
      }

      if ('stack' in error) {
        stack = error.stack;
      }
    }

    let reference = "";

    try{      
      reference = await this.Log('error', message, stack);
    }
    catch(e){
      console.error(error);
      console.error(e);
    }

    logAnalyticsError(this.analytics, error, reference);

    return reference;
  }

  async warn(message: string, stack: string): Promise<string> {
    console.warn(message);

    return this.Log('warn', message, stack);
  }

  private async Log(level: LogLevels, message: string, stack: string): Promise<string> {
    const profile = await firstValueFrom(this.auth.profile);

    const uid = crypto.randomUUID().replace(/-/g, "");    

    const log: Log = {
      level: level,
      date: serverTimestamp() as any,
      version: environment.version,
      message: message,
      stack: stack,
      page: window.location.href,
      userId: profile?.user.uid ?? null,
      companyId: profile?.company.uid ?? null,
    }

    const path = `logs/${uid}`;

    const docRef = doc(this.store, path);

    setDoc(docRef, log);

    return uid;
  }
}
