import { CodeDescriptionItem } from "./CodeDescriptionItem";

export enum CodigoImpuesto {
  IVA = "01",
  ImpuestoSelectivoConsumo = "02",
  ImpuestoUnicoCombustibles = "03",
  ImpuestoEspecificoBebidasAlcoholicas = "04",
  ImpuestoBebidasEnvasadasJabonesTocador = "05",
  ImpuestoProductosTabaco = "06",
  IVAEspecial = "07",
  IVARegimenBienesUsados = "08",
  ImpuestoEspecificoCemento = "12",
  Otros = "99"
}


export const CodigosImpuesto: CodeDescriptionItem[] = [
  { code: CodigoImpuesto.IVA, description: "Impuesto al Valor Agregado" },
  { code: CodigoImpuesto.ImpuestoSelectivoConsumo, description: "Impuesto Selectivo de Consumo" },
  { code: CodigoImpuesto.ImpuestoUnicoCombustibles, description: "Impuesto Único a los Combustibles" },
  { code: CodigoImpuesto.ImpuestoEspecificoBebidasAlcoholicas, description: "Impuesto específico de Bebidas Alcohólicas" },
  { code: CodigoImpuesto.ImpuestoBebidasEnvasadasJabonesTocador, description: "Impuesto Específico sobre las bebidas envasadas sin contenido alcohólico y jabones de tocador" },
  { code: CodigoImpuesto.ImpuestoProductosTabaco, description: "Impuesto a los Productos de Tabaco" },
  { code: CodigoImpuesto.IVAEspecial, description: "IVA (cálculo especial)" },
  { code: CodigoImpuesto.IVARegimenBienesUsados, description: "IVA Régimen de Bienes Usados (Factor)" },
  { code: CodigoImpuesto.ImpuestoEspecificoCemento, description: "Impuesto Específico al Cemento" },
  { code: CodigoImpuesto.Otros, description: "Otros" }
];