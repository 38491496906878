import { Pipe, PipeTransform } from '@angular/core';
import { multifieldDescription } from 'models/MultiField';

@Pipe({
  name: 'multifieldDescription',
  standalone: true
})
export class MultifieldDescriptionPipe implements PipeTransform {

  transform = multifieldDescription;
}