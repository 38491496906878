import { Timestamp } from "@angular/fire/firestore";
import { CodigoReferencia } from "./facturaelectronica/CodigoReferencia";
import { TipoDocumentoReferencia } from "./facturaelectronica/TipoDocumentoReferencia";
import { IdentifiedDocument, UniqueDocument, TransactionalDocument } from "./BaseDocument";
import { ClientRef as BaseClientRef, Client, getReference as getBaseClientReference } from "./Client";
import { CompanyRef as BaseCompanyRef, Company, getReference as getBaseCompanyReference } from "./Company";
import { FixedPrice, Product, ProductRef as BaseProductRef, getReference as getBaseProductReference } from "./Product";
import { CashDeskRef as BaseCashDeskRef, CashDesk, getReference as getBaseCashDeskReference } from "./CashDesk";
import { LogBase } from "./LogBase";
import { Stock } from "./Stock";
import { Warehouse, WarehouseRef, getReference as getWarehouseReference } from "./Warehouse";
import { CurrencyRef } from "./Currency";
import { Address } from "./Address";
import { Telephone } from "./Telephone";
import { CompanyBranch, CompanyBranchRef as BaseCompanyBranchRef, getReference as getBaseCompanyBranchReference } from "./CompanyBranch";
import { ClientBranch } from "./ClientBranch";
import { UnidadMedida } from "./facturaelectronica/UnidadMedida";
import { MultiField } from "./MultiField";
import { ListItem } from "./ListItem";
import { BranchRef, getReference as getBaseBranchReference } from "./Branch";
import { Payment } from "./Payment";
import { Summary as BaseSummary } from "./Summary";
import { EmailUsage } from "./Email";
import { SupplierRef as BaseSupplierRef, getReference as getBaseSupplierRef, Supplier } from "./Supplier";
import { SupplierBranch } from "./SupplierBranch";
import { PaymentTerm } from "./PaymentTerm";
import { Tax, TaxMode, TaxType } from "./Tax";
import { DigitalDocumentResponse } from "./DigitalDocumentResponse";
import { TipoOtrosCargos } from "./facturaelectronica/TipoOtrosCargos";
import { TipoIdentificacion } from "./facturaelectronica/TipoIdentificacion";
import { Discount, DiscountType } from "./Discount";
import { QuickClient } from "./QuickClient";
// import { round } from "../shared/utilities";

export type DigitalDocument = TransactionalDocument &
{
  isDraft: boolean;

  type: DocumentType,
  key: string;
  number: string;
  offlineNumber: string | null;

  company: CompanyRef;
  economicActivity: string;

  client: ClientRef | QuickClient | null | undefined,
  supplier: SupplierRef | null | undefined,
  currency: CurrencyRef & { exchangeRateCRC: number },
  paymentTerm: PaymentTerm,

  payments: Payment[];

  lines: Line[];
  charges: Charge[];
  roundDecimals: number;

  references: DocumentReference[]; //Referencias agregadas por el usuario y que se envian a Hacienda
  relatedDocuments: DigitalDocumentRef[]; //Documentos relacionados controlados internamente, deben estar las referencias tambien

  others: {
    name: string,
    value: string,
  }[];

  notes: string;

  statusLog: Log[];

  pdf: string | Blob;
  xls: string | Blob,
  xml: string | Blob | null;
  xmlResponse: string | Blob | null;

  response: DigitalDocumentResponse | null;
}

export type DigitalDocumentRef = UniqueDocument & {
  number: string;
  key: string;
  date: Timestamp;
}

export enum DocumentType {
  Ticket = "TE",
  Invoice = "FE",
  PurchaseInvoice = "FEC",
  PurchaseInvoiceVoid = "FEC-V",
  PurchaseInvoiceReplace = "FEC-R",
  CreditNote = "NC",
  CreditNoteVoid = "NC-V",
  CreditNoteDiscount = "NC-D",
  CreditNoteRefound = "NC-R",
  DebitNote = "ND",
  DebitNoteVoid = "ND-V",
}

export const documentTypeDetails: ListItem[] = [
  {
    uid: DocumentType.Ticket, name: "Tiquete Electrónico", code: "TE", color: "primary"
  },
  {
    uid: DocumentType.Invoice, name: "Factura Electrónica", code: "FE", color: "primary"
  },
  {
    uid: DocumentType.PurchaseInvoice, name: "Factura Electrónica de Compra", code: "FC", color: "primary"
  },
  {
    uid: DocumentType.PurchaseInvoiceVoid, name: "Factura Electrónica de Compra", code: "FC", color: "danger"
  },
  {
    uid: DocumentType.PurchaseInvoiceReplace, name: "Factura Electrónica de Compra", code: "FC", color: "primary"
  },
  {
    uid: DocumentType.CreditNote, name: "Nota de Crédito", code: "NC", color: "danger"
  },
  {
    uid: DocumentType.CreditNoteVoid, name: "Nota de Crédito", code: "NC", color: "danger"
  },
  {
    uid: DocumentType.CreditNoteDiscount, name: "Nota de Crédito", code: "NC", color: "danger"
  },
  {
    uid: DocumentType.CreditNoteRefound, name: "Nota de Crédito", code: "NC", color: "danger"
  },
  {
    uid: DocumentType.DebitNote, name: "Nota de Débito", code: "ND", color: "danger"
  },
  {
    uid: DocumentType.DebitNoteVoid, name: "Nota de Débito", code: "ND", color: "danger"
  },
]

export type Charge = {
  type: TipoOtrosCargos,
  identificationType: TipoIdentificacion | null, //Solo se usa para codigo 04 - Cobro de un tercero
  identification: string | null, //Solo se usa para codigo 04 - Cobro de un tercero
  name: string | null, //Solo se usa para codigo 04 - Cobro de un tercero
  description: string,
  percentage: number,
  amount: number
}

export type Line = {
  uid: string,
  amount: number,
  product: ProductRef,
  price: number,
  discount: Discount | null,
  notes: string | null,
  financialAdjustment: {
    type: AdjustType,
    percentage: number, //El precio del ajuste por el porcentaje debe dar el precio de la línea. UNa línea con ajuste no debería tener descuento.
    base: {
      price: number,
      discount: Discount | null,
    }
  } | null,
  modifiers: { //Solo se usa en las facturas electronicas con notas de credito o notas de credito anuladas
    refoundAmount: number | null, //Sumatoria de todos las devoluciones realizado a la linea
    priceAdjustAmount: number | null, //Sumatoria de todos los ajustes de precio aplicados a la linea
    additionalDiscountAmount: number | null, //Sumatoria de todos los descuentos adicionales aplicados a la linea
  } | null
}

export enum AdjustType {
  Price = 'p',
  Discount = 'd',
  Exoneration = 'e',
}

export type LineTotals = {
  unitDiscount: number,
  unitTax: number,
  unitTotal: number,
  lineSubTotal: number,
  lineDiscount: number,
  lineTax: number,
  lineTotal: number,
  modified: {
    unitDiscount: number,
    unitTax: number,
    unitTotal: number,
    lineSubTotal: number,
    lineDiscount: number,
    lineTax: number,
    lineTotal: number,
  } | null
}

export type DocumentReference = {
  number: string;
  date: Timestamp;
  type: TipoDocumentoReferencia;
  code: CodigoReferencia | null;
  reason: string;
}

export type ExchangeRate = {
  currency: CurrencyRef,
  rate: number
}

export type Log = LogBase & {
  status: Status;
}

export enum Status {
  Draft = "DF",
  New = "N",
  Sending = "SP",
  ReSending = "RS",
  Send = "S",
  AceptedByHacienda = "AH",
  RejectedByHacienda = "RH",
  AcceptedByClient = "AC",
  PartialAcceptedByClient = "PAC",
  RejectedByClient = "RC",
  AceptedByHaciendaResponse = "AHR",
  RejectedByHaciendaResponse = "RHR",
  Error = "E",
}

export const statusDetails: ListItem[] = [
  {
    uid: Status.Draft, name: "Borrador", color: "medium"
  },
  {
    uid: Status.New, name: "Nueva", color: "medium"
  },
  {
    uid: Status.Sending, name: "Enviando", color: "primary"
  },
  {
    uid: Status.ReSending, name: "Reenviando", color: "primary"
  },
  {
    uid: Status.Send, name: "Enviada", color: "success"
  },
  {
    uid: Status.AceptedByHacienda, name: "Aceptada por MH", color: "success"
  },
  {
    uid: Status.RejectedByHacienda, name: "Rechazada por MH", color: "danger"
  },
  {
    uid: Status.AcceptedByClient, name: "Aceptada", color: "success"
  },
  {
    uid: Status.PartialAcceptedByClient, name: "Aceptada Parcial", color: "warning"
  },
  {
    uid: Status.RejectedByClient, name: "Rechazada", color: "danger"
  },
  {
    uid: Status.AceptedByHaciendaResponse, name: "Respuesta Aceptada", color: "success"
  },
  {
    uid: Status.RejectedByHaciendaResponse, name: "Respuesta Rechazada", color: "danger"
  },
  {
    uid: Status.Error, name: "Error", color: "danger"
  },
]

export const statusPriorities = [
  {
    status: Status.New,
    overrides: [Status.Sending, Status.Send]
  },
  {
    status: Status.AceptedByHacienda,
    overrides: [Status.Sending, Status.Send]
  },
  {
    status: Status.RejectedByHacienda,
    overrides: [Status.Sending, Status.Send]
  },
  {
    status: Status.AcceptedByClient,
    overrides: [Status.Sending, Status.Send, Status.AceptedByHaciendaResponse]
  },
  {
    status: Status.PartialAcceptedByClient,
    overrides: [Status.Sending, Status.Send, Status.AceptedByHaciendaResponse]
  },
  {
    status: Status.RejectedByClient,
    overrides: [Status.Sending, Status.Send, Status.AceptedByHaciendaResponse]
  },
  {
    status: Status.RejectedByHaciendaResponse,
    overrides: [Status.Sending, Status.Send]
  },
]

export type Summary = BaseSummary & {
  modified: {
    subTotal: number,
    discount: number,
    tax: number,
    total: number,
  } | null
}

export const getReference = (document: DigitalDocument): DigitalDocumentRef => {
  return {
    uid: document.uid,
    number: document.number,
    key: document.key,
    date: document.date
  }
}

export const calculateLineTotals = (line: Line): LineTotals => {
  const totals: LineTotals = {
    unitDiscount: 0,
    unitTax: 0,
    unitTotal: 0,
    lineSubTotal: 0,
    lineDiscount: 0,
    lineTax: 0,
    lineTotal: 0,
    modified: null,
  };

  //No toma encuenta las notas de credito y debito
  totals.unitDiscount = line.discount ? (line.discount.type === DiscountType.Percentage ? line.price * (line.discount.percentage ?? 0) : line.discount.amount) : 0;
  totals.unitTax = line.product.taxes.reduce((acc, tax) => acc + (tax.mode === TaxMode.exclude ? 0 : (line.price - (tax.mode === TaxMode.ignoreDiscount ? 0 : totals.unitDiscount)) * (tax.percentage - (tax.exempt ? tax.exempt.percentage : 0))), 0);
  totals.unitTotal = line.price - totals.unitDiscount + totals.unitTax;

  totals.lineSubTotal = line.price * line.amount;
  totals.lineDiscount = totals.unitDiscount * line.amount;
  totals.lineTax = totals.unitTax * line.amount;
  totals.lineTotal = totals.unitTotal * line.amount;

  //SI toma en cuenta las notas de credito y debito
  //Si tiene algun modificador significa que se han aplicado notas de credito o debito
  if (line.modifiers) {
    totals.modified = {
      unitDiscount: 0,
      unitTax: 0,
      unitTotal: 0,
      lineSubTotal: 0,
      lineDiscount: 0,
      lineTax: 0,
      lineTotal: 0,
    };

    const adjustedAmount = (line.amount - (line.modifiers.refoundAmount ?? 0));
    const adjustedPrice = line.price - (line.modifiers.priceAdjustAmount ?? 0);

    totals.modified.unitDiscount = totals.unitDiscount + (line.modifiers.additionalDiscountAmount ?? 0);
    //Los descuento adicionales no afectan el impuesto
    totals.modified.unitTax = line.product.taxes.reduce((acc, tax) => acc + (tax.mode === TaxMode.exclude ? 0 : (adjustedPrice - (tax.mode === TaxMode.ignoreDiscount ? 0 : totals.modified!.unitDiscount) + (line.modifiers!.additionalDiscountAmount ?? 0)) * (tax.percentage - (tax.exempt ? tax.exempt.percentage : 0))), 0);
    totals.modified.unitTotal = adjustedPrice - totals.modified.unitDiscount + totals.modified.unitTax;

    totals.modified.lineSubTotal = adjustedPrice * adjustedAmount;
    totals.modified.lineDiscount = totals.modified.unitDiscount * adjustedAmount;
    totals.modified.lineTax = totals.modified.unitTax * adjustedAmount;
    totals.modified.lineTotal = totals.modified.unitTotal * adjustedAmount;
  }

  return totals;
}

export const calculateSummary = (lines: LineTotals[], charges: Charge[], roundDecimals: number = 2): Summary => {
  const summary: Summary = {
    subTotal: 0,
    discount: 0,
    tax: 0,
    chargesTotal: 0,
    total: 0,
    modified: null
  };

  const hasModifiers = lines.some(l => !!l.modified)

  if (hasModifiers) {
    summary.modified = {
      subTotal: 0,
      discount: 0,
      tax: 0,
      total: 0,
    }
  }

  for (const line of lines) {
    summary.subTotal += line.lineSubTotal;
    summary.discount += line.lineDiscount;
    summary.tax += line.lineTax;
    summary.total += line.lineTotal;

    if (hasModifiers) {
      summary.modified!.subTotal += line.modified?.lineSubTotal ?? line.lineSubTotal;
      summary.modified!.discount += line.modified?.lineDiscount ?? line.lineDiscount;
      summary.modified!.tax += line.modified?.lineTax ?? line.lineTax;
      summary.modified!.total += line.modified?.lineTotal ?? line.lineTotal;
    }
  }

  summary.chargesTotal = (charges ?? []).reduce((acc, charge) => acc + charge.amount, 0);
  summary.total += summary.chargesTotal;

  summary.subTotal = round(summary.subTotal, roundDecimals);
  summary.discount = round(summary.discount, roundDecimals);
  summary.tax = round(summary.tax, roundDecimals);
  summary.chargesTotal = round(summary.chargesTotal, roundDecimals);
  summary.total = round(summary.total, roundDecimals);

  return summary;
}

//Claves de los documentos electronicos
export const getDocumentKey = async (date: Date, documentNumber: string, company: BaseCompanyRef, crypto: Crypto, isOffline : boolean = false) => {
  const countryCode = '506';
  const day = date.getDate().toString().padStart(2, '0');
  //date.getMonth() devuelve del 0 al 11
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear().toString().slice(2);
  const identifiaction = company.identification.toString().padStart(12, '0');
  const type = isOffline ? "2" : "1" //1. Situación Normal, 2. Contingencia, 3. Sin Internet. //Sin Internet se mandan como contingencia ya que el consecutivo esta en el Servidor y no se puede generar el número consecutivo

  const baseKey = `${countryCode}${day}${month}${year}${identifiaction}${documentNumber}${type}`

  const code = await securityCode(baseKey, crypto);

  return `${baseKey}${code}`
}

const securityCode = async (input: string, crypto: Crypto): Promise<string> => {
  const buffer = new TextEncoder().encode(input);
  const hashArray = await crypto.subtle.digest('SHA-256', buffer);
  const hashHex = Array.prototype.map.call(new Uint8Array(hashArray), x => ('00' + x.toString(10)).slice(-2)).join('');
  return hashHex.substring(0, 8); // Tomar los primeros 8 caracteres del hash hexadecimal
}

//#endregion

//#region Company References

export type CompanyRef = BaseCompanyRef & {
  branch: CompanyBranchRef;

  configuration: {
    digitalInvoice: {
      isEnable: boolean;
      email: string;
    },
    stock: {
      isEnable: boolean;
      allowNegative: boolean;
    }
  }
}

export type CompanyBranchRef = BaseCompanyBranchRef & {
  warehouse: WarehouseRef | null;
  cashDesk: CashDeskRef;

  address: Address;
  telephones: Telephone[];
}

export type CashDeskRef = BaseCashDeskRef & {
  balanceId: string | null;
}

export const getCompanyReference = (company: Company, branch: CompanyBranch, warehouse: Warehouse | null, cashDesk: CashDesk, balanceId: string | null): CompanyRef => {
  return {
    ...getBaseCompanyReference(company),
    branch: getCompanyBranchReference(branch, warehouse, cashDesk, balanceId),
    configuration: {
      digitalInvoice: {
        isEnable: company.configuration.digitalInvoice.isEnable,
        email: company.configuration.digitalInvoice.email,
      },
      stock: {
        isEnable: company.configuration.stock.isEnable,
        allowNegative: company.configuration.stock.allowNegative,
      },
    }
  }
}

export const getCompanyBranchReference = (branch: CompanyBranch, warehouse: Warehouse | null, cashDesk: CashDesk, balanceId: string | null): CompanyBranchRef => {
  return {
    ...getBaseCompanyBranchReference(branch),
    warehouse: warehouse ? getWarehouseReference(warehouse) : null,
    cashDesk: getCashDeskReference(cashDesk, balanceId),

    address: branch.address,
    telephones: branch.telephones,
  }
}

export const getCashDeskReference = (cashDesk: CashDesk, balanceId: string | null): CashDeskRef => {
  return {
    ...getBaseCashDeskReference(cashDesk),
    balanceId: balanceId,
  }

}

//#endregion

//#region Client References

export type ClientRef = BaseClientRef & IdentifiedDocument & {
  branch: ClientBranchRef

  others: { name: string, value: string }[]
  manualEmails: string[];
  tradeName: string | null;
  priceListId: string | null;
}

export type ClientBranchRef = BranchRef & {
  others: { name: string, value: string }[]
  emails: string[],
  address: Address | null;
  telephones: Telephone[];
}

export const getClientReference = (client: Client): ClientRef => {
  return {
    ...getBaseClientReference(client),
    others: client.others,
    identificationType: client.identificationType,
    identification: client.identification,
    priceListId: client.priceListId,
    branch: null as any,
    manualEmails: [],
  }
}

export const getClientBranchReference = (branch: ClientBranch): ClientBranchRef => {
  return {
    ...getBaseBranchReference(branch),
    others: branch.others,
    emails: branch.emails.filter(e => e.usages.includes(EmailUsage.SendDigitalDocuments)).map(e => e.address),
    address: branch.address,
    telephones: branch.telephones,
  }
}

//#endregion

//#region Product References

export type ProductRef = BaseProductRef & {
  code: string;
  cost: number;
  maxDiscount: number;
  allowResellBelowCost: boolean;
  allowFullDiscount: boolean;
  taxes: Tax[],
  fixedPrices: FixedPrice[];
  unit: UnidadMedida;
  cabysCode: string;
  stock: Stock,
}

export const getProductReference = (product: Product, fields: MultiField[]): ProductRef => {
  return {
    ...getBaseProductReference(product, fields),
    code: product.code!,
    cost: product.cost,
    maxDiscount: product.maxDiscount,
    allowResellBelowCost: product.allowResellBelowCost,
    allowFullDiscount: product.allowFullDiscount,
    taxes: product.taxPercentage !== null ? [{ type: TaxType.IVA, mode: TaxMode.normal, percentage: product.taxPercentage, exempt: null }] : [],
    fixedPrices: product.fixedPrices,
    unit: product.unit,
    cabysCode: product.cabysCode!,
    stock: product.stock,
  }
}

//#endregion

//#region Supplier References

export type SupplierRef = BaseSupplierRef & IdentifiedDocument & {
  manualEmails: string[],
  branch: SupplierBranchRef
}

export type SupplierBranchRef = BranchRef & {
  emails: string[],
  address: Address | null;
  telephones: Telephone[];
}

export const getSupplierReference = (supplier: Supplier): SupplierRef => {
  return {
    ...getBaseSupplierRef(supplier),
    identificationType: supplier.identificationType,
    identification: supplier.identification,
    branch: null as any,
    manualEmails: []
  }
}

export const getSupplierBranchReference = (branch: SupplierBranch): SupplierBranchRef => {
  return {
    ...getBaseBranchReference(branch),
    emails: branch.emails.filter(e => e.usages.includes(EmailUsage.SendDigitalDocuments)).map(e => e.address),
    address: branch.address,
    telephones: branch.telephones,
  }
}

//#endregion

//Se copia desde shared/Utilities, ya que sino genera una referencia ciclica (utilities -> Other -> DigitalDocument -> utilities)
const round = (value: number, decimals: number): number => {
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
}