import { Branch, BranchRef, getReference as getBranchReference } from "./Branch";

export type CompanyBranch = Branch & {
    number: number
}

export type CompanyBranchRef = BranchRef & {
    number: number;
}

export const getReference = (companyBranch: CompanyBranch): CompanyBranchRef => {
    return {
        ...getBranchReference(companyBranch),
        number: companyBranch.number
    }
}