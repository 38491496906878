import { ListItem } from "./ListItem";

export type Email = {
    address: string;
    usages: EmailUsage[];
};

export enum EmailUsage {
    SendPurchases = 'P',
    SendMaintenances = 'M',
    SendDigitalDocuments = 'D',
    SendQuotes = 'Q',
}

export const EmailUsageDetails: ListItem[] = [
    {
        uid: EmailUsage.SendPurchases,
        icon: 'file-tray-full',
        name: 'Enviar Ordenes de Compra'
    },
    {
        uid: EmailUsage.SendMaintenances,
        icon: 'construct',
        name: 'Enviar Ordenes de Mantenimientos'
    },
    {
        uid: EmailUsage.SendDigitalDocuments,
        icon: 'receipt',
        name: 'Enviar Documentos Electrónicos'
    },
    {
        uid: EmailUsage.SendQuotes,
        icon: 'clipboard',
        name: 'Enviar Cotizaciones'
    },
]