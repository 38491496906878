import { Injectable } from '@angular/core';
import { DocumentPermissions, RepositoryService, SourceTypes } from './repository.service';
import { AuthService } from './auth.service';
import { Firestore, QueryConstraint, Timestamp, Transaction, doc, documentId, endBefore, limit, orderBy, serverTimestamp, startAfter, updateDoc, where } from '@angular/fire/firestore';
import { ListDocument, removePreposition, triGram } from '../../../models/BaseDocument';
import { Observable, combineLatest, firstValueFrom, map, of } from 'rxjs';
import { splitArray } from '../utilities/utililties';
import { PermissionsCodes } from 'models/Permission';

@Injectable({
  providedIn: 'root'
})
export abstract class ListRepositoryService<T extends ListDocument> extends RepositoryService<T> {
  constructor(
    auth: AuthService,
    store: Firestore,
    protected collections: string[],
    override permissions: DocumentPermissions & {  disable?: PermissionsCodes[] | boolean } = {},
  ) {
    super(auth, store, permissions);
  }

  canDisable(...parentsIds: string[]): Promise<boolean> {
    return this.checkPermission(this.permissions.disable)
  }

  protected override async paths(): Promise<string[]> {
    const profile = await this.auth.profileOnce;

    if (typeof (this.collections) === "string") {
      return [`companies/${profile.company.uid}/${this.collections}`];
    }

    return this.collections.map((collection, index) => index === 0 ? `companies/${profile.company.uid}/${collection}` : collection);
  }

  protected async triGram(document: T, ...parentsIds: string[]): Promise<any | undefined> {
    return undefined;
  }

  protected override async completeAdditionalData(document: T, ...parentsIds: string[]): Promise<{ uid: string; data: any; }> {
    //Agrega los trigram de busqueda, si no esta implementado devuelve undefined
    const triGram = await this.triGram(document, ...parentsIds);

    if (triGram) {
      document.searchText = { ...triGram }
    }
    else {
      document.searchText = undefined;
    }

    return super.completeAdditionalData(document, ...parentsIds);
  }

  override async create(document: T, transaction?: Transaction | undefined, ...parentsIds: string[]): Promise<T> {
    document.isActive = true;

    return super.create(document, transaction, ...parentsIds);
  }

  async byActive(isActive: boolean | undefined = undefined, take: number | undefined = undefined, lastTimeMark: Timestamp | undefined = undefined, source: SourceTypes = SourceTypes.Any, ...parentsIds: string[]): Promise<Observable<T[]>> {
    const queries: QueryConstraint[] = [];

    //TODO: si se usa lastTimeMark y isActive, da error por falta de indice
    if (this.order) {
      queries.push(orderBy(this.order.property, this.order.direction));
    }

    if (take) {
      queries.push(limit(take));
    }

    if (lastTimeMark) {
      queries.push(startAfter(lastTimeMark))
    }

    if (isActive !== undefined) {
      queries.push(where("isActive", "==", isActive));
    }

    return this.byFilter(queries, source, ...parentsIds);
  }

  async byActiveOnce(isActive: boolean | undefined = undefined, take: number | undefined = undefined, lastTimeMark: Timestamp | undefined = undefined, ...parentsIds: string[]): Promise<T[]> {
    return firstValueFrom(await this.byActive(isActive, take, lastTimeMark, SourceTypes.Server, ...parentsIds), { defaultValue: [] as T[] }) as Promise<T[]>;
  }

  async byActiveExcept(exceptId: string, isActive: boolean | undefined = undefined, source: SourceTypes = SourceTypes.Any, ...parentsIds: string[]): Promise<Observable<T[]>> {
    //TODO: revisar alguna forma de poder pasar la marca de tiempo y el Take, como el .all()

    const queries: QueryConstraint[] = [where(documentId(), "!=", exceptId)];

    //TODO: si se usa lastTimeMark y isActive, da error por falta de indice
    // if (this.order) {
    //   queries.push(orderBy(this.order.property, this.order.direction));
    // }

    if (isActive !== undefined) {
      queries.push(where("isActive", "==", isActive));
    }

    return this.byFilter(queries, source, ...parentsIds);
  }

  async byActiveExceptOnce(exceptId: string, isActive: boolean | undefined = undefined, ...parentsIds: string[]): Promise<T[]> {
    return firstValueFrom(await this.byActiveExcept(exceptId, isActive, SourceTypes.Server, ...parentsIds), { defaultValue: [] as T[] }) as Promise<T[]>;
  }

  async byActiveIdList(uidList: string[], isActive: boolean | undefined = undefined, source: SourceTypes = SourceTypes.Any, ...parentsIds: string[]): Promise<Observable<T[]>> {
    //La clausula 'in' en firestore tiene un limite de 30 items
    const batches = splitArray(uidList, isActive !== undefined ? 29 : 30);

    const promises: Promise<Observable<T[]>>[] = [];

    for (const batch of batches) {
      const queries: QueryConstraint[] = [];

      if (isActive !== undefined) {
        queries.push(where("isActive", "==", isActive));
      }

      if (batch.length === 0) {
        promises.push(Promise.resolve(of([] as T[])));
      } else {
        queries.push(where(documentId(), "in", batch));

        promises.push(this.byFilter(queries, source, ...parentsIds));
      }
    }

    const observables = await Promise.all(promises);

    return combineLatest(observables).pipe(map(values => (([] as T[]).concat(...values))));
  }

  async byActiveIdListOnce(uidList: string[], isActive: boolean | undefined = undefined, ...parentsIds: string[]): Promise<T[]> {
    return firstValueFrom(await this.byActiveIdList(uidList, isActive, SourceTypes.Server, ...parentsIds), { defaultValue: [] as T[] }) as Promise<T[]>;
  }

  async byText(filter: string | undefined = undefined, isActive: boolean | undefined = undefined, take: number | undefined = undefined, source: SourceTypes = SourceTypes.Any, ...parentsIds: string[]): Promise<Observable<T[]>> {
    const queries: QueryConstraint[] = [];

    //TODO: si se usa lastTimeMark y isActive, da error por falta de indice  
    //No se puede agregar el order ya que pide un indice por el trigram (imposible de saber)   
    // if (this.order) {
    //   queries.push(orderBy(this.order.property, this.order.direction));
    // }

    if (take) {
      queries.push(limit(take));
    }

    if (isActive !== undefined) {
      queries.push(where("isActive", "==", isActive));
    }

    if (filter) {
      const terms = removePreposition(filter.split(" "));

      for (const term of terms) {
        Object.keys(await triGram(term)).forEach((term: any) => {
          queries.push(where(`searchText.${term}`, '==', true));
        });
      }
    }

    return this.byFilter(queries, source, ...parentsIds);
  }

  async byTextOnce(filter: string | undefined = undefined, isActive: boolean | undefined = undefined, take: number | undefined = undefined, ...parentsIds: string[]): Promise<T[]> {
    return firstValueFrom(await this.byText(filter, isActive, take, SourceTypes.Server, ...parentsIds), { defaultValue: [] as T[] }) as Promise<T[]>;
  }

  async activate(uid: string, ...parentsIds: string[]) {
    return this.changeIsActive(uid, true, ...parentsIds);
  }

  async deactivate(uid: string, ...parentsIds: string[]) {
    return this.changeIsActive(uid, false, ...parentsIds);
  }

  private async changeIsActive(uid: string, isActive: boolean, ...parentsIds: string[]) {
    const profile = await this.auth.profileOnce;

    const path = `${await this.generatePath(...parentsIds)}/${uid}`;

    const docRef = doc(this.store, path);

    console.log(`${isActive ? 'Activating' : 'Deactivating'} ${path}`);

    //Se hace así porque no afecta el TriGram
    await updateDoc(docRef, {
      isActive: isActive,
      modifiedBy: profile.user.uid,
      modifiedDate: serverTimestamp()
    });

    return;
  }
}
