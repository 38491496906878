import { AdjustmentType, PriceList, ProfitType } from "../models/PriceList";
import { ExternalCashDeskId } from "../models/CashDesk";
import { CompanyRef as BaseCompanyRef } from "../models/Company";
import { Currencies } from "../models/Currency";
import { calculateLineTotals, calculateSummary, CompanyRef, DigitalDocument, DocumentType, Line, SupplierRef } from "../models/DigitalDocument";
import { CondicionVenta } from "../models/facturaelectronica/CondicionVenta";
import { Documento } from "../models/facturaelectronica/Documento";
import { CodigoMensajeHacienda } from "../models/facturaelectronica/Enums";
import { MensajeHacienda } from "../models/facturaelectronica/MensajeHacienda";
import { TipoIdentificacion } from "../models/facturaelectronica/TipoIdentificacion";
import { Payment, PaymentDetails } from "../models/Payment";
import { PaymentTermDetails } from "../models/PaymentTerm";
import { TaxDetails, TaxMode } from "../models/Tax";
import { Territory, getReference as getTerritoryReference } from "../models/Territory";
import { UserRef } from "../models/User";
import { ElementCompact, xml2js } from "xml-js";
import { Other, OtherContentContainer, OtherContentText, OtherType } from "../models/Others";
import { PDFDocumentLoadingTask, } from "pdfjs-dist";
import { DocumentInitParameters, TypedArray } from "pdfjs-dist/types/src/display/api";
import { AmountDiscount, DiscountType } from "../models/Discount";
import { CostaRicanAddress } from "../models/Address";
import { ToWords } from "to-words";

export const isDarkColor = (color: string): boolean => {
    // Elimina el "#" si está presente en la cadena del color hexadecimal
    const hexColor = color.replace("#", "");

    // Convierte el color hexadecimal a componentes RGB
    var r = parseInt(hexColor.substring(0, 2), 16) / 255;
    var g = parseInt(hexColor.substring(2, 4), 16) / 255;
    var b = parseInt(hexColor.substring(4, 6), 16) / 255;

    // Calcula la luminancia (Y) utilizando la fórmula
    var luminance = 0.299 * r + 0.587 * g + 0.114 * b;

    return luminance <= 0.5;
}

export const removeEmpty = (obj: any): any => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value === undefined) {
                delete obj[key];
            } else if (typeof value === 'object') {
                removeEmpty(value);
            }
        }
    }
}

export const numberFormat = new Intl.NumberFormat('en-US',
    {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

export type Result = {
    isValid: boolean;
    details: string;
    file?: File | Buffer;
    document?: DigitalDocument;
    message?: MensajeHacienda;
    pdfKey?: string;
};

export interface ITerritoryService {
    byIdOnce(uid: string, transaction?: undefined, ...parentsIds: string[]): Promise<Territory | undefined>;
}

export interface IDateService {
    fromDate(date: Date): any;
}
export interface IUIDService {
    newUID(): string;
}

export interface ILogger {
    info: (message: string) => void
    warn: (message: string) => void;
    error: (...args: any[]) => void;
}

export const processXML = async (xmlContent: string, file: File, company: BaseCompanyRef | undefined, user: UserRef, provinceService: ITerritoryService, cantonService: ITerritoryService, districtService: ITerritoryService, neighborhoodService: ITerritoryService, dateService: IDateService, uidService: IUIDService, verifySignature: ((xml: string, retyr: boolean) => Promise<boolean>), crypto: Crypto, logger: ILogger): Promise<Result> => {
    //HACK: no se verifica la firma de momento ya que en producción varios XML validos traen firmas invalida,
    //Al parecer por problemas de encoding, o de manupulacion de otros sistemas del layout del xml, 
    //pero no afectan los datos de la factura como tal.

    // const isSignatureValid = await verifySignature(xmlContent, true);

    // if (!isSignatureValid) {
    //     return { isValid: false, details: 'La firma del documento no es válida.', file: file };
    // }

    const options = {
        compact: true,
        trim: true,
        ignoreDeclaration: true,
        ignoreAttributes: true,
        textFn: (value: string, parentElement: any) => {
            try {
                const pOpKeys = Object.keys(parentElement._parent);
                const keyNo = pOpKeys.length;
                const keyName = pOpKeys[keyNo - 1];
                const arrOfKey = parentElement._parent[keyName];
                const arrOfKeyLen = arrOfKey.length;

                if (arrOfKeyLen > 0) {
                    const arr = arrOfKey;
                    const arrIndex = arrOfKey.length - 1;
                    arr[arrIndex] = value;
                } else {
                    if (['clave', 'numeroconsecutivo', 'condicionventa', 'codigo', 'tipo', 'tipodoc', 'tipodocumento', 'tipoidentificacionemisor', 'tipoidentificacionreceptor', 'numero', 'numerocedulaemisor', 'numerocedulareceptor', 'numeroidentidadtercero', 'mediopago', 'provincia', 'canton', 'distrito', 'barrio'].includes(keyName.toLowerCase())) {
                        parentElement._parent[keyName] = value;
                    } else if (['fechaemision'].includes(keyName.toLowerCase())) {
                        parentElement._parent[keyName] = new Date(value);
                    } else {
                        parentElement._parent[keyName] = nativeType(value);
                    }
                }
            } catch (e) {
                logger.error(e);
            }
        },
    };

    let xmlObject = xml2js(xmlContent, options) as ElementCompact;

    convertEmptyObjectsToNull(xmlObject);

    let digitalDocument: DigitalDocument | undefined;
    let message: MensajeHacienda | undefined;

    if (xmlObject['FacturaElectronica']) {
        digitalDocument = await convertDocumentoDeHaciendaToDigitalDocument(xmlObject['FacturaElectronica'], DocumentType.Invoice, user, provinceService, cantonService, districtService, neighborhoodService, dateService, uidService, crypto, logger);
    } else if (xmlObject['NotaCreditoElectronica']) {
        digitalDocument = await convertDocumentoDeHaciendaToDigitalDocument(xmlObject['NotaCreditoElectronica'], DocumentType.CreditNote, user, provinceService, cantonService, districtService, neighborhoodService, dateService, uidService, crypto, logger);
    } else if (xmlObject['NotaDebitoElectronica']) {
        digitalDocument = await convertDocumentoDeHaciendaToDigitalDocument(xmlObject['NotaDebitoElectronica'], DocumentType.DebitNote, user, provinceService, cantonService, districtService, neighborhoodService, dateService, uidService, crypto, logger);
    } else if (xmlObject['TiqueteElectronico']) {
        //No deberían llegar tiqutes electrónicos, pero se manejan por si acaso
        digitalDocument = await convertDocumentoDeHaciendaToDigitalDocument(xmlObject['TiqueteElectronico'], DocumentType.Ticket, user, provinceService, cantonService, districtService, neighborhoodService, dateService, uidService, crypto, logger);
    } else if (xmlObject['MensajeHacienda']) {
        message = xmlObject['MensajeHacienda'] as MensajeHacienda;
        message.Mensaje = message.Mensaje.toString() as CodigoMensajeHacienda;
    } else if (xmlObject['FacturaElectronicaCompra']) {
        return {
            isValid: false,
            details: 'Las facturas electrónicas de compra no deben cargarse desde el xml.',
            file: file
        }
    } else if (xmlObject['FacturaElectronicaExportacion']) {
        return {
            isValid: false,
            details: 'Las facturas electrónicas de exportación no deben cargarse desde el xml.',
            file: file
        }
    } else {
        return {
            isValid: false,
            details: 'El documento no es un documento de Hacienda válido.',
            file: file
        }
    }

    if (digitalDocument) {
        removeEmpty(digitalDocument);

        if (company && (digitalDocument.company.identification !== company.identification || digitalDocument.company.identificationType !== company.identificationType)) {
            return {
                isValid: false,
                details: `El documento no corresponde a la compañia ${company.name}.\nEl documento pertenece a ${digitalDocument.company.name}.`,
                file: file
            }
        }

        digitalDocument.company.uid = company?.uid ?? '';
        digitalDocument.company.image = company?.image ?? null;

        return { isValid: true, details: 'El documento se ha cargado correctamente.', file: file, document: digitalDocument };
    }

    if (message) {
        removeEmpty(message);

        if (company && (message.NumeroCedulaReceptor !== company.identification.toString() || message.TipoIdentificacionReceptor !== company.identificationType)) {
            return {
                isValid: false,
                details: `El mensaje de hacienda no corresponde a la compañia ${company.name}.\nEl mensaje pertenece a ${message.NombreReceptor}.`,
                file: file
            }
        }

        return { isValid: true, details: 'El documento se ha cargado correctamente.', file: file, message: message };
    }

    return {
        isValid: false,
        details: 'El archivo no es un Documento o Mensaje valido.',
        file: file
    }
}

export const nativeType = (value: any) => {
    var nValue = Number(value);
    if (!isNaN(nValue)) {
        return nValue;
    }
    var bValue = value.toLowerCase();
    if (bValue === 'true') {
        return true;
    } else if (bValue === 'false') {
        return false;
    }
    return value;
}

export const convertEmptyObjectsToNull = (obj: any) => {
    // Recorre las claves del objeto
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            // Si el valor es un objeto y está vacío, lo reemplaza por null
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                // Verifica si el objeto es una instancia de Date
                if (obj[key] instanceof Date) {
                    continue; // Si es Date, no hacemos nada
                }

                // Si el objeto está vacío, lo reemplaza por null
                if (Object.keys(obj[key]).length === 0) {
                    obj[key] = null;
                } else {
                    // Llama recursivamente para verificar propiedades anidadas
                    convertEmptyObjectsToNull(obj[key]);
                }
            }
        }
    }
}

export const convertDocumentoDeHaciendaToDigitalDocument = async (documento: Documento, type: DocumentType, user: UserRef, provinceService: ITerritoryService, cantonService: ITerritoryService, districtService: ITerritoryService, neighborhoodService: ITerritoryService, dateService: IDateService, uidService: IUIDService, crypto: Crypto, logger: ILogger): Promise<DigitalDocument> => {
    //Se ajustan valores que se esperan como array pero pueden ser valores unicos y asi lo interpreta el parser

    if (documento.OtrosCargos && !Array.isArray(documento.OtrosCargos)) {
        documento.OtrosCargos = [documento.OtrosCargos];
    } else if (!documento.OtrosCargos) {
        documento.OtrosCargos = [];
    }

    if (documento.MedioPago && !Array.isArray(documento.MedioPago)) {
        documento.MedioPago = [documento.MedioPago];
    } else if (!documento.MedioPago) {
        documento.MedioPago = [];
    }

    if (documento.InformacionReferencia && !Array.isArray(documento.InformacionReferencia)) {
        documento.InformacionReferencia = [documento.InformacionReferencia];
    } else if (!documento.InformacionReferencia) {
        documento.InformacionReferencia = [];
    }

    if (documento.DetalleServicio.LineaDetalle && !Array.isArray(documento.DetalleServicio.LineaDetalle)) {
        documento.DetalleServicio.LineaDetalle = [documento.DetalleServicio.LineaDetalle];
    } else if (!documento.DetalleServicio.LineaDetalle) {
        documento.DetalleServicio.LineaDetalle = [];
    }

    documento.DetalleServicio.LineaDetalle = documento.DetalleServicio.LineaDetalle.map((line: any) => {
        if (line.Impuesto && !Array.isArray(line.Impuesto)) {
            line.Impuesto = [line.Impuesto];
        } else if (!line.Impuesto) {
            line.Impuesto = [];
        }
        if (line.Descuento && !Array.isArray(line.Descuento)) {
            line.Descuento = [line.Descuento];
        } else if (!line.Descuento) {
            line.Descuento = [];
        }
        if (line.CodigoComercial && !Array.isArray(line.CodigoComercial)) {
            line.CodigoComercial = [line.CodigoComercial];
        } else if (!line.CodigoComercial) {
            line.CodigoComercial = [];
        }
        return line;
    });

    let roundDecimals = 0;

    let companyAddress = null;

    if (documento.Receptor?.Ubicacion) {
        const province = await provinceService.byIdOnce(documento.Receptor.Ubicacion.Provincia);
        const canton = await cantonService.byIdOnce(documento.Receptor.Ubicacion.Canton, undefined, province!.uid);
        const district = await districtService.byIdOnce(documento.Receptor.Ubicacion.Distrito, undefined, province!.uid, canton!.uid);
        const neighborhood = documento.Receptor.Ubicacion.Barrio ? await neighborhoodService.byIdOnce(documento.Receptor?.Ubicacion?.Barrio, undefined, province!.uid, canton!.uid, district!.uid) : null;

        companyAddress = {
            province: getTerritoryReference(province!),
            canton: getTerritoryReference(canton!),
            district: getTerritoryReference(district!),
            neighborhood: neighborhood ? getTerritoryReference(neighborhood) : null,
            address: documento.Receptor.Ubicacion.OtrasSenas ?? '',
            zipCode: Number(`${province?.uid}${canton?.uid.padStart(2, '0')}${district?.uid.padStart(2, '0')}`)
        }
    }

    const company: CompanyRef = {
        uid: '', //Se asigna al conocer la compañia
        image: null, //Se asigna al conocer la compañia
        name: documento.Receptor?.Nombre ?? '',
        identificationType: documento.Receptor?.Identificacion?.Tipo ? (documento.Receptor.Identificacion.Tipo as TipoIdentificacion) : (null as any),
        identification: documento.Receptor?.Identificacion?.Numero ?? (null as any),
        tradeName: documento.Receptor?.NombreComercial ?? null,
        branch: {
            uid: '',
            name: '',
            number: Number(documento.NumeroConsecutivo.substring(0, 3)),
            warehouse: null,
            cashDesk: {
                uid: ExternalCashDeskId,
                number: Number(documento.NumeroConsecutivo.substring(3, 8)),
                balanceId: null
            },
            address: companyAddress as any, //se convierte en any, ya que las emitidas siempre tentran dirección, pero las recibidas pueden venir en nulo, lo que no debería afectar el proceso. Se podria validar y si es nulo, obtener el de la compañia
            telephones: [],
        },
        configuration: {
            digitalInvoice: {
                isEnable: true,
                email: documento.Receptor?.CorreoElectronico ?? ''
            },
            stock: {
                isEnable: false,
                allowNegative: false
            }
        }
    };

    let supplierAddress: CostaRicanAddress | null = null;

    if (documento.Emisor.Ubicacion) {
        const province = await provinceService.byIdOnce(documento.Emisor.Ubicacion.Provincia);
        const canton = await cantonService.byIdOnce(documento.Emisor.Ubicacion!.Canton, undefined, province!.uid);
        const district = await districtService.byIdOnce(documento.Emisor.Ubicacion!.Distrito, undefined, province!.uid, canton!.uid);
        const neighborhood = documento.Emisor.Ubicacion!.Barrio ? await neighborhoodService.byIdOnce(documento.Emisor.Ubicacion!.Barrio, undefined, province!.uid, canton!.uid, district!.uid) : null;

        supplierAddress = {
            country: {
                uid: 'CRI',
                name: 'Costa Rica'
            },
            province: getTerritoryReference(province!),
            canton: getTerritoryReference(canton!),
            district: getTerritoryReference(district!),
            neighborhood: neighborhood ? getTerritoryReference(neighborhood) : null,
            address: documento.Emisor.Ubicacion.OtrasSenas ?? '',
            zipCode: Number(`${province?.uid}${canton?.uid.padStart(2, '0')}${district?.uid.padStart(2, '0')}`)
        }
    }

    const supplier: SupplierRef = {
        uid: '', //Se asigna al conocer el proveedor
        image: null, //Se asigna al conocer el proveedor
        name: documento.Emisor.Nombre,
        identificationType: documento.Emisor.Identificacion.Tipo as TipoIdentificacion,
        identification: documento.Emisor.Identificacion.Numero,
        tradeName: documento.Emisor.NombreComercial ?? null,
        branch: {
            uid: '', //Se asigna al conocer el proveedor
            name: '', //Se asigna al conocer el proveedor
            address: supplierAddress,
            telephones: [],
            emails: [documento.Emisor.CorreoElectronico]
        },
        manualEmails: [],
    };

    const lines: Line[] = [];

    for (const line of documento.DetalleServicio.LineaDetalle) {
        const newLine = {
            uid: newShortUID(crypto, 5, lines.map(l => l.uid)),
            amount: Number(line.Cantidad),
            product: {
                uid: '',
                image: null,
                multifieldType: '',
                code: line.CodigoComercial ? line.CodigoComercial.map(cc => cc.Codigo).join(" | ") : 'sin código', //TODO: vienes varios codigos, cual es el correcto?
                description: line.Detalle,
                cost: 0, //No viene el costo
                maxDiscount: 0, //No viene el descuento maximo
                allowResellBelowCost: false, //No viene si se puede vender por debajo del costo
                allowFullDiscount: false, //No viene si se puede dar descuento completo
                taxes: line.Impuesto.map(t => ({
                    type: TaxDetails.find(td => td.codigoImpuesto === t.Codigo)!.uid,
                    mode: TaxMode.normal,
                    percentage: (t.Tarifa ?? 0) / 100,
                    exempt: t.Exoneracion ? {
                        type: t.Exoneracion.TipoDocumento,
                        number: t.Exoneracion.NumeroDocumento,
                        date: dateService.fromDate(t.Exoneracion.FechaEmision),
                        name: t.Exoneracion.NombreInstitucion,
                        percentage: Number(t.Exoneracion.PorcentajeExoneracion) / 100,
                    } : null
                })),
                fixedPrices: [],
                unit: line.UnidadMedida,
                cabysCode: line.Codigo ?? '',
                stock: {
                    requested: 0,
                    available: 0,
                    holded: 0,
                    committed: 0,
                    sold: 0,
                }
            },
            price: Number(line.PrecioUnitario),
            discount: line.Descuento?.length ?? 0 > 0 ? {
                type: DiscountType.Amount,
                name: line.Descuento?.map(d => d.NaturalezaDescuento).join(" | ") ?? '',
                amount: (line.Descuento?.reduce((acc, d) => acc + d.MontoDescuento, 0) ?? 0) / Number(line.Cantidad)
            } as AmountDiscount : null,
            notes: null,
            financialAdjustment: null,
            summaryAdjustment: null, //TODO: revisar si en algunos casos se debe ajustar esto, por ejemplo si viene solo impuesto o un precio sin impuesto ni exoneraciones.
            additionalDiscount: null, // Si aplica algún otro descuento adicional
            modifiers: null,
        };

        lines.push(newLine);

        //Se calculan los totales de la linea para determinar la cantidad de decimales a redondear

        // const summary = calculateLineTotals(newLine);

        // roundDecimals = Math.max(roundDecimals, getRoundDecimals(summary.lineSubTotal, line.MontoTotal));
        // roundDecimals = Math.max(roundDecimals, getRoundDecimals(summary.lineSubTotal - summary.lineDiscount, line.SubTotal));
        // roundDecimals = Math.max(roundDecimals, getRoundDecimals(summary.lineTotal, line.MontoTotalLinea));

        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(line.MontoTotal));
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(line.SubTotal));
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(line.MontoTotalLinea));

        if (line.Descuento) {
            for (const descuento of line.Descuento) {
                roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(descuento.MontoDescuento));
            }
        }

        if (line.BaseImponible) {
            roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(line.BaseImponible));
        }

        for (const impuesto of line.Impuesto) {
            roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(impuesto.Monto));

            if (impuesto.Exoneracion) {
                roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(impuesto.Exoneracion.MontoExoneracion));
            }
        }

        if (line.ImpuestoNeto) {
            roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(line.ImpuestoNeto));
        }
    }

    //TODO: creo que esto deberia hacerse durante la conciliación de pagos
    const payments: Payment[] = documento.MedioPago.map(medio => ({
        type: PaymentDetails.find(p => p.medioPago === medio)!.uid,
        currency: {
            uid: documento.ResumenFactura.CodigoTipoMoneda?.CodigoMoneda ?? Currencies.CRC,
            exchangeRateCRC: documento.ResumenFactura.CodigoTipoMoneda?.TipoCambio ?? 1,
            exchangeRate: 1 //Depende de la compañia, por lo que se completa una vez se sepa la compañia de la factura
        },
        amount: 0,
        bank: undefined,
        reference: undefined,
    }));

    if (documento.ResumenFactura.TotalServGravados) {
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalServGravados));
    }

    if (documento.ResumenFactura.TotalServExentos) {
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalServExentos));
    }

    if (documento.ResumenFactura.TotalServExonerado) {
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalServExonerado));
    }

    if (documento.ResumenFactura.TotalMercanciasGravadas) {
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalMercanciasGravadas));
    }

    if (documento.ResumenFactura.TotalMercanciasExentas) {
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalMercanciasExentas));
    }

    if (documento.ResumenFactura.TotalMercExonerada) {
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalMercExonerada));
    }

    if (documento.ResumenFactura.TotalGravado) {
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalGravado));
    }

    if (documento.ResumenFactura.TotalExento) {
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalExento));
    }

    if (documento.ResumenFactura.TotalExonerado) {
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalExonerado));
    }

    roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalVenta));

    if (documento.ResumenFactura.TotalDescuentos) {
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalDescuentos));
    }

    roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalVentaNeta));

    if (documento.ResumenFactura.TotalImpuesto) {
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalImpuesto));
    }

    if (documento.ResumenFactura.TotalIVADevuelto) {
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalIVADevuelto));
    }

    if (documento.ResumenFactura.TotalOtrosCargos) {
        roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalOtrosCargos));
    }

    roundDecimals = Math.max(roundDecimals, getDecimalsPlaces(documento.ResumenFactura.TotalComprobante));

    const digitalDocument: DigitalDocument = {
        uid: uidService.newUID(),
        user: user,
        isDraft: false,

        type: type,
        key: documento.Clave,
        economicActivity: documento.CodigoActividad.toString(),
        number: documento.NumeroConsecutivo,
        offlineNumber: null,
        date: dateService.fromDate(documento.FechaEmision),
        company,
        client: null,
        supplier: supplier,
        paymentTerm: {
            type: PaymentTermDetails.find(pt => pt.condicionVenta === documento.CondicionVenta)!.uid,
            days: [CondicionVenta.Credito, CondicionVenta.ServiciosPrestadosCredito].includes(documento.CondicionVenta) && documento.PlazoCredito ? Number(documento.PlazoCredito) : 0
        },
        payments,
        lines,
        charges: documento.OtrosCargos.map(cargo => ({
            type: cargo.TipoDocumento,
            identificationType: null,
            identification: cargo.NumeroIdentidadTercero ?? null,
            name: cargo.NombreTercero ?? null,
            description: cargo.Detalle,
            percentage: cargo.Porcentaje ?? 0,
            amount: cargo.MontoCargo
        })),
        roundDecimals: roundDecimals,
        currency: {
            uid: documento.ResumenFactura.CodigoTipoMoneda?.CodigoMoneda ?? Currencies.CRC,
            exchangeRateCRC: documento.ResumenFactura.CodigoTipoMoneda?.TipoCambio ?? 1,
            exchangeRate: 1 //Depende de la compañia, por lo que se completa una vez se sepa la compañia de la factura
        },
        references: documento.InformacionReferencia.map(ref => ({
            type: ref.TipoDoc,
            number: ref.Numero ?? '',
            date: dateService.fromDate(ref.FechaEmision),
            code: ref.Codigo ?? null,
            reason: ref.Razon ?? ''
        })),
        relatedDocuments: [],
        others: [],
        notes: '',
        pdf: '',
        xls: '',
        xml: null,
        xmlResponse: null,
        createdBy: user.uid,
        createdDate: dateService.fromDate(new Date()),
        modifiedBy: user.uid,
        modifiedDate: dateService.fromDate(new Date()),
        statusLog: [],
        response: null,
    };

    let summary = calculateSummary(digitalDocument.lines.map(l => calculateLineTotals(l)), digitalDocument.charges, roundDecimals);

    if (summary.total !== documento.ResumenFactura.TotalComprobante) {
        logger.error(`El total del documento no coincide con el total del comprobante. Total del documento: ${summary.total}, Total del comprobante: ${documento.ResumenFactura.TotalComprobante}`);
    }

    return digitalDocument;
}

export const processPDF = async (file: File | Buffer, getDocument: (src: string | URL | TypedArray | ArrayBuffer | DocumentInitParameters) => PDFDocumentLoadingTask, logger: ILogger): Promise<Result> => {
    try {
        const buffer = 'arrayBuffer' in file ? await file.arrayBuffer() : new Uint8Array(file);

        // Carga el documento PDF
        const loadingTask = getDocument(buffer);
        const pdf = await loadingTask.promise;

        let text = '';
        const totalPages = pdf.numPages;

        // Recorre las páginas del PDF de manera secuencial
        for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const textContent = await page.getTextContent();

            // Extrae el texto de la página
            textContent.items.forEach((item: any) => {
                text += item.str + ' ';
            });
        }

        // Buscar la clave en el texto extraído
        const clave = searchForClave(text);

        if (clave) {
            return { isValid: true, details: 'El documento se ha cargado correctamente.', file: file, pdfKey: clave };
        } else {
            return { isValid: false, details: 'No se encontró la clave del documento en el archivo PDF.', file: file };
        }
    } catch (error) {
        logger.error('Error processing PDF:', error);
        return { isValid: false, details: 'Error al procesar el archivo PDF.', file: file };
    }
}

export const searchForClave = (text: string): string | null => {
    // Expresión regular para encontrar un número de 50 caracteres
    const claveRegex = /\b\d{50}\b/;
    const match = text.match(claveRegex);
    return match ? match[0] : null;
}

export const calculatePrice = (priceList: PriceList, cost: number, tax: number): number => {
    let calculatedPrice: number;

    switch (priceList.profitType) {
        case ProfitType.Manual:
            return NaN;
        case ProfitType.OnPurchase:
            calculatedPrice = cost * (1 + priceList.profitPercentage);
            break;
        case ProfitType.OnSale:
            calculatedPrice = cost / (1 - priceList.profitPercentage);
            break;
        default:
            throw new Error(`${priceList.profitType} no es un valor conocido para ProfitType`);
    }

    if (priceList.priceAdjustment.isEnabled) {
        let adjustmentFunction: Function;

        switch (priceList.priceAdjustment.type) {
            case AdjustmentType.Ceil:
                adjustmentFunction = Math.ceil;
                break;
            case AdjustmentType.Round:
                adjustmentFunction = Math.round;
                break;
            case AdjustmentType.Floor:
                adjustmentFunction = Math.floor;
                break;
            default:
                throw new Error(`${priceList.priceAdjustment.type} no es un valor conocido para AjustmentType`)
        }

        if (priceList.priceAdjustment.beforeTax) {
            calculatedPrice = adjustmentFunction(calculatedPrice / priceList.priceAdjustment.factor) * priceList.priceAdjustment.factor;
        }
        else {
            let totalPrice = calculatedPrice * (1 + tax);

            totalPrice = adjustmentFunction(totalPrice / priceList.priceAdjustment.factor) * priceList.priceAdjustment.factor;
            calculatedPrice = totalPrice / (1 + tax);
        }
    }

    return calculatedPrice;
}

export const otherByType = (others: Other[], type: DocumentType): Other[] => {
    const byType = others.filter(other => other.documentTypes.includes(type));

    for (let other of byType) {
        if (other.type === OtherType.ContentContainer) {
            other.value = otherByType(other.value as (OtherContentContainer | OtherContentText)[], type) as any;
        }
    }

    return byType;
}

export const setOthersValues = (others: Other[], values: { name: string, value: string }[]) => {
    for (let other of others) {
        if (other.type === OtherType.ContentContainer) {
            other.value = setOthersValues(other.value as (OtherContentContainer | OtherContentText)[], values) as any;
        } else {
            other.value = values.find(v => v.name === other.name)?.value ?? '';
        }
    }

    return others;
}

export const everyHasValue = (others: Other[]): boolean => {
    if (others.length === 0) {
        return true;
    }

    return others.every(other => {
        if (other.type === OtherType.ContentContainer) {
            return everyHasValue(other.value as (OtherContentContainer | OtherContentText)[]);
        } else {
            return !other.required || (other as OtherContentText).value.trim() !== '';
        }
    });
}

export const formatXMLAsHaciendaResponse = (xmlString: string): string => {
    const newLineHacienda = '\n    ';

    // Parece que alguno sistemas manipulan el XML de respuesta de Hacienda despues de recibirlo, lo que invalida la firma.
    //Se manipula para darle el formato conocido de haceinda y se vuelve a validar la firma.

    let formattedXML = xmlString;

    //Remover los espacios en blanco, tabulaciones y saltos de línea entre los tags
    formattedXML = formattedXML.replace(/>\s+</g, '><');

    //Agregar saltos de línea después de las etiquetas de cierre
    formattedXML = formattedXML.replace('><Clave>', `>${newLineHacienda}<Clave>`);
    formattedXML = formattedXML.replace('><NombreEmisor>', `>${newLineHacienda}<NombreEmisor>`);
    formattedXML = formattedXML.replace('><TipoIdentificacionEmisor>', `>${newLineHacienda}<TipoIdentificacionEmisor>`);
    formattedXML = formattedXML.replace('><NumeroCedulaEmisor>', `>${newLineHacienda}<NumeroCedulaEmisor>`);
    formattedXML = formattedXML.replace('><NombreReceptor>', `>${newLineHacienda}<NombreReceptor>`);
    formattedXML = formattedXML.replace('><TipoIdentificacionReceptor>', `>${newLineHacienda}<TipoIdentificacionReceptor>`);
    formattedXML = formattedXML.replace('><NumeroCedulaReceptor>', `>${newLineHacienda}<NumeroCedulaReceptor>`);
    formattedXML = formattedXML.replace('><Mensaje>', `>${newLineHacienda}<Mensaje>`);
    formattedXML = formattedXML.replace('><DetalleMensaje>', `>${newLineHacienda}<DetalleMensaje>`);
    formattedXML = formattedXML.replace('><MontoTotalImpuesto>', `>${newLineHacienda}<MontoTotalImpuesto>`);
    formattedXML = formattedXML.replace('><TotalFactura>', `>${newLineHacienda}<TotalFactura>`);
    formattedXML = formattedXML.replace('><ds:Signature', `>\n<ds:Signature`); //No lleva espacios en blanco

    //Restaura el espacio en blanco del mensaje vacio si hubiera
    formattedXML = formattedXML.replace('<DetalleMensaje></DetalleMensaje>', '<DetalleMensaje> </DetalleMensaje>');

    return standardizeToUTF8(formattedXML);
}

export const standardizeToUTF8 = (text: string) => {
    let utf8String = text;

    try {
        //para caracteres tipo Ã³ Ã± que se generan en algunos casos por convertir UTF-8 a ISO-8859-1 (ISO-Latin-1)
        utf8String = decodeURIComponent(escape(utf8String));
    } catch (e) {
        //Algunos casos da error por el proceso anterior, pero entonces ya no es necesario aplicarlo.
    }

    return utf8String;
}

export const newShortUID = (crypto: Crypto, length: number, others: string[] = []): string => {
    let uid;

    do {
        uid = crypto.randomUUID().substring(0, length);
    }
    while (others.includes(uid))

    return uid;
}

export const extensionFromUrl = (url: string) => {
    const extensionIndex = url.lastIndexOf('.');
    const querystringIndex = url.indexOf('?', extensionIndex);
    if (extensionIndex !== -1 && querystringIndex !== -1) {
        const extension = url.substring(extensionIndex + 1, querystringIndex).toUpperCase();

        return extension;
    } else {
        return null; // No se encontró una extensión de imagen en la URL
    }
}

export const getDecimalsPlaces = (value: number): number => {
    const valueString = value.toString();
    const decimalIndex = valueString.indexOf('.');

    return decimalIndex === -1 ? 0 : valueString.length - decimalIndex - 1;
}

export const getRoundDecimals = (rawValue: number, roundedValue: number): number => {
    const tolerance = 1e-10; // Tolerancia para comparar números flotantes debido a impresiciones de JavaScript: 0.1 + 0.2; // 0.30000000000000004

    for (let decimals = 0; decimals <= 5; decimals++) {
        // Redondear el total calculado al número actual de decimales
        const rounded = round(rawValue, decimals);

        // Verificar si coincide con el total original
        if (Math.abs(rounded - roundedValue) < tolerance) {
            return decimals;
        }
    }

    return 5; // Retornar 5 si no se encontró un factor de redondeo
}

export const round = (value: number, decimals: number): number => {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
}

export const numberingPlaceholders: {
    uid: string,
    name: string,
    type: 'date',
    format?: Intl.DateTimeFormatOptions
}[] = [
        { uid: '{YYYY}', name: 'Año 4 dígitos', type: 'date', format: { year: 'numeric' } },
        { uid: '{YY}', name: 'Año 2 dígitos', type: 'date', format: { year: '2-digit' } },
        { uid: '{MM}', name: 'Mes 2 dígitos', type: 'date', format: { month: '2-digit' } },
        { uid: '{MMM}', name: 'Mes corto', type: 'date', format: { month: 'short' } },
        { uid: '{MMMM}', name: 'Mes largo', type: 'date', format: { month: 'long' } },
        { uid: '{DD}', name: 'Día 2 dígitos', type: 'date', format: { day: '2-digit' } },
    ];

export const formatDocumentNumber = (number: number | string | null | undefined, date: Date, config: { prefix: string, suffix: string } = { prefix: '', suffix: '' }): string => {
    let formatted = `${config.prefix}${(number ?? 0).toString().padStart(5, '0')}${config.suffix}`;

    for (const placeholder of numberingPlaceholders) {
        if (placeholder.type === 'date') {
            formatted = formatted.replace(new RegExp(placeholder.uid, 'g'), date.toLocaleString('es-CR', placeholder.format));
            continue;
        }

        formatted.replace(placeholder.uid, '[Invalid Placeholder]');
    }

    return formatted;
}

export const toWords = (number: number, currencyId: string | undefined): string => {
    let currencyOptions;

    switch (currencyId) {
        case Currencies.CRC:
            currencyOptions = {
                name: 'colón',
                singular: 'colón',
                plural: 'colones',
                symbol: '₡',
                fractionalUnit: {
                    name: 'céntimo',
                    plural: 'céntimos',
                    symbol: '¢',
                },
            }
            break;
        case Currencies.USD:
            currencyOptions = {
                name: 'dólar',
                singular: 'dólar',
                plural: 'dólares',
                symbol: '$',
                fractionalUnit: {
                    name: 'centavo',
                    plural: 'centavos',
                    symbol: '¢',
                },
            }
            break;
        default:
            currencyOptions = {
                name: '',
                singular: '',
                plural: '',
                symbol: '',
                fractionalUnit: {
                    name: '',
                    plural: '',
                    symbol: '',
                },
            }
            break;
    }

    const toWords = new ToWords({
        localeCode: 'es-MX',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            currencyOptions: currencyOptions,
        },
    });

    return toWords.convert(number).toLowerCase();
}