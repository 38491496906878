import { PermissionsCodes } from "./Permission"
import { Profile } from "./Profile"

export type Section = {
    icon: string,
    name: string,
    pages: Page[]
    hidded: boolean
}

export type Page = {
    title: string,
    url: string,
    icon: string,
    permissions: PermissionsCodes[] | (() => PermissionsCodes[])
    hidded: boolean
    isDynamic?: boolean
}

export const menuPages = (profile: Profile): Page[] => {
    return [
        { title: 'Compañías', url: '/companies', icon: 'business-outline', permissions: [PermissionsCodes.ViewCompanies, PermissionsCodes.EditCompanies, PermissionsCodes.CreateCompanies, PermissionsCodes.DisableCompanies], hidded: true },
        { title: 'Sucursales', url: '/branches', icon: 'storefront-outline', permissions: [PermissionsCodes.ViewBranches, PermissionsCodes.EditBranches, PermissionsCodes.CreateBranches, PermissionsCodes.DisableBranches], hidded: true },
        { title: 'Cajas', url: '/cash-desks', icon: 'tv-outline', permissions: () => (profile.company.configuration.digitalInvoice.isEnable ? [PermissionsCodes.ViewCashDesk, PermissionsCodes.EditCashDesk, PermissionsCodes.EditCashDeskConsecutives, PermissionsCodes.CreateCashDesk, PermissionsCodes.DisableCashDesk, ...(profile.company.configuration.cashDeskBalance.isEnable ? [PermissionsCodes.ViewCashDeskBalances, PermissionsCodes.AdjustCashDeskBalances] : [])] : []), hidded: true },
        { title: 'Usuarios', url: '/users', icon: 'id-card-outline', permissions: [PermissionsCodes.ViewUsers, PermissionsCodes.EditUsers, PermissionsCodes.CreateUsers, PermissionsCodes.DisableUsers], hidded: true },
        { title: 'Roles', url: '/roles', icon: 'shield-checkmark-outline', permissions: [PermissionsCodes.ViewRoles, PermissionsCodes.EditRoles, PermissionsCodes.CreateRoles, PermissionsCodes.DisableRoles], hidded: true },
        { title: 'Bancos', url: '/banks', icon: 'card-outline', permissions: () => (profile.company.configuration.digitalInvoice.isEnable ? [PermissionsCodes.ViewBanks, PermissionsCodes.EditBanks, PermissionsCodes.CreateBanks, PermissionsCodes.DisableBanks] : []), hidded: true },
        { title: 'Tipo de Cambio', url: '/exchange-rates', icon: 'exchange-rate', permissions: () => (profile.company.configuration.digitalInvoice.isEnable || profile.company.configuration.purchase.isEnable || profile.company.configuration.quote.isEnable ? [PermissionsCodes.CreateExchangeRates] : []), hidded: true },
        { title: 'Pantallas Dinámicas', url: '/dynamics', icon: 'code-working-outline', permissions: [PermissionsCodes.ViewDynamicPages, PermissionsCodes.CreateDynamicPages, PermissionsCodes.EditDynamicPages, PermissionsCodes.DisableDynamicPages], hidded: true },
        { title: 'Tipos Dinámicos', url: '/multi-field-page-types', icon: 'code-outline', permissions: [PermissionsCodes.ViewDynamicTypes, PermissionsCodes.CreateDynamicTypes, PermissionsCodes.EditDynamicTypes, PermissionsCodes.DisableDynamicTypes], hidded: true },
        { title: 'Proveedores', url: '/suppliers', icon: 'albums-outline', permissions: () => (profile.company.configuration.digitalInvoice.isEnable || profile.company.configuration.purchase.isEnable ? [PermissionsCodes.ViewSuppliers, PermissionsCodes.EditSuppliers, PermissionsCodes.CreateSuppliers, PermissionsCodes.DisableSuppliers] : []), hidded: true },
        { title: 'Ordenes de Compra', url: '/purchases', icon: 'file-tray-full-outline', permissions: () => (profile.company.configuration.purchase.isEnable ? [PermissionsCodes.ViewPurchases, PermissionsCodes.EditPurchases, PermissionsCodes.CreatePurchases, PermissionsCodes.CancelMaintenances] : []), hidded: true },
        { title: 'Documentos Electrónicos\nGastos y Compras', url: '/purchase-invoices', icon: 'receipt-outline', permissions: () => (profile.company.configuration.digitalInvoice.isEnable ? [PermissionsCodes.ViewPurchaseDigitalDocuments, PermissionsCodes.CreatePurchaseInvoices, PermissionsCodes.AcceptRejectDigitalDocuments] : []), hidded: true },
        { title: 'Clientes', url: '/clients', icon: 'people-outline', permissions: () => (profile.company.configuration.digitalInvoice.isEnable || profile.company.configuration.maintenance.isEnable || profile.company.configuration.quote.isEnable ? [PermissionsCodes.ViewClients, PermissionsCodes.EditClients, PermissionsCodes.CreateClients, PermissionsCodes.DisableClients] : []), hidded: true },
        { title: 'Cotizaciones', url: '/quotes', icon: 'clipboard-outline', permissions: () => (profile.company.configuration.quote.isEnable ? [PermissionsCodes.CreateQuotes, PermissionsCodes.EditQuotes, PermissionsCodes.AcceptRejectQuotes] : []), hidded: true },
        { title: 'Documentos Electrónicos\nVentas', url: '/invoices', icon: 'receipt-outline', permissions: () => (profile.company.configuration.digitalInvoice.isEnable ? [PermissionsCodes.CreateInvoices, PermissionsCodes.CreateTickets, PermissionsCodes.CreateCreditNote, PermissionsCodes.CreateDebitNote, PermissionsCodes.ViewDigitalDocuments] : []), hidded: true },
        { title: 'Ordenes de Mantenimiento', url: '/maintenances', icon: 'construct-outline', permissions: () => (profile.company.configuration.maintenance.isEnable ? [PermissionsCodes.ViewWorkshopMaintenances, PermissionsCodes.CreateWorkshopMaintenances, PermissionsCodes.InProcessMaintenance, PermissionsCodes.CompleteWorkshopMaintenances, PermissionsCodes.CloseMaintenances, PermissionsCodes.CancelMaintenances, PermissionsCodes.ViewOnSiteMaintenances, PermissionsCodes.CreateOnSiteMaintenances, PermissionsCodes.CompleteOnSiteMaintenances] : []), hidded: true },
        { title: 'Listas de Precio', url: '/priceLists', icon: 'pricetags-outline', permissions: () => (profile.company.configuration.digitalInvoice.isEnable || profile.company.configuration.quote.isEnable ? [PermissionsCodes.ViewPriceLists, PermissionsCodes.EditPriceLists, PermissionsCodes.CreatePriceLists, PermissionsCodes.DisablePriceLists] : []), hidded: true },
        { title: 'Productos', url: '/products', icon: 'list-outline', permissions: [PermissionsCodes.ViewProducts, PermissionsCodes.EditProducts, PermissionsCodes.CreateProducts, PermissionsCodes.DisableProducts], hidded: true },
        { title: '<Equipos>', url: '/fixables', icon: 'disc-outline', permissions: () => (profile.company.configuration.maintenance.isEnable ? [PermissionsCodes.ViewFixables, PermissionsCodes.EditFixables, PermissionsCodes.CreateFixables, PermissionsCodes.DisableFixables] : []), hidded: true },
        { title: 'Bodegas', url: '/warehouses', icon: 'cube-outline', permissions: () => (profile.company.configuration.stock.isEnable ? [PermissionsCodes.ViewWarehouse, PermissionsCodes.EditWarehouse, PermissionsCodes.CreateWarehouse, PermissionsCodes.DisableWarehouse] : []), hidded: true },
        { title: 'Traslados de Inventario', url: '/stock-transfers', icon: 'shuffle-outline', permissions: () => (profile.company.configuration.stock.isEnable ? [PermissionsCodes.ViewStockTransfers, PermissionsCodes.CreateStockTransfers, PermissionsCodes.RejectStockTransfers, PermissionsCodes.CloseStockTransfers, PermissionsCodes.CancelStockTransfers] : []), hidded: true },
        { title: 'Movimientos de Inventario', url: '/stock-changes', icon: 'swap-horizontal-outline', permissions: () => (profile.company.configuration.stock.isEnable ? [PermissionsCodes.ViewStockChanges, PermissionsCodes.CreateStockChanges] : []), hidded: true },
    ];
}