import { Address } from "./Address";
import { ListDocument, IdentifiedDocument, ImagedDocument, NamedDocument, UniqueDocument } from "./BaseDocument";
import { Email } from "./Email";
import { Telephone } from "./Telephone";

export type Supplier = ListDocument &IdentifiedDocument & SupplierRef & {  
  currency: string,
  collectTaxes: boolean;
  creditTerm: number;

  //Estos datos no se guardan en base de datos, se pasan a la sucursal
  address: Address | null;
  telephones: Telephone[];
  emails: Email[];
}

export type SupplierRef = UniqueDocument & NamedDocument & ImagedDocument &{
  tradeName: string | null;
};

export const getReference = (supplier: Supplier): SupplierRef => {
  return {
    uid: supplier.uid,
    image: supplier.image,
    name: supplier.name,
    tradeName: supplier.tradeName,
  }
}