import { Address } from "./Address";
import { ListDocument, IdentifiedDocument, ImagedDocument, NamedDocument, UniqueDocument } from "./BaseDocument";
import { Email } from "./Email";
import { Other } from "./Others";
import { Telephone } from "./Telephone";

export type Client = ListDocument & IdentifiedDocument & ClientRef & {
  creditTerm: number;
  priceListId: string | null;

  //Estos datos no se guardan en base de datos, se pasan a la sucursal
  address: Address | null;
  telephones: Telephone[];
  emails: Email[];

  others: Other[] | null;
};

export type ClientRef = UniqueDocument & ImagedDocument & NamedDocument & {
  tradeName: string | null;
};

export const getReference = (client: Client): ClientRef => {
  return {
    uid: client.uid,
    image: client.image,
    name: client.name,
    tradeName: client.tradeName
  }
}