
import { CanDeactivateFn } from '@angular/router';
import { StandardListPage } from '../pages/base/standard-list.page';
import { ListDocument } from 'models/BaseDocument';
import { redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

export const DirtyFormGuard: CanDeactivateFn<StandardListPage<ListDocument>> = (page: StandardListPage<ListDocument>): Promise<boolean> | boolean => {
    if (!page.form) {
        return true;
    }

    return page.form.canClose(undefined);
};

export const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
export const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);