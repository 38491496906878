import { ListDocument, UniqueDocument } from "./BaseDocument";

export type Territory = ListDocument & TerritoryRef;

export type TerritoryRef = UniqueDocument & {
  name: string;
}

export const getReference = (territory: Territory): TerritoryRef => {
  return {
    uid: territory.uid,
    name: territory.name,
  }
}