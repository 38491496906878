import { AdjustmentType, PriceList, ProfitType } from "../models/PriceList";
import { ExternalCashDeskId } from "../models/CashDesk";
import { CompanyRef as BaseCompanyRef } from "../models/Company";
import { Currencies } from "../models/Currency";
import { CompanyRef, DigitalDocument, DocumentType, Line, SupplierRef } from "../models/DigitalDocument";
import { CondicionVenta } from "../models/facturaelectronica/CondicionVenta";
import { Documento } from "../models/facturaelectronica/Documento";
import { CodigoMensajeHacienda } from "../models/facturaelectronica/Enums";
import { MensajeHacienda } from "../models/facturaelectronica/MensajeHacienda";
import { TipoIdentificacion } from "../models/facturaelectronica/TipoIdentificacion";
import { Payment, PaymentDetails } from "../models/Payment";
import { PaymentTermDetails } from "../models/PaymentTerm";
import { TaxDetails } from "../models/Tax";
import { Territory, getReference as getTerritoryReference } from "../models/Territory";
import { UserRef } from "../models/User";
import { ElementCompact, xml2js } from "xml-js";
import { Other, OtherContentContainer, OtherContentText, OtherType } from "../models/Others";

export const isDarkColor = (color: string): boolean => {
    // Elimina el "#" si está presente en la cadena del color hexadecimal
    const hexColor = color.replace("#", "");

    // Convierte el color hexadecimal a componentes RGB
    var r = parseInt(hexColor.substring(0, 2), 16) / 255;
    var g = parseInt(hexColor.substring(2, 4), 16) / 255;
    var b = parseInt(hexColor.substring(4, 6), 16) / 255;

    // Calcula la luminancia (Y) utilizando la fórmula
    var luminance = 0.299 * r + 0.587 * g + 0.114 * b;

    return luminance <= 0.5;
}

export const removeEmpty = (obj: any): any => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value === undefined) {
                delete obj[key];
            } else if (typeof value === 'object') {
                removeEmpty(value);
            }
        }
    }
}

export type Result = {
    isValid: boolean;
    details: string;
    file: File;
    document?: DigitalDocument;
    message?: MensajeHacienda;
    pdfKey?: string;
};

export interface ITerritoryService {
    byIdOnce(uid: string, transaction?: undefined, ...parentsIds: string[]): Promise<Territory | undefined>;
}

export interface IDateService {
    fromDate(date: Date): any;
}
export interface IUIDService {
    newUID(): string;
}

export const processXML = async (xmlContent: string, file: File, company: BaseCompanyRef | undefined, user: UserRef, provinceService: ITerritoryService, cantonService: ITerritoryService, districtService: ITerritoryService, neighborhoodService: ITerritoryService, dateService: IDateService, uidService: IUIDService, verifySignature: ((xml: string) => Promise<boolean>)): Promise<Result> => {
    const isSignatureValid = await verifySignature(xmlContent);

    if (!isSignatureValid) {
        return { isValid: false, details: 'La firma del documento no es válida.', file: file };
    }

    const options = {
        compact: true,
        trim: true,
        ignoreDeclaration: true,
        ignoreAttributes: true,
        textFn: (value: string, parentElement: any) => {
            try {
                const pOpKeys = Object.keys(parentElement._parent);
                const keyNo = pOpKeys.length;
                const keyName = pOpKeys[keyNo - 1];
                const arrOfKey = parentElement._parent[keyName];
                const arrOfKeyLen = arrOfKey.length;

                if (arrOfKeyLen > 0) {
                    const arr = arrOfKey;
                    const arrIndex = arrOfKey.length - 1;
                    arr[arrIndex] = value;
                } else {
                    if (['clave', 'numeroconsecutivo', 'condicionventa', 'codigo', 'tipo', 'tipodoc', 'tipoidentificacionemisor', 'tipoidentificacionreceptor', 'numero', 'numerocedulaemisor', 'numerocedulareceptor', 'mediopago'].includes(keyName.toLowerCase())) {
                        parentElement._parent[keyName] = value;
                    } else if (['fechaemision'].includes(keyName.toLowerCase())) {
                        parentElement._parent[keyName] = new Date(value);
                    } else {
                        parentElement._parent[keyName] = nativeType(value);
                    }
                }
            } catch (e) {
                console.error('Error:', e);
            }
        },
    };

    let xmlObject = xml2js(xmlContent, options) as ElementCompact;

    convertEmptyObjectsToNull(xmlObject);

    let digitalDocument: DigitalDocument | undefined;
    let message: MensajeHacienda | undefined;

    if (xmlObject['FacturaElectronica']) {
        digitalDocument = await convertDocumentoDeHaciendaToDigitalDocument(xmlObject['FacturaElectronica'], DocumentType.Invoice, user, provinceService, cantonService, districtService, neighborhoodService, dateService, uidService);
    } else if (xmlObject['NotaCreditoElectronica']) {
        digitalDocument = await convertDocumentoDeHaciendaToDigitalDocument(xmlObject['NotaCreditoElectronica'], DocumentType.CreditNote, user, provinceService, cantonService, districtService, neighborhoodService, dateService, uidService);
    } else if (xmlObject['NotaDebitoElectronica']) {
        digitalDocument = await convertDocumentoDeHaciendaToDigitalDocument(xmlObject['NotaDebitoElectronica'], DocumentType.DebitNote, user, provinceService, cantonService, districtService, neighborhoodService, dateService, uidService);
    } else if (xmlObject['MensajeHacienda']) {
        message = xmlObject['MensajeHacienda'] as MensajeHacienda;
        message.Mensaje = message.Mensaje.toString() as CodigoMensajeHacienda;
    } else if (xmlObject['TiqueteElectronico']) {
        return {
            isValid: false,
            details: 'Los tiquetes electrónicos no pueden ser usados para respaldar créditos fiscales.',
            file: file
        }
    } else if (xmlObject['FacturaElectronicaCompra']) {
        return {
            isValid: false,
            details: 'Las facturas electrónicas de compra no deben cargarse desde el xml.',
            file: file
        }
    } else if (xmlObject['FacturaElectronicaExportacion']) {
        return {
            isValid: false,
            details: 'Las facturas electrónicas de exportación no deben cargarse desde el xml.',
            file: file
        }
    } else {
        return {
            isValid: false,
            details: 'El documento no es un documento de Hacienda válido.',
            file: file
        }
    }

    if (digitalDocument) {
        removeEmpty(digitalDocument);

        if (company && (digitalDocument.company.identification !== company.identification || digitalDocument.company.identificationType !== company.identificationType)) {
            return {
                isValid: false,
                details: `El documento no corresponde a la compañia ${company.name}.\nEl documento pertenece a ${digitalDocument.company.name}.`,
                file: file
            }
        }

        digitalDocument.company.uid = company?.uid ?? '';
        digitalDocument.company.image = company?.image ?? null;

        return { isValid: true, details: 'El documento se ha cargado correctamente.', file: file, document: digitalDocument };
    }

    if (message) {
        removeEmpty(message);

        if (company && (message.NumeroCedulaReceptor !== company.identification.toString() || message.TipoIdentificacionReceptor !== company.identificationType)) {
            return {
                isValid: false,
                details: `El mensaje de hacienda no corresponde a la compañia ${company.name}.\nEl mensaje pertenece a ${message.NombreReceptor}.`,
                file: file
            }
        }

        return { isValid: true, details: 'El documento se ha cargado correctamente.', file: file, message: message };
    }

    return {
        isValid: false,
        details: 'El archivo no es un Documento o Mensaje valido.',
        file: file
    }
}

export const nativeType = (value: any) => {
    var nValue = Number(value);
    if (!isNaN(nValue)) {
        return nValue;
    }
    var bValue = value.toLowerCase();
    if (bValue === 'true') {
        return true;
    } else if (bValue === 'false') {
        return false;
    }
    return value;
}

export const convertEmptyObjectsToNull = (obj: any) => {
    // Recorre las claves del objeto
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            // Si el valor es un objeto y está vacío, lo reemplaza por null
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                // Verifica si el objeto es una instancia de Date
                if (obj[key] instanceof Date) {
                    continue; // Si es Date, no hacemos nada
                }

                // Si el objeto está vacío, lo reemplaza por null
                if (Object.keys(obj[key]).length === 0) {
                    obj[key] = null;
                } else {
                    // Llama recursivamente para verificar propiedades anidadas
                    convertEmptyObjectsToNull(obj[key]);
                }
            }
        }
    }
}

export const convertDocumentoDeHaciendaToDigitalDocument = async (documento: Documento, type: DocumentType, user: UserRef, provinceService: ITerritoryService, cantonService: ITerritoryService, districtService: ITerritoryService, neighborhoodService: ITerritoryService, dateService: IDateService, uidService: IUIDService): Promise<DigitalDocument> => {
    //Se ajustan valores que se esperan como array pero pueden ser valores unicos y asi lo interpreta el parser

    if (documento.OtrosCargos && !Array.isArray(documento.OtrosCargos)) {
        documento.OtrosCargos = [documento.OtrosCargos];
    } else if (!documento.OtrosCargos) {
        documento.OtrosCargos = [];
    }

    if (documento.MedioPago && !Array.isArray(documento.MedioPago)) {
        documento.MedioPago = [documento.MedioPago];
    } else if (!documento.MedioPago) {
        documento.MedioPago = [];
    }

    if (documento.InformacionReferencia && !Array.isArray(documento.InformacionReferencia)) {
        documento.InformacionReferencia = [documento.InformacionReferencia];
    } else if (!documento.InformacionReferencia) {
        documento.InformacionReferencia = [];
    }

    if (documento.DetalleServicio.LineaDetalle && !Array.isArray(documento.DetalleServicio.LineaDetalle)) {
        documento.DetalleServicio.LineaDetalle = [documento.DetalleServicio.LineaDetalle];
    } else if (!documento.DetalleServicio.LineaDetalle) {
        documento.DetalleServicio.LineaDetalle = [];
    }

    documento.DetalleServicio.LineaDetalle = documento.DetalleServicio.LineaDetalle.map((line: any) => {
        if (line.Impuesto && !Array.isArray(line.Impuesto)) {
            line.Impuesto = [line.Impuesto];
        } else if (!line.Impuesto) {
            line.Impuesto = [];
        }
        if (line.Descuento && !Array.isArray(line.Descuento)) {
            line.Descuento = [line.Descuento];
        } else if (!line.Descuento) {
            line.Descuento = [];
        }
        if (line.CodigoComercial && !Array.isArray(line.CodigoComercial)) {
            line.CodigoComercial = [line.CodigoComercial];
        } else if (!line.CodigoComercial) {
            line.CodigoComercial = [];
        }
        return line;
    });



    const province = await provinceService.byIdOnce(Number(documento.Receptor?.Ubicacion?.Provincia ?? 0).toString());
    const canton = province ? await cantonService.byIdOnce(Number(documento.Receptor?.Ubicacion?.Canton ?? 0).toString(), undefined, province.uid) : undefined;
    const district = province && canton ? await districtService.byIdOnce(Number(documento.Receptor?.Ubicacion?.Distrito ?? 0).toString(), undefined, province.uid, canton.uid) : undefined;
    const neighborhood = province && canton && district && documento.Emisor.Ubicacion!.Barrio ? await neighborhoodService.byIdOnce(Number(documento.Receptor?.Ubicacion?.Barrio ?? 0).toString(), undefined, province.uid, canton.uid, district.uid) : null;

    const company: CompanyRef = {
        uid: '',
        image: null,
        name: documento.Receptor?.Nombre ?? '',
        identificationType: documento.Receptor?.Identificacion.Tipo ? (documento.Receptor.Identificacion.Tipo as TipoIdentificacion) : TipoIdentificacion.CedulaFisica,
        identification: documento.Receptor?.Identificacion.Numero ?? '',
        tradeName: documento.Receptor?.NombreComercial ?? null,
        branch: {
            uid: '',
            name: '',
            number: Number(documento.NumeroConsecutivo.substring(0, 3)),
            warehouse: null,
            cashDesk: {
                uid: ExternalCashDeskId,
                number: Number(documento.NumeroConsecutivo.substring(3, 8)),
                balanceId: null
            },
            address: {
                province: province ? getTerritoryReference(province) : null as any,
                canton: canton ? getTerritoryReference(canton) : null as any,
                district: district ? getTerritoryReference(district) : null as any,
                neighborhood: neighborhood ? getTerritoryReference(neighborhood) : null,
                address: documento.Receptor?.Ubicacion?.OtrasSenas ?? '',
                zipCode: Number(`${province?.uid}${canton?.uid.padStart(2, '0')}${district?.uid.padStart(2, '0')}`)
            },
            telephones: [], // Asigna el valor correcto según tus datos
        },
        configuration: {
            digitalInvoice: {
                isEnable: true,
                email: documento.Receptor?.CorreoElectronico ?? ''
            },
            stock: {
                isEnable: false,
                allowNegative: false
            }
        }
    };

    let supplierAddress = null;

    if (documento.Emisor.Ubicacion) {
        const province = await provinceService.byIdOnce(Number(documento.Emisor.Ubicacion.Provincia).toString());
        const canton = await cantonService.byIdOnce(Number(documento.Emisor.Ubicacion!.Canton).toString(), undefined, province!.uid);
        const district = await districtService.byIdOnce(Number(documento.Emisor.Ubicacion!.Distrito).toString(), undefined, province!.uid, canton!.uid);
        const neighborhood = documento.Emisor.Ubicacion!.Barrio ? await neighborhoodService.byIdOnce(Number(documento.Emisor.Ubicacion!.Barrio).toString(), undefined, province!.uid, canton!.uid, district!.uid) : null;

        supplierAddress = {
            province: getTerritoryReference(province!),
            canton: getTerritoryReference(canton!),
            district: getTerritoryReference(district!),
            neighborhood: neighborhood ? getTerritoryReference(neighborhood) : null,
            address: documento.Emisor.Ubicacion.OtrasSenas ?? '',
            zipCode: Number(`${province?.uid}${canton?.uid.padStart(2, '0')}${district?.uid.padStart(2, '0')}`)
        }
    }

    const supplier: SupplierRef = {
        uid: '',
        image: null,
        name: documento.Emisor.Nombre,
        identificationType: documento.Emisor.Identificacion.Tipo as TipoIdentificacion,
        identification: documento.Emisor.Identificacion.Numero,
        tradeName: documento.Emisor.NombreComercial ?? null,
        branch: {
            uid: '',
            name: 'Sin Sucursal',
            address: supplierAddress,
            telephones: [], // Asigna el valor correcto según tus datos
            emails: [documento.Emisor.CorreoElectronico]
        },
        manualEmails: [],
    };

    const lines: Line[] = documento.DetalleServicio.LineaDetalle.map(line => ({
        amount: Number(line.Cantidad),
        product: {
            uid: '',
            image: null,
            multifieldType: '',
            code: line.CodigoComercial ? line.CodigoComercial.map(cc => cc.Codigo).join(" | ") : 'sin código', //TODO: vienes varios codigos, cual es el correcto?
            description: line.Detalle,
            cost: 0, //No viene el costo
            maxDiscount: 0, //No viene el descuento maximo
            allowResellBelowCost: false, //No viene si se puede vender por debajo del costo
            taxes: line.Impuesto.map(t => ({
                type: TaxDetails.find(td => td.codigoImpuesto === t.Codigo)!.uid,
                percentage: (t.Tarifa ?? 0) / 100,
                exempt: t.Exoneracion ? {
                    type: t.Exoneracion.TipoDocumento,
                    number: t.Exoneracion.NumeroDocumento,
                    date: dateService.fromDate(t.Exoneracion.FechaEmision),
                    name: t.Exoneracion.NombreInstitucion,
                    percentage: Number(t.Exoneracion.PorcentajeExoneracion) / 100,
                } : null
            })),
            fixedPrices: [],
            unit: line.UnidadMedida,
            cabysCode: line.Codigo ?? '',
            stock: {
                requested: 0,
                available: 0,
                holded: 0,
                committed: 0,
                sold: 0,
            }
        },
        price: Number(line.PrecioUnitario),
        discountPercentage: line.Descuento ? Math.round(line.Descuento.reduce((acc, d) => acc + d.MontoDescuento, 0) / Number(line.MontoTotal) * 100) / 100 : 0,
        additionalDiscountPercentage: 0, // Si aplica algún otro descuento adicional
        modifiers: null,
    }));

    //TODO: creo que esto deberia hacerse durante la conciliación de pagos
    const payments: Payment[] = documento.MedioPago.map(medio => ({
        type: PaymentDetails.find(p => p.medioPago === medio)!.uid,
        currency: {
            uid: documento.ResumenFactura.CodigoTipoMoneda?.CodigoMoneda ?? Currencies.CRC,
            exchangeRateCRC: documento.ResumenFactura.CodigoTipoMoneda?.TipoCambio ?? 1,
            exchangeRate: 1 //TODO: obtener el tipo de cambio para la fecha del documento
        },
        amount: 0,
        bank: undefined,
        reference: undefined,
    }));

    const digitalDocument: DigitalDocument = {
        uid: uidService.newUID(),
        user: user,
        isDraft: false,

        type: type,
        key: documento.Clave,
        economicActivity: documento.CodigoActividad.toString(),
        number: documento.NumeroConsecutivo,
        date: dateService.fromDate(documento.FechaEmision),
        company,
        client: null,
        supplier: supplier,
        paymentTerm: {
            type: PaymentTermDetails.find(pt => pt.condicionVenta === documento.CondicionVenta)!.uid,
            days: [CondicionVenta.Credito, CondicionVenta.ServiciosPrestadosCredito].includes(documento.CondicionVenta) && documento.PlazoCredito ? Number(documento.PlazoCredito) : 0
        },
        payments,
        lines,
        currency: {
            uid: documento.ResumenFactura.CodigoTipoMoneda?.CodigoMoneda ?? Currencies.CRC,
            exchangeRateCRC: documento.ResumenFactura.CodigoTipoMoneda?.TipoCambio ?? 1,
            exchangeRate: 1 //TODO: obtener el tipo de cambio para la fecha del documento
        },
        references: documento.InformacionReferencia.map(ref => ({
            type: ref.TipoDoc,
            number: ref.Numero ?? '',
            date: dateService.fromDate(ref.FechaEmision),
            code: ref.Codigo ?? null,
            reason: ref.Razon ?? ''
        })),
        relatedDocuments: [],
        others: [],
        notes: '',
        pdf: '',
        xls: '',
        xml: null,
        xmlResponse: null,
        createdBy: user.uid,
        createdDate: dateService.fromDate(new Date()),
        modifiedBy: user.uid,
        modifiedDate: dateService.fromDate(new Date()),
        statusLog: [],
        response: null,
    };

    return digitalDocument;
}

export const processPDF = async (file: File, pdfjsLib: any): Promise<Result> => {
    console.log('Processing PDF:', file.name);

    try {
        // Carga el documento PDF
        const loadingTask = pdfjsLib.getDocument(await file.arrayBuffer());
        const pdf = await loadingTask.promise;

        let text = '';
        const totalPages = pdf.numPages;

        // Recorre las páginas del PDF de manera secuencial
        for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const textContent = await page.getTextContent();

            // Extrae el texto de la página
            textContent.items.forEach((item: any) => {
                text += item.str + ' ';
            });
        }

        // Buscar la clave en el texto extraído
        const clave = searchForClave(text);

        if (clave) {
            return { isValid: true, details: 'El documento se ha cargado correctamente.', file: file, pdfKey: clave };
        } else {
            return { isValid: false, details: 'No se encontró la clave del documento en el archivo PDF.', file: file };
        }
    } catch (error) {
        console.error('Error processing PDF:', error);
        return { isValid: false, details: 'Error al procesar el archivo PDF.', file: file };
    }
}

export const searchForClave = (text: string): string | null => {
    // Expresión regular para encontrar un número de 50 caracteres
    const claveRegex = /\b\d{50}\b/;
    const match = text.match(claveRegex);
    console.log('match:', match, text);
    return match ? match[0] : null;
}

export const calculatePrice = (priceList: PriceList, cost: number, tax: number): number => {
    let calculatedPrice: number;

    switch (priceList.profitType) {
        case ProfitType.Manual:
            return NaN;
        case ProfitType.OnPurchase:
            calculatedPrice = cost * (1 + priceList.profitPercentage);
            break;
        case ProfitType.OnSale:
            calculatedPrice = cost / (1 - priceList.profitPercentage);
            break;
        default:
            throw new Error(`${priceList.profitType} no es un valor conocido para ProfitType`);
    }

    if (priceList.priceAdjustment.isEnabled) {
        let adjustmentFunction: Function;

        switch (priceList.priceAdjustment.type) {
            case AdjustmentType.Ceil:
                adjustmentFunction = Math.ceil;
                break;
            case AdjustmentType.Round:
                adjustmentFunction = Math.round;
                break;
            case AdjustmentType.Floor:
                adjustmentFunction = Math.floor;
                break;
            default:
                throw new Error(`${priceList.priceAdjustment.type} no es un valor conocido para AjustmentType`)
        }

        if (priceList.priceAdjustment.beforeTax) {
            calculatedPrice = adjustmentFunction(calculatedPrice / priceList.priceAdjustment.factor) * priceList.priceAdjustment.factor;
        }
        else {
            let totalPrice = calculatedPrice * (1 + tax);

            totalPrice = adjustmentFunction(totalPrice / priceList.priceAdjustment.factor) * priceList.priceAdjustment.factor;
            calculatedPrice = totalPrice / (1 + tax);
        }
    }

    return calculatedPrice;
}


export const otherByType = (others: Other[], type: DocumentType): Other[] => {
    const byType = others.filter(other => other.documentTypes.includes(type));

    for (let other of byType) {
        if (other.type === OtherType.ContentContainer) {
            other.value = otherByType(other.value as (OtherContentContainer | OtherContentText)[], type) as any;
        }
    }

    return byType;
}

export const everyHasValue = (others: Other[]) : boolean => {
    if (others.length === 0) {
        return true;
    }

    return others.every(other => {
        if (other.type === OtherType.ContentContainer) {
            return everyHasValue(other.value as (OtherContentContainer | OtherContentText)[]);
        } else {
            return (other as OtherContentText).value.trim() !== '';
        }
    });
}