import { Timestamp } from "@angular/fire/firestore";
import { BaseDocument, UniqueDocument } from "./BaseDocument";
import { UserRef } from "./User";
import { BranchRef, Line, PurcahseRef } from "./Purchase";

export type PurchaseReception = BaseDocument &
{
    number: number;
    user: UserRef;
    initDate: Timestamp;
    completeDate: Timestamp | null;

    invoice: string | null,
    branch: BranchRef 

    lines: Line[];

    notes: string;
    pdf: string | Blob;
}

export type PurchaseReceptionRef = UniqueDocument &  {
    number: number,
    purchase: PurcahseRef
}

export const numberPrefix = "R";