import { Address } from "./Address";
import { ListDocument, NamedDocument, UniqueDocument } from "./BaseDocument";
import { Email } from "./Email";
import { Telephone } from "./Telephone";

export type Branch = ListDocument & NamedDocument & {
  address: Address;
  telephones: Telephone[];
  emails: Email[];
};

export type BranchRef = UniqueDocument & NamedDocument & {
}

export const getReference = (branch: Branch): BranchRef => {
  return {
    uid: branch.uid,
    name: branch.name,
  }
}

export const DefaultBranchId = "--default--";
export const DefaultBranchName = "(Oficinas Centrales)";