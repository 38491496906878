async function fetchTimeFromAPI(url: string, responseParser: (data: any) => Date) {
    try {
        const start = Date.now();
        const timeoutPromise = new Promise<null>((resolve, reject) => {
            setTimeout(() => {
                reject(new Error('Timeout exceeded'));
            }, 5000); // Timeout de 5 segundo (5000 milisegundos)
        });

        const responsePromise = fetch(url)
            .then(response => response.json())
            .then(data => {
                const end = Date.now();
                const serverTime = responseParser(data);
                const roundTripTime = end - start;
                const adjustedTime = new Date(serverTime.getTime() - roundTripTime);
                return adjustedTime;
            });

        // Ejecuta ambas promesas y devuelve la primera que se resuelva (o se rechace si ocurre antes del timeout)
        const result = await Promise.race([responsePromise, timeoutPromise]);

        return result;
    } catch (error) {
        console.error(`Error fetching time from ${url}:`, error);
        return null;
    }
}

export const serverTime = async () => {
    //TODO: retomar este codigo para obtener la hora del servidor de proveedores externos y ahorar llamadas al cloud function
    // try {

    //     const tolerance = 1000; // 1 second
    //     const sources = [
    //         {
    //             url: 'http://worldtimeapi.org/api/timezone/Etc/UTC',
    //             parser: (data: any) => new Date(data.utc_datetime)
    //         },
    //         {
    //             url: 'http://worldclockapi.com/api/json/utc/now',
    //             parser: (data: any) => new Date(data.currentDateTime)
    //         },
    //         {
    //             url: 'https://timeapi.io/api/Time/current/zone?timeZone=UTC',
    //             parser: (data: any) => new Date(data.dateTime)
    //         }
    //     ];

    //     const [time1, time2] = await Promise.all([
    //         fetchTimeFromAPI(sources[0].url, sources[0].parser),
    //         fetchTimeFromAPI(sources[1].url, sources[1].parser)
    //     ]);

    //     console.log('time1', time1);
    //     console.log('time2', time2);

    //     if (time1 && time2 && Math.abs(time1.getTime() - time2.getTime()) < tolerance) {
    //         // Si ambas horas son cercanas (dentro de 1 segundo de diferencia)
    //         return time1;
    //     }

    //     // Si las dos primeras fuentes no coinciden, usa la tercera fuente
    //     const time3 = await fetchTimeFromAPI(sources[2].url, sources[2].parser);

    //     console.log('time3', time3);

    //     // Retorna la hora que coincida con al menos una de las otras fuentes
    //     if (time1 && time3 && Math.abs(time1.getTime() - time3.getTime()) < 1000) {
    //         return time1;
    //     } else if (time2 && time3 && Math.abs(time2.getTime() - time3.getTime()) < 1000) {
    //         return time2;
    //     }
    // } catch (error) {
    //     console.error('Error fetching server time from Time Provider:', error);
    // }

    try{

        const firebasTime = await fetchTimeFromAPI("https://us-east1-mentesis-dev.cloudfunctions.net/http-common-serverTime", (data: any) => new Date(data.date));

        console.log('firebasDate', firebasTime);
        
        if (firebasTime){
            return firebasTime;
        }

    }catch(error){
        console.error('Error fetching server time from Firebase:', error);
    }

    // Si no se puede determinar una hora confiable, maneja el error
    console.error('Unable to determine reliable server time');
    return new Date();
}

