import { Timestamp } from "@angular/fire/firestore";
import { BaseDocument, UniqueDocument } from "./BaseDocument";
import { UserRef } from "./User";
import { BranchRef, Line, StockTransferRef } from "./StockTransfer";

export type StockTransferReception = BaseDocument &
{
    number: number;
    user: UserRef;
    date: Timestamp;

    branch: BranchRef 

    lines: Line[];

    notes: string;
    pdf: string | Blob;
}

export type StockTransferReceptionRef = UniqueDocument &  {
    number: number,
    stockTransfer: StockTransferRef
}

export const numberPrefix = "R";